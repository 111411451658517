
.footer {
  color: #fff;
  padding: 35px 0 55px;
  letter-spacing: .02em;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  z-index: 20;
  width: 100%;
  overflow-x: hidden;
  background: #252733;

  @include zoomMozillaContainerPosition;



  .container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;

    &:last-child {
      padding: 0 16%;
      text-align: center;
    }
  }

  a {
    color: #fff;
    text-decoration: none;
    -webkit-transition: color .3s;
    -o-transition: color .3s;
    transition: color .3s;
    opacity: 0.8;

    &.active, &:hover {
      opacity: 1;
    }
  }

  &__logo {
    margin-bottom: 25px;
    width: 100%;
    img {
      width: 211px
    }
  }

  &__links {
    margin-top: 17px;
    margin-bottom: 28px;
    padding-right: 80px;
    @include  max800 {
      text-align: center;
      padding: 0;
      margin-top: 44px;
    }
    @include max576 {
      text-align: left;
      margin-left: 21px;
      margin-top: 14px;
    }
    p {
      margin-bottom: 10px;
      line-height: 1.3;
      margin-bottom: 11px;
      opacity: 0.8;
    }
  }

  &__social {
    font-size: 18px;
    margin-left: auto;

    margin-top: 8px;
    padding-top: 0;
    svg {
      height: 15px;
    }
    .fa-vk {
      font-size: 20px;
    }
    a {
      color: #fff !important;
      opacity: .5;
      margin-right: 10px;
      -webkit-transition: opacity .3s;
      -o-transition: opacity .3s;
      transition: opacity .3s;


      &:hover {
        opacity: 1
      }
    }
  }

  &-menu__col {

    padding-top: 10px;
    padding-right: 15px;

    @include  max576 {
      width: 100%;
    }
    &.no-opacity a {
      opacity: 1;
    }


  }
  &__copyright {
    width: 100%;
    margin-top: 36px;
  }
}








@media (min-width: 768px) {
  .footer__menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }
}

@media (min-width: 992px) {
  .footer {
    padding: 40px 0 55px;
    font-size: 14px
  }

  .footer__logo {
    margin-bottom: 40px
  }


  .footer__contacts {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: 22%;
    -ms-flex-negative: 0;
    flex-shrink: 0
  }

  .footer__menu {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
  }





}


.footer-menu__link {
  margin-bottom: 11px;
  display: inline-block
}

.footer-menu__link.level-1 {
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.3;
  background: 0 0 !important;
  padding: 0;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important
}

.footer-menu__link.level-1 .fa {
  margin-left: 17px;
  font-size: 14px;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  -o-transition: transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  transition: transform .3s, -webkit-transform .3s
}

.footer-menu__link.level-1[aria-expanded=true] .fa {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg)
}

.footer-menu__submenu {
  margin-top: 5px
}

@media (min-width: 768px) {
  .footer .footer-menu__link.level-1 .fa {
    display: none;
  }

  .footer .footer-menu button.level-1 {
    pointer-events: none;
  }

  .footer .footer-menu__submenu {
    display: block !important;
    visibility: visible !important;
    height: auto !important;
  }
}

@media (min-width: 992px) {
  .footer-menu__link {
    margin-bottom: 13px
  }
}

.footer-contacts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.footer-contacts__title {
  letter-spacing: .1em;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.3;
  margin-top: 5px;
  margin-bottom: 14px
}

.footer-contacts__phone {
  font-weight: 800;
  line-height: 1.3;
  margin-bottom: 15px;
  white-space: nowrap
}



@media (min-width: 992px) {
  .footer-contacts {
    display: block;
    padding-top: 5px
  }

  .footer-contacts__title {
    font-size: 14px;
    margin-bottom: 12px
  }

  .footer-contacts__phone {
    font-size: 18px;
    margin-bottom: 30px
  }


}

@media (min-width: 1280px) {
  .footer-contacts__phone {
    font-size: 24px
  }
}

.footer__attention {
  font-size: 14px;
  margin-top: 30px;
  color: rgba(255, 255, 255, .8);
}

.footer__attention strong {
  color: rgba(255, 255, 255, 1);
}
