
.catalog-item {
  position: relative; /*max-width:290px;*/
  margin-bottom: 60px;
  border-radius: 25px;
  cursor: pointer;
  width: 300px;
  margin-top: 15px;


  &:hover {
    z-index: 30;
    -webkit-box-shadow: 4px 10px 16px rgba(0, 0, 0, .15);
    box-shadow: 4px 10px 16px rgba(0, 0, 0, .15);
    transition: all 0.4s ease-out;
    transform: scale(1.05);

    .catalog-item__desc {


      opacity: 1;

      p {
        opacity: 1;
        margin-top: 0;
        transition: all 0.4s ease-out;
      }
    }

    .catalog-item-add-category {
      display: block;
      z-index: 999;
    }

    .catalog-item-add-category[data-state="remove"] {
      background: transparent;
    }
  }

  &__link {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 12px;
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    margin-bottom: 5px;
    height: auto;
    overflow: hidden;
    margin-top: 9px;
  }

  &__viewed {
    span {
      font-size: 12px;
    }

    .svg-icon {
      width: 16px;
      height: 16px;
      opacity: .8;
      margin-right: 17px;
    }
  }

  &__buttons {
    z-index: 9;

    position: absolute;
    top: -11px;
    left: -15px;
  }

  &__choice {

    top: 0;
    left: 0;
    border: none;
    background: none;
    padding: 0;
    //margin: -25px 0 0 -10px;
    z-index: 20;
    width: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    margin-bottom: -12px;
    margin-top: -17px;
    margin-left: 2px;

    img {
      max-width: none;
    }
  }



  &__coop {
    width: 43px;
    height: 43px;


    background: rgba(233, 236, 255, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    /* Note: backdrop-filter has minimal browser support */
    top: 50px;
    left: -16px;
    border: none;
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      border-radius: 50%;
      width: 36px;
      height: 36px;


      background: #E9ECFF;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      color: #374859;
    }
  }

  .catalog-item__reviews {
    font-size: 12px;
    line-height: 130%;
    font-weight: 500;
    color: #252733;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    .svg-icon {
      width: 19px;
      height: 19px;
      margin-right: 17px;
      -ms-flex-negative: 0;
      flex-shrink: 0;
    }
  }

  &__mail svg {
    fill: none;
    position: absolute;
    color: #385C8B;
    width: 22px;
    height: 22px;
    bottom: 2px;
  }

  &__video {
    position: absolute;
    right: 20px;
    top: 48px;
  }

  &__info {
    font-size: 12px;
    font-weight: 300;
    margin-top: 15px;

    .fa {
      font-size: 24px;
      line-height: 0;
      margin-top: -3px;
      position: relative;
      margin-right: 4px;
      color: #385C8B
    }
  }

  &__desc {
    width: 300px;
    height: 292px;
    background: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 140%;
    color: #FFFFFF;
    position: absolute;
    top: 0;
    border-radius: 25px 25px 0px 0px;
    overflow: hidden;
    padding: 40px 25px;
    opacity: 0;
    @include max576 {
      width: 100%;
    }

    p {
      opacity: 0;
      margin-top: 20px;
    }
  }


}


.catalog-item__media {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 25px 25px 0 0;
}

.catalog-item__media::before {
  content: '';
  display: block;
  padding-bottom: 100%
}

.catalog-item__media img {
  width: 100%;
  position: absolute;
  z-index: -1;
}

.catalog-item__media::after {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
}

.catalog-item__media-extra {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  overflow: hidden;
  border-radius: 25px 25px 0 0;
  pointer-events: none;
}

.catalog-item__topbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 18px;
  color: #b3b3b7
}

.catalog-item__stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.catalog-item__rating {
  font-size: 11px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 18px
}

.catalog-item__rating .fa {
  margin-right: 5px;
  color: #fde022
}


.catalog-item__controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.catalog-item__controls-item {
  margin-left: 10px;
  color: #b3b3b7;
  width: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative
}

.catalog-item__controls-item .svg-icon {
  position: absolute;
  top: 50%;
  margin-top: -10px
}

.catalog-item__subtitle {
  font-size: 16px;
  line-height: 130%;
  color: #2a2d37;
  margin-bottom: 10px
}

.catalog-item__title-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 13px;
}

.catalog-item__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.catalog-item__row_bottom {
  margin-top: 25px;
}


.catalog-item__subtitle {
  margin-right: auto;
  font-weight: 500;
}


.catalog-item__desc a, .catalog-item__desc p {
  color: inherit
}

.catalog-item__content {
  position: relative;
  padding: 20px 20px 15px;
  background: #F9FAFC;

  border-radius: 0 0 25px 25px;
  overflow: hidden;

}

.catalog-item__favorite {
  position: relative;
  z-index: 8;
  cursor: pointer;
  color: #252733;
}

.catalog-item__favorite .fa-heart {
  display: none;
}

.catalog-item__favorite.active {
  color: #D83A54;
}

.catalog-item__favorite.active .fa-heart-o {
  display: none;
}

.catalog-item__favorite.active .fa-heart {
  display: inline-block;
}

/*.catalog-item_choice .catalog-item__media-extra::before {content: '';display:block;top:0;left:0;position:absolute;width:100%;height:10px;background:#FAC15E;z-index:1;-webkit-transition:.5s;-o-transition:.5s;transition:.5s;}*/

.catalog-item__media-extra.border-highligth::before {
  content: '';
  display: block;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 10px;
  background: #FAC15E;
  z-index: 1;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
}

.catalog-item_choice .catalog-item__media-wrap:hover .catalog-item__media-extra::before {
  height: 0;
}


.owl-carousel .owl-item .catalog-item__choice img {
  width: auto;
}

.catalog-item__icons {
  position: absolute;
  top: 17px;
  right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  z-index: 20;
}

.catalog-item__icon {
  background: #e4e9ed;
  border-radius: 3px;
  border: none;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 5px;
  min-width: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 10px;
  color: #252733;
  opacity: 0.8;
  font-size: 10px;
  font-weight: 700;
  -webkit-box-shadow: 0px 5px 25px rgba(30, 31, 33, 0.12);
  box-shadow: 0px 5px 25px rgba(30, 31, 33, 0.12);
}

.catalog-item__icon.icon-only img {
  width: 14px !important;
  margin-left: -2px;
  margin-right: -2px;
  max-width: none;
}

.catalog-item__icon img {
  margin-left: 5px;
  margin-right: 5px;
  width: 14px !important;
  max-width: none;
}

.catalog-item__icon span {
  margin-right: 8px;
}

@media (max-width: 991px) {
  .catalog-item {
    margin-bottom: 25px
  }

  .catalog-item__media::after {
    height: 5px
  }

  .catalog-item__content {
    padding-top: 8px
  }

  .catalog-item__topbar {
    display: block
  }

  .catalog-item__stats {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 5px
  }

  .catalog-item__rating {
    margin-right: 3px
  }

  .catalog-item__reviews {
    font-size: 12px;
    color: #b3b3b7
  }

  .catalog-item__controls-item {
    height: 20px;
    margin-left: 0;
    margin-right: 10px
  }

  .catalog-item__subtitle {
    font-size: 12px
  }

  .catalog-item__title {
    font-size: 16px;
    padding-right: 0;
    margin-bottom: 10px
  }

  .catalog-item__info {
    font-size: 12px
  }

  .catalog-item__info > div {
    width: 50%
  }

  .catalog-item__info .fa {
    margin-top: 0;
    margin-right: 0;
    font-size: 18px
  }
}

@media (min-width: 992px) {

}

div.catalog-item__desc_remove-spec {
  opacity: 1;
}

.catalog-item .catalog-item__media::after, .catalog-item_coach .catalog-item__media::after, .catalog-item_consult .catalog-item__media::after {
  background: #f48f6f
}

//.catalog-item .catalog-item__desc, .catalog-item_coach .catalog-item__desc, .catalog-item_consult .catalog-item__desc {
//  background: -o-radial-gradient(50.19% -37.5%, 93.5% 93.5%, rgba(243, 112, 32, 0) 0, #f48f6f 100%);
//  background: radial-gradient(93.5% 93.5% at 50.19% -37.5%, rgba(243, 112, 32, 0) 0, #f48f6f 100%)
//}

.catalog-item_psychologist .catalog-item__media::after {
  background: #5d8d94
}

//.catalog-item_psychologist .catalog-item__desc {
//  background: -o-radial-gradient(50.19% -37.5%, 93.5% 93.5%, rgba(243, 112, 32, 0) 0, #5d8d94 100%);
//  background: radial-gradient(93.5% 93.5% at 50.19% -37.5%, rgba(243, 112, 32, 0) 0, #5d8d94 100%)
//}

.catalog-item_mentor .catalog-item__media::after {
  background: #385C8B
}

//.catalog-item_mentor .catalog-item__desc {
//  background: -o-radial-gradient(50.19% -37.5%, 93.5% 93.5%, rgba(56, 92, 139, 0) 0, #385C8B 100%);
//  background: radial-gradient(93.5% 93.5% at 50.19% -37.5%, rgba(56, 92, 139, 0) 0, #385C8B 100%)
//}


.catalog-item__mail {
  padding: 0;
  border: none;
  background: none;
  float: right;
  margin-left: 20px;
  color: #385C8B;
  line-height: 0;
  position: relative;
  z-index: 8;
  cursor: pointer;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  height: 22px;
  width: 27px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 5px;

  &:hover {
    color: #fff;
    //background: #385C8B;
    svg {
      fill: #385C8B;
      color: #fff;
    }
  }
}


.catalog-item-add-category {
  position: absolute;
  top: 198px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  pointer-events: auto;
  cursor: pointer;
  display: none;
}

.catalog-item-add-category__circle {
  position: relative;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.catalog-item-add-category__circle span {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
}

.catalog-item-add-category[data-state="add"] .catalog-item-add-category__circle {
  background-color: #FFFFFF;
  color: #385C8B;
}

.catalog-item-add-category[data-state="remove"] .catalog-item-add-category__circle {
  background-color: #EB5757;
  color: #FFFFFF;
}

.catalog-item-add-category[data-state="remove"] {
  display: block;
  width: 100%;
  height: 292px;
  top: 0;
  padding-top: 66%;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 30px 30px 0px 0px;
}

.catalog-item-add-category__circle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.catalog-item-add-category__label {
  margin-top: 20px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}
