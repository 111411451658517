.spec-competitions {
  position: relative;
  padding: 185px 0 35px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -100vw;
    width: 200vw;
    height: 100%;
    pointer-events: none;
    background: url(../images/dance-line-orange.svg) no-repeat 50% 120px
  }

  &__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-top: 70px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 120px;

  }
  &__item {
    margin-right: 20px;
    border: 1px solid #f48f6f;
    padding: 17px 12px 8px 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    color: rgba(37, 39, 51, .8);
    margin-bottom: 15px;
    position: relative;
    border-radius: 12px;
    width: 203px;
  }

  &__title {
    display: block;
    max-width: 180px;
    position: relative;
    max-height: 80px;
    overflow: hidden;
    font-size: 16px;
    opacity: 0.8;
    font-family: 'Montserrat';
    font-weight: 400;
    width: 100%;
    padding: 0px 18px;
  }

}

.theme-mentor .spec-competitions::before {
  background-image: url(../images/dance-line-blue.svg)
}

.theme-psycologist .spec-competitions::before {
  background-image: url(../images/dance-line-green.svg)
}


.theme-mentor .spec-competitions__item {
  border-color: #385C8B;
}

.theme-psycologist .spec-competitions__item {
  border-color: #5d8d94;
}

.spec-competitions__item::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: #f48f6f;
  -webkit-transition: height .3s;
  -o-transition: height .3s;
  transition: height .3s
}

.theme-mentor .spec-competitions__item::before {
  background: #385C8B
}

.theme-psycologist .spec-competitions__item::before {
  background: #5d8d94
}

.spec-competitions__item.active {
  color: #fff
}

.spec-competitions__item.active::before {
  height: 100%
}



.spec-competitions__more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
  padding: 0 0 0 15px;
}

.spec-competitions__more .svg-icon {
  width: 117px
}

.spec-competitions__more:hover .svg-icon {
  fill: #f48f6f
}

.theme-mentor .spec-competitions__more:hover .svg-icon {
  fill: #385C8B
}

.theme-psycologist .spec-competitions__more:hover .svg-icon {
  fill: #5d8d94
}

.spec-competitions__count {
  display: block;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: .02em;
  padding-left: 3px
}

@media (max-width: 991px) {
  .spec-competitions {
    padding-top: 30px;
    margin-bottom: 0
  }

  .spec-competitions::before {
    content: none
  }

  .spec-competitions__list {
    margin-top: 15px
  }
}

@media (min-width: 992px) {
  .spec-competitions {
    margin-right: -42px
  }

  .spec-competitions__item {
    padding: 40px 42px 15px 23px;
    font-size: 16px;
    margin-bottom: 24px
  }

  .spec-competitions__more {
    padding-left: 52px
  }
}

@media (min-width: 992px) and (max-width: 1599px) {
  .spec-competitions {
    padding-top: 85px
  }
}

.spec-competitions .page__text-bg {
  top: 160px
}
