@import "../media";
.blog-articles {
  margin-top: 40px;
  background: #f5f6fb;
  max-width: 265px;
  padding: 20px 15px 30px;
  border-radius: 25px;


}

.blog-articles__item {
  position: relative;
  margin-bottom: 52px
}

.blog-articles__item:last-of-type {
  margin-bottom: 0;
}

.blog-articles__date {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: .02em;
  color: #5d5d64;
  margin-bottom: 15px
}

.blog-articles__type {
  color: rgba(21, 21, 40, .5);
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 5px
}

.blog-articles__title {
  color: #151528;
  font-weight: 600;
  font-size: 16px;
  line-height: 115%
}

.blog-articles__title a {
  -webkit-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s
}

.blog-articles__title a:hover {
  opacity: .7
}

.blog-articles__title a::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

@media (max-width: 991px) {
  .blog-articles {
    max-width: none;
    margin-left: -15px;
    margin-right: -15px;
    overflow-x: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }

  .blog-articles__item {
    margin-bottom: 0;
    margin-right: 20px;
    width: 250px;
    -ms-flex-negative: 0;
    flex-shrink: 0
  }
}

@media (min-width: 992px) {
  .blog-articles {
    padding: 25px 20px 20px 20px
  }
}

@media (min-width: 1280px) {
  .blog-articles {
    padding: 30px 25px 40px;
  }

  .blog-articles__type {
    font-size: 14px;
    line-height: 17px
  }

  .blog-articles__title {
    font-size: 18px
  }
}

.blog-article {
  margin-top: 40px;

  &__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 28px;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: .02em;
    position: relative;
    @include max576 {
      //justify-content: space-between;
    }
  }
  &__type {
    margin-right: 60px;
    font-size: 16px;
    margin-bottom: 20px;
    @include max576 {
      margin-right: 0;
      width: 100%;
      margin-bottom: 9px;
    }
  }

  &__views {
    font-size: 14px;
    display: flex;
    margin-left: 20px;
    @include max576 {
      //position: absolute;
      //top: 24px;
      //right: 1px;
    }
    svg {
      width: 18px;
      height: 16px;
      margin-right: 5px;

    }
  }

  &__author {
    width: 100%;
    margin-top: -10px;
    font-size: 16px;
    a {
      color: #385C8B;

    }

  }
}


.blog-article__type a {
  color: #385C8B
}

.blog-article__date {
  color: #b3b3b7;
  margin-bottom: 20px
}

.blog-article .page__title {
  text-transform: none;
  font-weight: 600;
  font-size: 20px;
  line-height: 115%;
  color: #151528
}

.blog-article__content {
  max-width: 910px
}

.blog-article__content p {
  margin-bottom: 20px;
  line-height: 140%
}

.blog-article__image {
  margin-bottom: 40px
}

.blog-article__nav {
  margin: 30px -20px
}

.blog-article__nav .svg-icon {
  width: 117px;
  margin: 0 20px
}

.blog-article__prev .svg-icon {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1)
}

@media (min-width: 992px) {
  .blog-article .page__title {
    font-size: 30px
  }

  .blog-article__nav {
    margin-bottom: 0
  }
}

@media (min-width: 1280px) {
  .blog-article__nav {
    margin-top: 42px;
    margin-bottom: 48px
  }
}
