
.page_main .spec-filters_themes {

  margin-bottom: 160px;
  @include  max800 {
    margin-bottom: 60px;
  }
}

.page_main .spec-filters {
  margin-top: 0px;
}
.spec-filters {
  margin-top: 30px;
  margin-bottom: 50px;
  &__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 35px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  &__content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 50px;
      background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 1)));
      background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    }
  }
  &.owl-loaded {
    opacity: 1;
  }

  .owl-next, .owl-prev {
    z-index: 9;
    top: 18%;
  }
}



.spec-filters__title {
  width: 300px;
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #252733;
  margin-right: 20px;
}

.spec-filters .spec-competitions__item {
  flex-shrink: 0;
  height: 98px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  padding: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 15px 15px 0;
  max-width: 250px;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  overflow: hidden;
}

.spec-filters .spec-competitions__item_mentor {
  border-color: #385C8B;
}

.spec-filters .spec-competitions__item_psychologist {
  border-color: #5d8d94;
}

.spec-filters .spec-competitions__item_coach {
  border-color: #f48f6f;
}

/* ховеры показываем только на десктопе */
@media (min-width: 992px) {
  .spec-filters .spec-competitions__item:hover {
    color: #fff;
  }

  .spec-filters .spec-competitions__item_mentor:hover,
  .theme-mentor .spec-filters .spec-competitions__item:hover {
    background: #385C8B;
  }

  .spec-filters .spec-competitions__item_psychologist:hover,
  .theme-psycologist .spec-filters .spec-competitions__item:hover {
    background: #5d8d94;
  }

  .spec-filters .spec-competitions__item_coach:hover,
  .theme-coach .spec-filters .spec-competitions__item:hover {
    background: #f48f6f;
  }
}

.spec-filters .spec-competitions__more {
  padding-left: 0;
  font-size: 20px;
}

.theme-mentor .spec-filters .spec-competitions__more {
  color: #385C8B;
}

.theme-psycologist .spec-filters .spec-competitions__more {
  color: #5d8d94;
}

.theme-coach .spec-filters .spec-competitions__more {
  color: #f48f6f;
}


.spec-filters__collapse-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  background: none;
  padding: 0;
  color: #252733;
}

.spec-filters__collapse-btn-icon {
  border: 1px solid #385C8B;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 26px;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  border-radius: 50%;
  font-size: 30px;
  line-height: 0;
}

.spec-filters__collapse-btn-text {
  color: #252733;
  opacity: .8;
  font-size: 16px;
}

.spec-filters .blog-search__form {
  margin: -20px 0 5px;
  margin-bottom: 24px;
}




.spec-filters_themes .spec-filters__title {
  font-size: 20px;
  margin-bottom: 60px;
}

/*.spec-filters_main .spec-filters__content {flex-wrap: wrap; overflow: hidden; max-height: 220px;}*/
