/* темы кубики */

.mpage-themes__carousel {
  padding-left: 0;
  margin-top: -20px;
  margin-left: -15px;
  margin-right: -15px;

  width: 1700px;

  @include max1050 {
    width: 120%;

  }
  @include  max576 {
    width: 100%;
    margin: 0;
  }
  .owl-nav {
    &.animate {
      transition: all 0.3s ease-out;
      top: 38%;
      opacity: 1;
    }
  }
}


.theme-item {
  position: relative;
  overflow: hidden;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 45px;
  border-radius: 25px;
  background: #F9FAFC;
  width: 100%;
  width: 320px;
  height: 326px;

  @include  max576 {
    margin: 0 auto;
  }

  &:hover {
    box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
    transform: scale(1.05);
    transition: all 0.4s ease-out;

    .theme-item__media {
      display: none;
    }
    .theme-item__content {
      display: none;
    }
    .theme-item__hovered {
      opacity: 1;
      transition: all 0.4s ease-out;
      &-title {
        margin-top: 17px;
        transition: all 0.4s ease-out;
      }
    }
  }

  &__hovered {
    position: absolute;
    background: #fff;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    padding: 52px 45px;
    &-title {
      color: #252733;
      bottom: 0;
      margin-top: 28px;
      font-weight: bold;
      position: relative;
      text-transform: uppercase;
    }

    &-descr {
      position: relative;
      bottom: unset;
      margin-top: 30px;
      font-size: 14px;


    }
  }


  &::before {
    content: '';
    display: block;
    padding-bottom: 102%;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    pointer-events: none;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

    }


    &.active {

    }
  }




  &__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 25px;
    overflow: hidden
  }

  &__media img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 25px;
  }


  &__content > * {
    position: relative;
  }

  &__content a {
    color: inherit
  }

  &__content a::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  &__bottom {
    opacity: .8;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: .02em
  }

  &__middle {
    margin-top: 50px;
    margin-bottom: auto;
    padding-right: 14%
  }

  &__title {
    font-size: 16px;
    line-height: 130%;
    text-transform: uppercase;
    color: #FFFFFF;
    position: absolute;
    bottom: 30px;
    text-align: left;
    width: 100%;
    padding: 0px 45px;
    font-weight: bold;
    @include  max576 {
      width: 90%;
      padding: 0;
    }
  }

  &__descr {
    position: absolute;
    bottom: -100%;
    font-size: 14px;
    color: #252733;
    padding: 0px 45px;
  }

  &__subtext {
    text-align: right;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    opacity: 0;
  }
}



.theme-item_with-media .theme-item__content {
  background: rgba(0, 0, 0, .5);
}

.theme-item_with-media .theme-item__middle {
  margin-bottom: 17px
}

.theme-item_coaching::after {
  background: #F48F6F;
}

.theme-item_coaching .theme-item__subtext {
  color: #F48F6F;
}

.theme-item_psycology::after {
  background: #5D8D94;
}

.theme-item_psycology .theme-item__subtext {
  color: #5D8D94;
}

.theme-item_mentoring::after {
  background: #385C8B;
}

.theme-item_mentoring .theme-item__subtext {
  color: #385C8B;
}