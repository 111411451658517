
.main-themes-desc {
  margin-bottom: 150px;
  margin-top: 160px;
  @include zoomMozillaContainerPosition;
  @include  max800 {
    margin-bottom: 50px;
    margin-top: 60px;
  }
  @include  max576 {
    flex-wrap: wrap;
  }

  &__row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }


  &__aside {
    width: 100%;
    display: flex;
    padding: 0;
    @include  max576 {
      flex-wrap: wrap;
    }

    li {
      margin-bottom: 15px;
      list-style: none;
      display: inline-block;
      width: 25%;
      transition: transform 0.2s linear;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      cursor: pointer;

      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      font-size: 30px;
      line-height: 37px;
      width: 400px;
      height: 100px;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      @include  max800 {
        width: 29%;
      }
      @include max576 {
        width: 100%;
        height: 62px;
        font-size: 14px;
        margin-bottom: 0;
      }

      span {
        font-size: 20px;
        opacity: 1;
        margin-left: 48px;
        width: 100%;
        @include max576 {
          font-size: 16px;
          margin-left: 26px;
        }
      }

      &:first-child, &:last-child {
        width: 40%;
        @include  max800 {
          width: 23%;
        }
        @include  max576 {
          width: 100%;
        }
      }



      &:first-child {
        span {
          width: 100%;
          text-align: right;
          margin-right: 261px;
          @include  max576 {
            width: 100%;
            text-align: left;
            margin-right: 0;
            margin-left: 40px;
          }
        }

      }

      &.active {
        box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
        border-radius: 1px 15px 15px 0px;
        transform: scale(1.1);
        z-index: 9;
        transition: transform 0.2s linear;
        font-weight: bold;

        &.theme-coach {
          border-color: #F48F6F;
          background: #F48F6F;
        }

        &.theme-mentor {
          border-color: #385C8B;
          background: #385C8B;
        }

        &.theme-psycologist {
          border-color: #5D8D94;
          background: #5D8D94;

        }

        &.theme-consult {
          border-color: #F48F6F;
          background: #F48F6F;

        }
      }

      &.theme-coach {
        //border-color: #F48F6F;
        //background:#F48F6F;
        border-color: rgba(244, 141, 111, 0.25);
        background: rgba(244, 141, 111, 0.25);
      }

      &.theme-mentor {
        //border-color: #385C8B;
        //background:#385C8B;
        border-color: rgba(56, 92, 139, 0.25);
        background: rgba(56, 92, 139, 0.25);

      }

      &.theme-psycologist {
        //border-color: #5D8D94;
        //background:#5D8D94;
        border-color: rgba(93, 14, 148, 0.25);
        background: rgba(93, 141, 148, 0.25);
      }

      &.theme-consult {
        //border-color: #F48F6F;
        //background:#F48F6F;
        border-color: rgba(244, 141, 111, 0.25);
        background: rgba(244, 141, 111, 0.25);
      }


    }


  }

  // aside
  .tab-pane {
    display: flex;
  }

  &__content {

    width: 100%;
    display: flex;
    margin: 0 auto;
    margin-top: 60px;
    display:none;
    opacity: 0;
    transition: all 0.3s linear;
    max-width: 1920px;

    @include  max576 {
      flex-wrap: wrap;
      margin-top: 30px;
    }


    &.visible {
      opacity: 1;
      transition: all 0.3s linear;
    }



    &-left {
      width: 36%;
      border-radius: 0 40px 40px 0px;

      overflow: hidden;
      @include  max800 {
        width: 45%;
      }
      @include  max576 {
        width: 100%;
        margin-bottom: 30px;
      }
    }

    &-right {

      margin-left: 120px;
      width: 625px;
      @include max800 {
        margin-left: 40px;
        width: 51%;
      }
      @include  max576 {
        width: 100%;
        margin: 0 auto;
        width: 87%;
      }
    }

    p {
      margin-bottom: 20px;
      line-height: 1.4;
    }

    .spec-card__total-item {
      padding-left: 8px;
    }

    p + .spec-card__total-item {
      margin-top: 38px;
    }

  }

}

.main-themes-desc .mainpage-group__title {
  margin-bottom: 80px;
  letter-spacing: .02em;
}


.main-themes-desc__aside a > * {
  position: relative;
}

.main-themes-desc__aside a svg {
  margin: 0 40px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  opacity: 0;
  -webkit-transform: translateX(-10px);
  -ms-transform: translateX(-10px);
  transform: translateX(-10px);
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
}

.main-themes-desc__aside li.active > a {
  max-width: 440px;
  font-weight: 600;
}

.main-themes-desc__aside li.active > a svg {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.main-themes-desc__aside li.active > a::before {
  -webkit-box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
}

li.main-themes-desc__item_coach.active > a::before {
  background: #F48F6F;
}

li.main-themes-desc__item_mentors.active > a::before {
  background: #385C8B;
}

li.main-themes-desc__item_psycologists.active > a::before {
  background: #5D8D94;
}

.main-themes-desc__btn {
  margin-top: 40px;
  margin-left: 8px;
}
