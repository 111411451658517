
.blog-item {
  width: 320px;
  height: 326px;
  position: relative;
  overflow: hidden;
  margin-left: 3px;
  margin-right: 3px;
  margin-bottom: 45px;
  border-radius: 25px;
  background: #f9fafc;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &::before {
    width: 100%;
    content: '';
    display: block;
    padding-bottom: 0;
    background: antiquewhite;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: linear-gradient(180deg, rgba(37, 39, 51, 0.1) 0%, rgba(37, 39, 51, 0.3) 100%), url(.jpg);
  }
  &:hover{
    //box-shadow: 5px 8px 19px -4px rgba(50, 50, 50, 0.35);
    box-shadow: 4px 10px 16px rgba(0, 0, 0, 0.15);
    transform: scale(1.05);
    transition: all 0.3s ease-out;
    .blog-item__media {
      display:none;
    }
    .blog-item__content {
      background: none;
    }

    &::before {
      display: none;
    }
  }

  &__wrap {
    position: relative;
    padding-top: 25px;
    margin-left: 27px;
    display: block;
  }
  &__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    img {
      -o-object-fit: cover;
      object-fit: cover;
      min-height: 100%;
      min-width: 100%;
      width: auto;
      height: auto
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 19px 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: rgba(0,0,0,0.3);
    z-index: 2;
    a {
      color: inherit;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0
      }
    }
  }

  &__date {
    display: flex;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 9;

    //background: rgba(255, 255, 255, 0.9);
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    top: 5px;
    left: -22px;
    flex-wrap: wrap;
    padding: 6px;
    @include  max576 {
      left: -6px;
    }
    .d {
      display: block;
      text-align: center;
      width: 100%;
      font-size: 16px;
      line-height: 100%;
      text-transform: uppercase;
      color: #252733;
    }
    .m {
      display: block;
      text-align: center;
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      text-transform: uppercase;
    color: rgba(37, 39, 51, 0.5);

    }

  }


  &__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  &__top {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: .02em
  }


  &__hover {
    position: absolute;
    top: 0;
    background: #fff;
    padding: 45px;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 9;
    &:hover{
      opacity: 1;
      transition: all 0.4s ease-out;
    }
    &:hover .blog-item__hover-title {
      margin-top: 40px;
      transition: all 0.4s ease-out;
    }

    &-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 130%;
      color: #252733;
      margin-bottom: 14px;
      margin-top: 55px;
    }
    p {
      font-size: 14px;
    }
    .views {
      font-size: 12px;
      display: flex;
      align-items: center;
      svg {
        width: 14px;
        margin-right: 12px;
      }
    }
  }


}


.blog-item.blog-item_with-media:hover .blog-item__media {
  opacity: .8;
}





.blog-item__stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px
}

.blog-item__stat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 30px
}

.blog-item__stat .svg-icon {
  width: 16px;
  height: 16px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 13px
}





.blog-item__title {
  font-weight: 800;
  font-size: 16px;
  line-height: 130%;
  display: -webkit-box;
  max-height: 5.2em;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.blog-item__edit {
  position: relative;
  margin: -5px 0 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 210px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px
}

.blog-item__edit a {
  color: #b3b3b7
}

.blog-item__action {
  margin-bottom: 80px
}

.blog-item__type {
  font-weight: 500;
  font-size: 16px;
  text-transform: lowercase;
}

@media (max-width: 767px) {
  .blog-item {
    margin-left: 15px;
    margin-right: 15px
  }
}

@media (max-width: 991px) {
  .blog-item {

  }



  .blog-item__action .btn-round {
    height: 50px
  }
}

.blog-item_with-media .blog-item__content {
  color: #fff; /*padding:15px;*/

}

/*.blog-item_with-media .blog-item__middle{margin-bottom:17px}*/
.blog-item_with-media .blog-item__stats {
  margin-right: 0
}