@import "../media";


.alert {
  height: 100%;
}
.modal {
  //display: flex;
  //justify-content: center;
  //align-items: center;
  .close {
    position: absolute;
    top: 30px;
    right: 25px;
    z-index: 10;
    opacity: 1;

    .svg-icon {
      width: 18px;
      height: 18px
    }

    &:hover {
      color: #385C8B
    }
  }
}

.modal-content {

  border: none;
  box-shadow: 4px 10px 16px rgba(0, 0, 0, 0.20);
  border-radius: 19px;

}

.modal-dialog {
  //display: block;

  position: absolute;
  top: 50%;
  bottom: 0;
  box-shadow: none;

  //background: #fff;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%,-50%) !important;
  @include max576 {
    width: 88%;
  }

  //overflow: hidden;
}

.modal-open .modal-backdrop {
  opacity: .8;
  height: 100%;
  background: #fff;
  z-index: 1039;
}

@media (min-width: 992px) {
  .modal-open {
    padding-right: 17px !important
  }
}




.modal__title {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: .02em
}

.modal__text {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 40px
}
.modal-body {
  padding: 60px;
  @include max800 {
    padding: 40px;
  }
}


@media (min-width: 768px) {
  .modal__title {
    font-size: 28px;
    line-height: 35px;
    padding: 22px 0;
    margin-bottom: 22px
  }


}

.modal-label {
  margin-bottom: 25px
}

.modal-label.h6 {
  margin-top: 5px
}

@media (min-width: 1600px) {
  .modal-label {
    margin-bottom: 45px
  }
}

.modal-btn-wrap {
  text-align: center;
  margin-top: 70px
}

.modal-btn-wrap .btn-round.modal-btn {
  width: 100%;
}

@media (min-width: 768px) {
  .modal-btn-wrap .btn-round.modal-btn {
    min-width: 350px;
    width: auto;
  }
}

.modal-form {
  margin-top: 44px
}

.modal-form .form-group__hint {
  font-size: 14px
}

.modal-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.modal-head .modal__title {
  padding: 0;
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1
}

.modal-head .page__title {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1
}

.modal-head a {
  color: #b3b3b7;
  text-transform: uppercase;
  font-size: 21px;
  letter-spacing: .02em;
  margin: 10px 0 20px 30px
}

@media (min-width: 768px) {
  .modal-md .modal-dialog {
    width: 480px
  }


  .modal-md .modal-head {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .modal-md .modal-head a {
    font-size: 28px;
    letter-spacing: 0
  }

  .modal-md .modal-form {
    margin-top: 22px
  }

  .modal-md .form-group {
    margin-bottom: 18px
  }

  .modal-md .form-group .control-label {
    font-size: 14px
  }

  .modal-md .form-group.modal-btn-wrap {
    margin-top: 40px;
    margin-bottom: 40px
  }

  .modal-md .form-group.text-center:last-of-type {
    margin-bottom: 0
  }
}

.modal-small p {
  font-size: 14px
}

.modal-small h5 {
  font-size: 16px
}

.modal-small .modal-icons a {
  display: inline-block;
  margin-right: 20px
}

#regModal {
  .modal-content {
    height: 70vh;
    width: 495px;
    overflow: auto;
  }
}

.review-item-modal {
  .modal-content {
    height: 100%;
  }

  .modal-dialog {
    min-height: 570px;
    @include max576 {
      width: 93%;
    }
  }
}