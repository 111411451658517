@import "normalize-bootstrap.scss";
@import "media";



/***
 НАЧАЛО ВЕРСТКИ что выше рессеты и бутстрап
 ***/
body {
  font-family: Montserrat, arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #252733;
  background-color: #fff;
  @include max576 {
    font-size: 14px;
  }
}

.rub {
  font-family: Rouble, arial, sans-serif;
  font-weight: 400
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, arial, sans-serif;
  margin: 10px 0;
  color: #000
}

.h4, h4 {
  font-weight: 600
}

.h5, h5 {
  font-weight: 700
}

.h6, h6 {
  font-weight: 600;
  font-size: 18px
}

@media (max-width: 480px) {
  .h3, h3 {
    font-size: 24px
  }

  .h4, h4 {
    font-size: 21px
  }

  .h5, h5 {
    font-size: 18px
  }

  .h6, h6 {
    font-size: 16px
  }
}


blockquote::before {
  background: #385C8B;
  border-radius: 12px;
}

// Конец типографики

.container {
  max-width: 1400px;
  width: 100%;
  position: relative;
  @include max1050 {
    padding: 0 54px;

  }
  @include max576 {
    padding: 0 20px;
  }
}

.page__wrap {
  display: flex;
  @include max1050 {
    flex-wrap: wrap;
  }

  .page__aside {
    width: 25.2%;

    @include max1050 {
      width: 33%;
    }
    @include max800 {

      width: 100%;

    }
  }

  .page__content {
    width: 74.8%;
    @include max1050 {
      width: 62.8%;
      margin-left: 0%;
    }
    @include max800 {

      width: 100%;

    }
  }
}


/**
 Кастом скролл
 */
.custom-scroll_bar-y {
  background: rgb(56, 92, 139);
  opacity: 0.7;
  border-radius: 58px;
  width: 4px;
}

.cust-scroll__horizontal-wrap {
  display: flex;

}
.tabs-simple {
  .custom-scroll_bar-x {
    height:0px;
  }
}



.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.row::after, .row::before {
  content: none
}

.lead {
  font-size: 24px;
  line-height: 1.5
}

@media (min-width: 768px) {
  .pretext {
    margin-top: 15px
  }
}

.p2 {
  font-size: 16px;
  line-height: 1.5
}

@media (min-width: 768px) {
  .p2 {
    font-size: 18px;
    line-height: 1.5
  }
}



//TODO: что за стили разобрать
.page0 {
  min-width: 1920px
}

.page0 .container {
  width: 1400px
}

.page {
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  padding-top: 88px
}

.page__main {
  width: 100%;
  margin: 0 auto;
  //max-width: 1760px;
  position: relative

}


.page__title {

  font-size: 48px;
  margin-bottom: 50px;
  font-weight: bold;
  line-height: 1.3;

  @include max576 {
    font-size: 24px;
    margin-bottom: 30px;
    line-height: 1.3;
    letter-spacing: 0.02em; /*text-transform:uppercase;*/
  }

}

.page__text-bg {
  position: absolute;
  top: 54px;
  left: -156px;
  z-index: -1;
  display: none
}


@media (min-width: 1280px) {
  .page {
    padding-top: 0
  }

  .page__main {
    padding-top: 140px
  }
}

@media (min-width: 1366px) {
  .page__text-bg {
    display: block;
    padding-top: 160px
  }
}

.count {
  font-weight: 700;
  font-size: 30px;
  line-height: 150%
}

.count.count_accent {
  color: #f48f6f
}

.count.count_accent_green {
  color: #5d8d94
}

.count.count_accent_blue {
  color: #385C8B
}

.accent {
  color: #f48f6f
}

.accent-green {
  color: #5d8d94
}

.accent-blue {
  color: #385C8B
}

.grey-block {
  position: relative
}

.grey-block::before {
  content: '';
  display: block;
  position: absolute;
  background: #393c43;
  opacity: .15;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  width: 100%;
  height: 100%
}

.grey-block__content {
  position: relative;
  padding: 58px;
  background: #f5f6fb
}

.grey-block_left::before {
  top: 46px;
  left: -33px
}

.grey-block_right::before {
  top: 46px;
  left: 33px
}

.collapse-more {
  //font-weight: bold;
  color: #385C8B;
  font-size: 20px;
  padding: 0;
  border: none !important;
  background: 0 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;

  .show-more, .show-less {
    //margin-bottom: 37px;
  }
}

.collapse-more .show-more, .collapse-more[aria-expanded=false] .show-more {
  display: block
}

.collapse-more .show-less, .collapse-more[aria-expanded=false] .show-less {
  display: none
}

.collapse-more.toggled .show-more, .collapse-more[aria-expanded=true] .show-more {
  display: none
}

.collapse-more.toggled .show-less, .collapse-more[aria-expanded=true] .show-less {
  display: block
}


@media (max-width: 767px) {
  .mpage-block {
    margin-top: 60px;
  }
}


/**
 ОБЩИЕ СТИЛИ OWL
 */
// х**р знает что ее глючит все активно
.owl-nav.disabled, .owl-nav {

  position: unset;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  opacity: 1;
  left: -1.5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex !important;
  transition: all 0.3s ease-out;
  opacity: 0;

  @include max1050 {
    opacity: 1;
  }
  @include max576 {
    display: none !important;
  }

  &.animate {
    transition: all 0.3s ease-out;
    opacity: 1;
  }

  .owl-prev, .owl-next {
    width: auto;
    position: absolute;
    top: 34%;
  }

  .owl-next {
    right: 0%;
  }


}

.owl-nav {
  .prev, .next {
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 1);

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;


  }

  .prev {
    svg {
      transform: rotate(180deg);

    }
  }

}

.owl-dots {

  @include max576 {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .owl-dot {
    @include max576 {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #f48f6f;
      margin: 0 12px;
      transition: all 0.3s linear;
      opacity: 0.3;

    }


    &.active {
      background: #f48f6f;
      transition: all 0.3s linear;
      position: relative;
      opacity: 1;
      //top: -3px;
      @include max576 {
        //width: 14px;
        //height: 14px;
      }

    }
  }

}

//Галерея
#specGallery {
  .owl-prev, .owl-next {
    top: 26%!important;
  }
}



// топ спецы

.owl-carousel_spec {
  width: 1600px;

  .owl-nav {
    //display: none !important;
  }

  .owl-prev, .owl-next {

    @include max1050 {
      top: 48%;

    }
  }

  .owl-prev {
    left: -1.5%;
  }

  .owl-next {
    right: 20% !important;
    @include max1050 {
      right: 40% !important;
    }
    @include max800 {
      right: 60% !important;
    }
  }

  .owl-dots {
    @include max576 {
      width: 22%;
      justify-content: center;
    }
  }
}

// темы карусель
.themes-carousel {
  width: 120%;

  @include max576 {
    width: 100%;
  }

  .owl-prev, .owl-next {
    top: 39%;
  }

  .owl-prev {
    left: -1.5%;
    @include max800 {
      left: -20px;
    }
  }

  .owl-next {
    right: 18.5% !important;
    @include max1050 {
      right: 22.5% !important;
    }
    @include max800 {
      right: 28.5% !important;
    }
  }

  .owl-dots {
    bottom: 2px !important;
    width: 670px;
    height: 2px;
    background: #D4D4D7;
    display: flex;
    margin-left: 25%;
    margin-top: 31px;
    @include max1050 {
      margin-left: 16%;
    }
    @include max800 {
      margin-left: 7%;
    }
    @include max576 {
      margin-left: 0;
      background: none;
      text-align: center;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    &.disabled {
      width: 670px;
      height: 2px;
      background: #D4D4D7;
      display: flex;
      margin-left: 25%;
      margin-top: 31px;

      @include max1050 {
        margin-left: 16%;
      }
      @include max576 {
        background: none;
      }

    }

    .owl-dot {
      display: block;
      height: 2px;
      width: 100%;


      @include max576 {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #f48f6f;
        margin: 0 12px;
        transition: all 0.3s linear;
        opacity: 0.3;
      }

      &.active {
        background: #f48f6f;
        transition: all 0.3s linear;
        height: 4px;
        top: -1px;
        @include max576 {
          width: 12px;
          height: 12px;
          opacity: 1;
        }
      }
    }
  }
}


//TODO: отрефакторить js html css код блогов




//  блоги карусель
.blog-carousel-wrap, .posts-owl-wrap {
  width: 129%;
  position: relative;
  overflow: hidden;
  padding-left: 39px;
  position: relative;
  left: -33px;
  width: 1800px;
  @include  max576 {
    left: -0px;
    width: 118%;
     padding: 0;
  }

  .posts-owl {
    @include max576 {
      left: -27px;

    }

  }

  .owl-carousel {
    .owl-next,  .owl-prev {
      top: 43%!important;
    }

    .owl-item {
      position: relative;
    }

    .owl-next {
      right: 18.5%;
      @include max1050 {
        right: 39%;
      }
      @include max800 {
        right: 58.6%;
      }
    }

    .owl-nav {
      &.animate {
        top: 33%;
      }
    }
  }

}


.reset {
  padding: 0;
  border: none;
  background: 0 0;
  text-decoration: none;
  margin-left: 10px;
  display: inline-block;
  color: #b3b3b7;
  cursor: pointer
}

.reset .svg-icon {
  width: 10px;
  height: 10px
}

.reset-text {
  font-size: 14px
}

@media (max-width: 991px) {
  .page_pseudomodal .page__title {
    font-size: 21px;
    letter-spacing: .02em;
    margin-bottom: 20px
  }

  .page_pseudomodal p {
    font-size: 16px
  }

  .page_pseudomodal .modal-form {
    margin-top: 28px
  }

  .page_pseudomodal .radio-wrap {
    margin-bottom: 0
  }

  .page_pseudomodal .textarea-wrap__text, .page_pseudomodal textarea.form-control {
    font-size: 14px
  }

  .page_pseudomodal .modal-btn {
    width: 100%;
    height: 50px
  }
}

@media (max-width: 991px) {
  .aside-filter {
    position: fixed;
    top: 0;
    right: 0;
    background: #fff;
    height: 100vh !important;
    z-index: 99;
    width: 90%;
    padding: 10px 15px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%)
  }

  .aside-filter.in {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-box-shadow: 0 0 85px rgba(0, 0, 0, .9);
    box-shadow: 0 0 85px rgba(0, 0, 0, .9)
  }

  .aside-filter__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px
  }

  .aside-filter__header h3 {
    font-weight: 600;
    font-size: 21px
  }

  .aside-filter__header .reset {
    margin-top: 5px
  }

  .aside-filter__header .close {
    padding: 0;
    border: none;
    background: 0 0;
    text-decoration: none;
    display: inline-block;
    cursor: pointer
  }

  .aside-filter__header .close .svg-icon {
    width: 18px;
    height: 18px
  }

  .aside-filter .checkbox-wrap, .aside-filter .radio-wrap {
    margin-bottom: 10px
  }
}

@media (min-width: 992px) {
  .aside-filter {
    height: auto !important;
    visibility: visible !important;
    display: block !important
  }
}

.aside-menu {
  margin-top: 10px;
  margin-bottom: 75px
}

.aside-menu h4 {
  letter-spacing: 0;
  margin-bottom: 36px
}

.aside-menu ul {
  list-style: none;
  margin: 0;
  padding: 0
}

.aside-menu ul li {
  margin-bottom: 10px;
  font-size: 14px
}

.aside-menu ul a:hover {
  opacity: .5
}

.aside-menu ul a.active {
  font-weight: 600
}

@media (max-width: 991px) {
  .aside-menu {
    margin: -5px 5px 25px
  }
}

hr {
  border-color: rgba(56, 92, 139, .3);
  margin-bottom: 45px
}

.verification p {
  margin-bottom: 35px
}

.verification img {
  margin-right: 25px;
  margin-left: 25px
}


.owl-carousel-wrap_catalog {
  margin-left: -15px;
  padding-left: 0;
}

.owl-carousel-wrap .container {
  margin-left: 0
}

//.owl-carousel .owl-stage {
//  display: -webkit-box;
//  display: -ms-flexbox;
//  display: flex;
//  padding-top: 20px;
//}


.notififation-wrap {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;

  &.active {
    z-index: 10
  }

  &__icon {
    //TODO: надо бы перебрать иконки в шапке там каша стилей и html
    @include max1050 {
      width: 23px !important;
      height: 23px !important;
      margin-left: 11px !important;
      margin-right: 7px !important;
      svg {
        width: 100% !important;
        height: 100% important;
      }


    }
  }
}


.active .notififation-wrap__icon, .active .notififation-wrap__icon .counter {
  color: #252733 !important
}

.notififation-list {
  margin-left: -15px;
  margin-right: -15px
}

@media (min-width: 1280px) {
  .notififation-list {
    position: absolute;
    right: -10px;
    top: -15px;
    -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, .5);
    box-shadow: 0 10px 15px rgba(0, 0, 0, .5);
    width: 364px;
    margin-left: 0;
    margin-right: 0;
    display: none;
    background: #fff
  }

  .active .notififation-list {
    display: block
  }
}

.notififation-list__head {
  font-size: 16px;
  line-height: 23px;
  color: rgba(56, 92, 139, .8);
  padding: 10px 17px
}

.notififation-list .notififation-item {
  padding: 11px 17px;
  font-size: 14px;
  line-height: 23px;
  color: #5d5d64 !important
}

.notififation-list .notififation-item a {
  color: #252733 !important;
  margin: 0 !important
}

.notififation-list .notififation-item:nth-child(odd) {
  background: #f7f7ff
}

.tabs-simple {
  margin-bottom: 70px;

  @include max800 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow-x: auto;
    margin-right: -15px;
    width: calc(110% + 15px);
    padding-bottom: 15px;
    margin-bottom: 30px;
    @include max576 {
      width: 100%;
    }
    &::after {
      content: '';
      display: block;
      width: 15px;
      height: 1px;
      -ms-flex-negative: 0;
      flex-shrink: 0
    }
  }

   a {
    position: relative;
    margin-right: 55px;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: .02em;
    transition: all 0.1s linear;

    span::before {
      content: "";
      display: block;
      background: #f48f6f;
      width: 0%;
      height: 9px;
      position: absolute;
      bottom: 2px;
      z-index: -1;
      left: 0;
      opacity: .5;
      transition: all 0.1s linear;
    }

    &.active, &:hover {
      font-weight: 800;
      transition: all 0.1s linear;

      span::before {
        width: 122%;
        transition: all 0.1s linear;

      }
    }
  }


}

ul.styled {
  list-style: none;
  padding-left: 0;
  margin: 30px 0
}

ul.styled li {
  position: relative;
  padding-left: 33px;
  margin-bottom: 10px
}

ul.styled li::before {
  content: '';
  width: 12px;
  height: 12px;
  position: absolute;
  top: 5px;
  left: 0;
  background: #f48f6f
}

.theme-coach ul.styled li::before {
  background: #f48f6f
}

.theme-consult ul.styled li::before {
  background: #f48f6f
}

.theme-mentor ul.styled li::before {
  background: #385C8B
}

.theme-psycologist ul.styled li::before {
  background: #5d8d94
}

ol.styled {
  list-style: none;
  padding-left: 0;
  margin: 30px 0;
  counter-reset: list-counter
}

ol.styled li {
  position: relative;
  padding-left: 33px;
  margin-bottom: 10px
}

ol.styled li::before {
  content: counter(list-counter, decimal) ".";
  width: 12px;
  position: absolute;
  top: 0;
  left: 0;
  color: #f48f6f;
  counter-increment: list-counter;
  font-weight: 700;
  font-size: 1.1em
}

.theme-coach ol.styled li::before {
  color: #f48f6f
}

.theme-consult ol.styled li::before {
  color: #f48f6f
}

.theme-mentor ol.styled li::before {
  color: #385C8B
}

.theme-psycologist ol.styled li::before {
  color: #5d8d94
}

.hovered-image {
  position: relative;
  overflow: hidden;
  cursor: pointer
}

.hovered-image img {
  -webkit-transition: 1.5s;
  -o-transition: 1.5s;
  transition: 1.5s
}

.hovered-image:hover img {
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
  transform: scale(1.15)
}

.svg-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  fill: currentColor
}

.svg-icon svg {
  max-width: 100%;
  max-height: 100%;
  vertical-align: top
}

.header {
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  -webkit-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity .5s;
}

.header__container {
  max-width: 1760px;
  margin: 0 auto;
  position: relative;
  padding: 15px 0 15px;
  -webkit-transition: padding-top .5s, padding-bottom .5s;
  -o-transition: padding-top .5s, padding-bottom .5s;
  transition: padding-top .5s, padding-bottom .5s;
}

.header__logo {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 15px;
  margin-left: 7px
}

.header__logo img {
  height: 43px;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
}

.header__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.header__menu {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1
}

.header__cabinet {
  margin-left: auto;
  margin-right: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.header__cabinet a {
  text-decoration: none;
  color: #385C8B;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  margin: 0 0 0 10px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 18px
}

.header__cabinet a .counter {
  position: absolute;
  display: block;
  top: -14px;
  right: -8px;
  font-size: 10px;
  line-height: 25px;
  letter-spacing: .02em;
  color: #252733
}

.header__cabinet a.header__user {
  width: 25px
}

.header__user-container {
  position: relative;
  margin-left: 20px;
}

.header__user-container .header__user img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.header__user-container::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 5px 25px rgba(30, 31, 33, 0.12);
  box-shadow: 0px 5px 25px rgba(30, 31, 33, 0.12);
  border-radius: 10px;
  pointer-events: none;
}

.header__user-header {
  border: none !important;
  background: #fff !important;
  padding: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  position: relative;
  z-index: 1001;
  border-radius: 10px;
}

.header__user-header .fa {
  margin: 0 15px;
  font-size: 20px;
}

.header__user img {
  margin-right: 10px;
}

.header__user-menu {
  border: none;
  background: #FFFFFF;
  -webkit-box-shadow: 0px 5px 25px rgba(30, 31, 33, 0.12);
  box-shadow: 0px 5px 25px rgba(30, 31, 33, 0.12);
  border-radius: 0 0 10px 10px;
  margin-top: -10px;
  padding-top: 10px;
  min-width: 100%;
  left: auto;
  right: 0;
}

.header__user-menu .dropdown-item {
  padding: 10px 17px;
  display: block;
  height: auto;
  margin: 0;
  color: #5D5D64 !important;
}

.header__user-menu .dropdown-item:hover {
  background: #F7F7FF;
  color: #252733 !important;
}

.header__cabinet .svg-icon, .header__search .svg-icon {
  width: 15px;
  height: 18px;
  fill: currentColor;
  opacity: .8
}

.header__cabinet-link {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: .02em
}

.header__cabinet-link .svg-icon {
  margin-right: 10px;
  width: 30px !important;
  height: 30px !important
}

.header__search {
  margin-right: 38px;
  margin-top: 7px
}

.header__burger {
  position: absolute;
  top: 15px;
  right: 10px;
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer
}

.header__burger .navbar-toggle {
  display: block;
  padding: 0;
  height: 25px;
  width: 20px;
  margin: 0;
  position: relative
}

.header__burger .icon-bar {
  pointer-events: none;
  display: block;
  height: 2px;
  width: 20px;
  margin: 5px 0;
  background: #252733;
  position: absolute;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out
}

.header__burger .icon-bar:nth-child(1) {
  top: 3px;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center
}

.menu-open .header__burger .icon-bar:nth-child(1) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 16px;
  left: 5px
}

.header__burger .icon-bar:nth-child(2) {
  top: 12px;
  -webkit-transform-origin: center center;
  -ms-transform-origin: center center;
  transform-origin: center center
}

.menu-open .header__burger .icon-bar:nth-child(2) {
  width: 0;
  opacity: 0
}

.header__burger .icon-bar:nth-child(3) {
  top: 20px;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center
}

.menu-open .header__burger .icon-bar:nth-child(3) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 3px;
  left: 5px
}

@media (max-width: 1279px) {
  .header {
    -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, .01);
    box-shadow: 0 10px 15px rgba(0, 0, 0, .01);
    position: fixed;
    background: #fff;
  }

  .header__menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff
  }

  .header__container::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: .5;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
    display: none;
  }

  .menu-open .header__container::before {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    display: block;
  }
}

.page_spec .header__user-name, .page_client .header__user-name {
  display: none;
}

.page_spec .header__user-menu, .page_client .header__user-menu {
  margin-top: -5px;
  padding-top: 5px;
  min-width: 200px;
  border-radius: 10px 0 10px 10px;
}

.page_spec .header__user-header .fa, .page_client .header__user-header .fa {
  margin: 0 5px;
}

@media (max-width: 480px) {
  .header__user-name, .header__user-header .fa {
    display: none;
  }

  .header__user-header {
    padding: 0;
  }

  .header__user-container::before {
    content: none;
  }

  .header__user-menu {
    margin-top: 10px;
    padding-top: 0;
    min-width: 200px;
    border-radius: 10px 0 10px 10px;
  }
}

@media (min-width: 1280px) {
  .header {
    margin-bottom: 20px
  }

  .header__container {
    padding: 25px 0 28px;
  }

  .page_header-fix .header__container {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .header__container::before {
    display: none !important
  }

  @-moz-document url-prefix() {
    .header__container::before {
      content: '' !important;
      background: transparent;
      display: block !important;
      position: absolute;
      top: 0;
      left: 50%;
    }
    .page_header-fix .header__container::before {
      background: #fff;
      width: 117vw;
      margin-left: -59.5vw;
      height: 100%;
    }
    .header__container .container-fluid {
      position: relative
    }
  }
  .header__logo {
    min-width: 12%;
    margin-right: 30px;
    margin-left: 40px;
  }

  .header__logo a {
    display: block;
    height: 48px;
    -webkit-transition: height .5s;
    -o-transition: height .5s;
    transition: height .5s;
  }

  .page_header-fix .header__logo a {
    height: 48px;
  }

  .header__logo img {
    height: 86px;
    -webkit-transition: height .5s;
    -o-transition: height .5s;
    transition: height .5s;
  }

  .page_header-fix .header__logo img {
    height: 56px;
  }

  .header__burger {
    display: none
  }

  .header__cabinet {
    margin-right: 0
  }

  .header__cabinet a {
    color: #252733;
    margin-right: 3px
  }

  .header__cabinet a.header__user {
    width: 35px
  }

  .header__cabinet .svg-icon, .header__search .svg-icon {
    width: 20px;
    height: 24px
  }
}

.header-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.header-menu__item {
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  text-transform: uppercase;
  margin-right: 30px;
  margin-left: 30px;
  position: relative;
}

.header-menu__item:hover {
  z-index: 2;
}

.header-menu .header-menu__item.has-submenu:hover {
  color: #252733 !important;
}

.header-menu .header-menu__item:hover .header-menu__submenu {
  display: block;
}

.header-menu a {
  text-decoration: none;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  position: relative;
  z-index: 1;
}

.header-menu a:hover {
  opacity: .5
}

.header-menu a.active {
  font-weight: 700
}

.header-menu .header-menu__submenu {
  position: absolute;
  top: 100%;
  left: -15px;
  min-width: 180px;
  margin-top: 12px;
  display: none;
}

.header-menu .header-menu__submenu::before {
  content: '';
  display: block;
  position: absolute;
  top: -44px;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, .95);
  -webkit-box-shadow: 0px 5px 25px rgba(30, 31, 33, 0.12);
  box-shadow: 0px 5px 25px rgba(30, 31, 33, 0.12);
}

.header-menu .header-menu__submenu a {
  text-transform: none;
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #5D5D64 !important;
  padding: 10px 17px;
}

.header-menu .header-menu__submenu a:hover, .header-menu .header-menu__submenu a.active {
  color: #252733 !important;
  background: #F7F7FF;
  opacity: 1;
}

@media (max-width: 1279px) {
  .header-menu {
    display: none;
    border-bottom: 1px solid #eee;
    -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, .01);
    box-shadow: 0 10px 15px rgba(0, 0, 0, .01)
  }

  .header-menu__item {
    margin-right: 0;
    border-top: 1px solid #eee
  }

  .header-menu a {
    padding: 10px 15px;
    display: block
  }
}

@media (min-width: 1600px) {
  .header-menu__item {
    margin-right: 45px;
    margin-left: 45px;
  }
}

@import "partials/menu-mobile";

@import "partials/footer.scss";

@import "partials/modals";


/**
  BTNS
  TODO: Надо разобрать стили кнопок а то каша
 */

// btn-info рабочее
.btn-info {

  background: 0 0 !important;
  color: #252733 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  position: relative;
  border: none;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  @include max576 {
    font-size: 14px;
  }

  &.btn-info__blue::after {
    background: #385C8B;
  }

  &::after {
    content: '';
    position: absolute;
    height: 13px;
    opacity: 0.5;
    width: 100%;
    background: #F48F6F;
    bottom: 6px;
    left: 0;
    z-index: -1;
  }
}

.btn-info:hover {
  color: #fff !important;
}

.btn-info::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  border-radius: 12px;
  z-index: -1;
  transition: all 0.2s linear;

}

//.btn-info span {
//  display: inline-block;
//  position: relative;
//  //background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #f02f17 51%, #f48f6f 51%, #f48f6f 100%);
//  //background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(51%, rgba(255, 255, 255, 0)), color-stop(51%, #f02f17), color-stop(51%, #f48f6f), to(#f48f6f));
//  //background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #f02f17 51%, #f48f6f 51%, #f48f6f 100%)
//  background: rgba(244,143, 111,0.8);
//}


.btn-info:hover::after {
  //opacity: 1;
}


.btn-info:active::before, .btn-info:focus::before, .btn-info:hover::before {
  top: -7px;
  bottom: -10px;
  left: -20px;
  z-index: -1;
  right: -20px;
  height: 46px;
  background: #F48F6F;
  transition: all 0.2s linear;
}

.btn-info.btn-info__blue:active::before, .btn-info.btn-info__blue:focus::before, .btn-info.btn-info__blue:hover::before {
  background: #385C8B;
}


//.btn-info:active::before, .btn-info:focus::before {
//  background: #f48f6f
//}

.btn-info.btn-inverse span {
  color: #fff
}

.btn-info.btn-inverse .svg-icon {
  fill: #fff
}






.theme-psycologist .btn-info {
  &::after {
    background: #5d8d94;
  }
}
.theme-psycologist .btn-info:active::before, .theme-psycologist .btn-info:focus::before, .theme-psycologist .btn-info:hover::before {
  background: #5d8d94;
}
.theme-mentor .btn-info {
  &::after {
    background: #385C8B;
  }
}
.theme-mentor .btn-info:active::before, .theme-mentor .btn-info:focus::before, .theme-mentor .btn-info:hover::before {
  background: #385C8B;
}







.btn__outline {
  padding: 16px 40px;
  font-size: 16px;
  border: 1px solid #F48F6F;
  color: #252733;
  display: inline-block;
  border-radius: 10px;

  cursor: pointer;
  transition: all 0.3s linear;

  @include max576 {
    //font-size: 14px;
    //padding: 10px 20px;
  }

  &:hover {
    background: #F48F6F;
    box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.15);
    color: #fff;
    transition: all 0.3s linear;
  }
}

.btn__primary {
  padding: 16px 40px;
  font-size: 16px;
  border: 1px solid #F48F6F;
  color: #252733;
  display: inline-block;
  border-radius: 10px;
  box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.15);
  background: #F48F6F;
  cursor: pointer;
  @include max576 {
    //font-size: 14px;
    //padding: 10px 20px;
  }
}

.btn__round {
  border: 1px solid #385C8B;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border-radius: 40px;
  padding: 8px 20px;
  text-transform: uppercase;
  font-size: 12px;

  &:hover {
    background: #385C8B;
    color: #fff;
    transition: all 0.3s linear;
  }
}


// используется
.btn-bordered {
  text-transform: lowercase;
  border: 2px solid #385C8B;
  border-radius: 20px;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding: 16px 25px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
}

.theme-mentor .btn-bordered {
  border-color: #385C8B;
}

.theme-mentor .btn-bordered:hover {
  border-color: #385C8B;
  color: #fff;
  background: #385C8B;
}

.theme-coach .btn-bordered {
  border-color: #f48f6f;
}

.theme-coach .btn-bordered:hover {
  border-color: #f48f6f;
  color: #fff;
  background: #f48f6f;
}

.theme-psycologist .btn-bordered {
  border-color: #5D8D94;
}

.theme-psycologist .btn-bordered:hover {
  border-color: #5D8D94;
  color: #fff;
  background: #5D8D94;
}


.btn-accent {
  background: #385C8B;
  border-color: #385C8B;
  color: #fff !important;
  min-width: 230px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
  -webkit-box-shadow: 19px 14px 30px rgba(56, 92, 139, .6) !important;
  box-shadow: 19px 14px 30px rgba(56, 92, 139, .6) !important;
  letter-spacing: .02em;
  height: 50px;
  border-radius: 100px;
  &:hover {
    border-color: #32527d;
    background: #32527d;
    color: #fff;
  }
  &:active, &:focus {
    color: #fff;
    border-color: #32527d;
    background: #32527d;
  }
}





//?? хз
//.btn-link {
//  display: -webkit-inline-box;
//  display: -ms-inline-flexbox;
//  display: inline-flex;
//  -webkit-box-align: center;
//  -ms-flex-align: center;
//  align-items: center;
//  cursor: pointer
//}
//
//.btn-link .svg-icon {
//  margin-right: 10px
//}

//.btn {
//  font-weight: 700;
//  font-size: 14px;
//  line-height: 1.5;
//  letter-spacing: .02em;
//  text-transform: uppercase;
//  padding: 0;
//  display: -webkit-inline-box;
//  display: -ms-inline-flexbox;
//  display: inline-flex;
//  -webkit-box-align: center;
//  -ms-flex-align: center;
//  align-items: center;
//  color: #252733;
//  border-radius: 12px;
//  border: none;
//  position: relative;
//  margin-right: 40px
//}

//.btn .svg-icon_arrow {
//  margin: 0 0 0 34px;
//  width: 33px;
//  position: relative
//}
//
//@media (max-width: 991px) {
//  .btn {
//    margin-right: 10px
//  }
//}
//
//@media (min-width: 992px) {
//  .btn {
//    font-size: 16px
//  }
//
//  .btn img {
//    margin: 0 12px
//  }
//}

//?? хз
//.btn-primary {
//  background: 0 0 !important;
//  color: #252733 !important;
//  -webkit-box-shadow: none !important;
//  box-shadow: none !important;
//  border-radius: 0 !important;
//}
//
//.btn-primary::before {
//  content: '';
//  display: block;
//  position: absolute;
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 0;
//  z-index: 0;
//  -webkit-transition: .3s;
//  -o-transition: .3s;
//  transition: .3s;
//  border-radius: 12px;
//}
//
//.btn-primary span {
//  display: inline-block;
//  position: relative;
//  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #385C8B 51%, rgba(56, 92, 139, .3) 51%, rgba(56, 92, 139, .3) 100%);
//  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(51%, rgba(255, 255, 255, 0)), color-stop(51%, #385C8B), color-stop(51%, rgba(56, 92, 139, .3)), to(rgba(56, 92, 139, .3)));
//  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #385C8B 51%, rgba(56, 92, 139, .3) 51%, rgba(56, 92, 139, .3) 100%)
//}
//
//.btn-primary:active::before, .btn-primary:focus::before, .btn-primary:hover::before {
//  top: -10px;
//  bottom: -10px;
//  left: -20px;
//  right: -20px;
//}
//
//.btn-primary:active .svg-icon, .btn-primary:focus .svg-icon, .btn-primary:hover .svg-icon {
//  fill: #fff
//}
//
//.btn-primary:active span, .btn-primary:focus span, .btn-primary:hover span {
//  color: #fff;
//  background: 0 0 !important
//}
//
//.btn-primary:hover::before {
//  background: #385C8B
//}
//
//.btn-primary:active::before, .btn-primary:focus::before {
//  background: #385C8B
//}
//
//.btn-primary.btn-inverse span {
//  color: #fff
//}
//
//.btn-primary.btn-inverse .svg-icon {
//  fill: #fff
//}


//
//.btn-round {
//  display: -webkit-inline-box;
//  display: -ms-inline-flexbox;
//  display: inline-flex;
//  -webkit-box-align: center;
//  -ms-flex-align: center;
//  align-items: center;
//  border-radius: 100px;
//  text-transform: none;
//  font-weight: 500;
//  font-size: 16px;
//  line-height: 20px;
//  height: 50px;
//  border: 1px solid transparent;
//  -webkit-box-shadow: none !important;
//  box-shadow: none !important;
//  position: relative
//}
//
//.btn-round::before {
//  content: none !important
//}
//
//.btn-round span {
//  background: 0 0;
//  color: inherit
//}
//
//.btn-round.btn-default {
//  background: #f5f6fb;
//  border-color: #f5f6fb;
//  color: #385C8B;
//  min-width: 230px;
//  -webkit-box-pack: center;
//  -ms-flex-pack: center;
//  justify-content: center;
//  padding-left: 15px;
//  padding-right: 15px
//}
//
//.btn-round.btn-default:active, .btn-round.btn-default:focus, .btn-round.btn-default:hover {
//  border-color: #eeeff8;
//  background: #eeeff8
//}
//
//.btn-round.btn-accent {
//  background: #385C8B;
//  border-color: #385C8B;
//  color: #fff;
//  min-width: 230px;
//  -webkit-box-pack: center;
//  -ms-flex-pack: center;
//  justify-content: center;
//  padding-left: 15px;
//  padding-right: 15px;
//  font-weight: 600;
//  -webkit-box-shadow: 19px 14px 30px rgba(56, 92, 139, .6) !important;
//  box-shadow: 19px 14px 30px rgba(56, 92, 139, .6) !important;
//  letter-spacing: .02em
//}
//
//.btn-round.btn-accent:hover {
//  border-color: #32527d;
//  background: #32527d
//}
//
//.btn-round.btn-accent:active, .btn-round.btn-accent:focus {
//  border-color: #32527d;
//  background: #32527d
//}
//
//.btn-round.btn-bordered {
//  background: #fff;
//  border-color: #385C8B;
//  color: #385C8B;
//  min-width: 230px;
//  -webkit-box-pack: center;
//  -ms-flex-pack: center;
//  justify-content: center;
//  padding-left: 15px;
//  padding-right: 15px;
//  font-weight: 600
//}
//
//.btn-round.btn-bordered:hover {
//  border-color: #32527d;
//  background: #32527d;
//  color: #fff
//}
//
//.btn-round.btn-bordered:active, .btn-round.btn-bordered:focus {
//  border-color: #32527d;
//  background: #32527d;
//  color: #fff
//}
//
//.btn-round.btn-bordered.disabled {
//  border-color: #b3b3b7;
//  color: #b3b3b7;
//  cursor: not-allowed;
//  pointer-events: none
//}
//
//.btn-round.btn-bordered.success {
//  border-color: #5d8d94;
//  color: #5d8d94
//}
//
//.btn-round.btn-bordered.success:hover {
//  border-color: #537e85;
//  background: #537e85;
//  color: #fff
//}
//
//.btn-round.btn-bordered.success:active, .btn-round.btn-bordered.success:focus {
//  border-color: #4A7177;
//  background: #4A7177;
//  color: #fff
//}
//
//@media (max-width: 991px) {
//  .btn-round {
//    font-size: 14px;
//    height: 40px
//  }
//
//  .btn-round.btn-default {
//    min-width: 186px
//  }
//}
//
//.btn-edit, .btn-reset {
//  background: 0 0 !important;
//  -webkit-box-shadow: none !important;
//  box-shadow: none !important;
//  border: 1px solid transparent !important;
//  padding-left: 0;
//  padding-right: 0;
//  color: #b3b3b7;
//  text-transform: none;
//  font-weight: 500;
//  font-size: 16px;
//  line-height: 20px;
//  height: 50px;
//  margin-right: 0
//}
//
//@media (max-width: 991px) {
//  .btn-edit, .btn-reset {
//    font-size: 14px;
//    height: 40px
//  }
//}
//
//


.form-control {
  background: 0 0 !important;
  border: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-bottom: 2px solid #dfdfe1;
  font-size: 16px;
  border-radius: 0;
  padding: 0
}

.form-control::-webkit-input-placeholder {
  color: rgba(37, 39, 51, .5)
}

.form-control::-moz-placeholder {
  color: rgba(37, 39, 51, .5)
}

.form-control:-ms-input-placeholder {
  color: rgba(37, 39, 51, .5)
}

.form-control::-ms-input-placeholder {
  color: rgba(37, 39, 51, .5)
}

.form-control::placeholder {
  color: rgba(37, 39, 51, .5)
}

.form-control.ui-datepicker {
  padding-right: 30px
}

textarea::-webkit-scrollbar {
  width: 3px
}

textarea::-webkit-scrollbar-track {
  background: rgba(37, 39, 51, .5);
  -webkit-box-shadow: inset 0 0 0 1px #fff;
  box-shadow: inset 0 0 0 1px #fff
}

textarea::-webkit-scrollbar-thumb {
  background: #385C8B
}

.control-label {
  margin-top: 10px;
  line-height: 1.7;
  padding: 0 5px
}

.form-group .select2 {
  display: block;
  width: 100% !important
}

.select2-container--open .select2-dropdown {
  min-width: 200px;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, .1)
}

.select2-results {
  background: #fff
}

.select2-dropdown {
  border: none;
  border-radius: 0
}

.select2-container--default .select2-selection--single {
  border: none;
  border-bottom: 2px solid #dfdfe1;
  border-radius: 0;
  font-size: 16px;
  line-height: 150%;
  background: 0 0;
  height: 35px
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-bottom-color: #66afe9;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 35px
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  padding-right: 30px
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: none;
  color: #385C8B
}

.select2-container--default .select2-selection--single .select2-selection__arrow b::before {
  content: "\f107";
  font-family: FontAwesome;
  font-size: 18px;
  position: absolute;
  top: -4px;
  right: -8px
}

.form-group .select2-container--default .select2-selection--single .select2-selection__arrow b {
  color: #252733
}

.checkbox-wrap {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 45px
}

.checkbox-wrap label {
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  cursor: pointer;
  color: rgba(37, 39, 51, .8);
  font-size: 14px;
  margin-bottom: 9px
}

.checkbox-wrap label::before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background: #f5f6fb;
  margin-right: 20px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-top: 2px
}

.checkbox-wrap.inverted label::before {
  background: #fff
}

.checkbox-wrap input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0
}

.checkbox-wrap input:checked + label {
  color: #252733;
  font-weight: 600
}

.checkbox-wrap input:checked + label::before {
  background: #385C8B
}

@media (max-width: 991px) {
  .checkbox-wrap {
    margin-bottom: 30px
  }
}

@media (min-width: 992px) {
  .checkbox-wrap:last-child {
    margin-right: 0
  }
}

.radio-wrap {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 45px;
  cursor: pointer;
}

.radio-wrap label {
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  cursor: pointer;
  color: rgba(37, 39, 51, .8);
  font-size: 16px;
  margin-bottom: 9px
}

.radio-wrap label::before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background: #f5f6fb;
  margin-right: 18px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-top: 6px;
  border-radius: 50%;
  border: 1px solid rgba(56, 92, 139, .5);
}

.radio-wrap input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0
}

.radio-wrap input:checked + label {
  color: #252733;
  font-weight: 600
}

.radio-wrap input:checked + label::before {
  background: #385C8B
}

.radio-wrap:last-child {
  margin-right: 0
}

.filter_level-2 {
  padding-left: 28px;
  margin-bottom: 30px;
}

.clear-filter {
  margin-bottom: 30px;
  border: none;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #385C8B;
  font-size: 14px;
  padding: 0;
  display: inline-block;
}

@media (max-width: 991px) {
  .radio-wrap {
    margin-bottom: 30px
  }
}

@media (max-width: 480px) {
  .radio-wraps_justify .radio-wrap {
    margin: 0 0 20px;
    width: 49%
  }

  .checkboxes_justify .checkbox-wrap {
    margin: 0 0 20px;
    width: 49%
  }
}

.form-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 15px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: .02em;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: -45px
}

.form-bottom .btn {
  margin-right: 35px;
  margin-top: 15px;
  margin-bottom: 15px
}

.form-bottom .btn .svg-icon_arrow {
  margin-left: 25px
}

.form-bottom a {
  color: #2274a5;
  text-decoration: underline
}

.form-bottom a:hover {
  text-decoration: none
}

@media (min-width: 768px) {
  .form-bottom {
    margin-top: 0
  }
}

.form-group_pass .icon-pass-show {
  display: none
}

.form-group_pass .icon-pass-hide {
  display: block
}

.form-group_pass.show-pass .icon-pass-show {
  display: block
}

.form-group_pass.show-pass .icon-pass-hide {
  display: none
}

.form-group_file {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-bottom: 8px
}

.form-group_file input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer
}

.form-group_file label {
  cursor: pointer;
  color: #385C8B;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 0 5px
}

@media (max-width: 991px) {
  .form-group_file label {
    font-size: 14px
  }
}

.form-group {
  position: relative;
  margin-bottom: 42px
}

.form-group .control-label {
  padding: 0;
  font-weight: 400
}

.form-group .form-control-wrap {
  position: relative
}

.form-group .form-control-wrap_with-icon .form-icon {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0;
  top: 50%;
  margin-top: -15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #4267b2
}

.form-group .form-control-wrap_with-icon .form-control {
  padding-left: 30px
}

.form-group .form-control-wrap .showpass {
  position: absolute;
  right: -2px;
  top: 50%;
  margin-top: -15px;
  color: #b3b3b7;
  line-height: 0;
  padding: 5px;
  cursor: pointer
}

.form-group .form-control-wrap .showpass .svg-icon {
  width: 20px;
  height: 20px
}

.form-group .form-control-wrap .reset {
  position: absolute;
  right: -10px;
  top: 50%;
  margin-top: -15px;
  line-height: 0;
  padding: 10px
}

.form-group .form-control-wrap .edit {
  position: absolute;
  right: -10px;
  top: 50%;
  margin-top: -12px;
  padding: 0 10px;
  color: #b3b3b7;
}

.form-group .form-control-wrap .calend {
  position: absolute;
  right: -2px;
  top: 50%;
  margin-top: -15px;
  line-height: 0;
  color: #b3b3b7
}

.form-group__hint {
  margin-top: 10px
}

.form-group__hint a {
  color: #385C8B
}

.sort-wrap .select2 {
  display: inline-block;
  width: auto !important
}

.sort-wrap .select2-container--default .select2-selection--single {
  font-weight: 400;
  font-size: 14px;
  border-bottom: none
}

.textarea-wrap {
  position: relative;
  padding: 5px 0;
  min-height: 3em
}

.textarea-wrap__text {
  opacity: 0;
  font-size: inherit
}

.textarea-wrap textarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: inherit
}

@media (min-width: 768px) {
  .form-inline-row .control-label {
    margin-top: -5px
  }
}

.form-group_price .form-control {
  color: #385C8B;
  width: 120px
}

.breadcrumbs {
  font-size: 12px;
  line-height: 25px;
  letter-spacing: .02em;
  margin-bottom: 18px
}

.breadcrumbs a {
  opacity: .5;
  text-decoration: none;
  margin-right: 20px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}

.breadcrumbs a:hover {
  opacity: 1
}

@media (min-width: 768px) {
  .breadcrumbs {
    margin-bottom: 22px
  }
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 50px 0 0
}

.pagination__item {
  width: 30px;
  height: 30px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 7px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px;
  line-height: 18px;
  border-radius: 50%
}

.pagination__item_current {
  color: #fff;
  background: #385C8B;
  font-weight: 700
}

.pagination__next {
  color: #385C8B;
  line-height: 1;
  margin-left: 20px
}

.pagination__next .svg-icon {
  width: 118px
}

.pretext_feedback {
  margin-top: 28px;
  margin-bottom: 36px
}

@media (min-width: 768px) {
  .pretext_feedback {
    margin-top: 43px;
    margin-bottom: 70px
  }
}

.feedback {
  position: relative
}

.feedback::before {
  content: '';
  display: block;
  position: absolute;
  top: 20px;
  left: 40px;
  width: 100%;
  height: 100%;
  background: #393c43;
  opacity: .2;
  -webkit-filter: blur(50px);
  filter: blur(50px)
}

.feedback__content {
  background: #f5f6fb;
  padding: 25px 15px 100px;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
  border-radius: 25px;
}

.feedback__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 15px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: .02em;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: -45px
}

.feedback__bottom .btn {
  margin-right: 85px;
  margin-top: 15px;
  margin-bottom: 15px
}

.feedback__bottom .btn .svg-icon_arrow {
  margin-left: 25px
}

.feedback__bottom a {
  color: #2274a5;
  text-decoration: underline
}

.feedback__bottom a:hover {
  text-decoration: none
}

.feedback .textarea-wrap {
  min-height: 78px
}

@media (min-width: 768px) {
  .feedback__content {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 70px;
    padding: 25px
  }

  .feedback__bottom {
    margin-top: 0
  }

  .feedback .control-label {
    margin-top: 26px
  }
}

@media (min-width: 992px) {
  .feedback__content {
    margin-bottom: 120px;
    padding: 46px 60px 55px
  }

  .feedback .col-xs-12 .form-group {
    padding-right: 7.2%
  }

  .feedback .col-sm-6 .form-group {
    padding-right: 15%
  }
}

.scroll-pane {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 315px;
  position: relative
}

.scroller {
  height: 100%;
  max-height: 100%;
  overflow-y: auto
}

.scroller.with-scroll + .scroller__bar-wrapper {
  display: block !important
}

.scroller::-webkit-scrollbar {
  width: 0
}

.scroller__content {
  position: relative;
  padding-right: 30px;
  overflow: hidden
}

.scroller__bar-wrapper {
  position: absolute;
  z-index: 2;
  top: 10px;
  bottom: 10px;
  right: 5px;
  width: 3px;
  border-radius: 5px;
  background: rgba(37, 39, 51, .5);
  -webkit-box-shadow: inset 0 0 0 1px #fff;
  box-shadow: inset 0 0 0 1px #fff;
  pointer-events: none;
  -webkit-transition: all .2s linear 0s;
  -o-transition: all .2s linear 0s;
  transition: all .2s linear 0s
}

.mainpage-faq .scroller__bar-wrapper {
  background: rgba(255, 255, 255, .5);
  -webkit-box-shadow: none;
  box-shadow: none
}

.scroller__bar {
  position: absolute;
  z-index: 1;
  width: 3px;
  border-radius: 3px;
  -webkit-transition: opacity .2s linear 0s;
  -o-transition: opacity .2s linear 0s;
  transition: opacity .2s linear 0s;
  pointer-events: auto;
  background: #385C8B
}

.theme-coach .scroller__bar {
  background: #f48f6f
}

.theme-consult .scroller__bar {
  background: #f48f6f
}

.theme-mentor .scroller__bar {
  background: #385C8B
}

.theme-psycologist .scroller__bar {
  background: #5d8d94
}

.mainpage-faq .scroller__bar {
  background: #f48f6f
}

.page .aside-filter .scroller__bar {
  background: #385C8B
}

.baron:hover .scroller__bar-wrapper {
  opacity: 1
}

.baron:hover .scroller__bar {
  opacity: 1
}

.payment-history__sort {
  margin-bottom: 2px
}

.payment-history__table {
  width: 100%;
  display: block
}

.payment-history__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.payment-history__row > div {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.payment-history__row > div.date {
  width: 50%
}

.payment-history__row > div.sum {
  width: 50%
}

.payment-history__row > div.title {
  width: 100%
}

.payment-history__row > div.more {
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center
}

.payment-history__row > div.check {
  text-align: right;
}

.payment-history__row:nth-child(even) {
  background: #f5f6fb
}

.payment-history__th {
  color: #5d5d64;
  font-size: 14px;
  line-height: 18px;
  border: none;
  padding: 14px 15px;
  border-bottom: 1px solid rgba(56, 92, 139, .3);
  font-weight: 400
}

.payment-history__td {
  font-size: 14px;
  line-height: 25px;
  padding: 0 0 5px;
  border: none
}

.payment-history__td.more {
  font-weight: 600;
  line-height: 20px
}

.payment-history__td a {
  color: #385C8B
}

.payment-history__td.title p {
  margin-bottom: 0
}

@media (max-width: 1279px) {
  .payment-history {
    margin-bottom: 118px
  }

  .payment-history__row {
    padding: 26px 15px;
    margin-left: -15px;
    margin-right: -15px
  }

  .payment-history__td.sum {
    text-align: right;
    padding-right: 8px
  }

  .payment-history__td.title {
    line-height: 24px;
    margin-bottom: 15px
  }

  .payment-history__td.id {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 8px;
  }

  .payment-history__td.id a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .payment-history__td.date {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }

  .payment-history__row > div.specialist {
    width: 50%;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    font-weight: 700;
  }

  .payment-history__row > div.sum {
    width: 50%;
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }

  .payment-history__row > div.title {
    width: 100%;
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
    margin-bottom: 0;
  }

  .payment-history__row > div.timing {
    width: 100%;
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }

  .payment-history__row > div.check {
    width: 100%;
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
  }

  .payment-history__row > div.check a {
    margin-left: 10px;
  }
}

@media (min-width: 1280px) {
  .payment-history {
    margin-top: 50px;
    margin-bottom: 50px
  }

  .payment-history__row > div.id {
    width: 10%;
  }

  .payment-history__row > div.date {
    width: 13%
  }

  .payment-history__row > div.sum {
    width: 12%
  }

  .payment-history__row > div.specialist {
    width: 15%
  }

  .payment-history__row > div.title {
    width: 22%
  }

  .payment-history__row > div.timing {
    width: 12%
  }

  .payment-history__row > div.check {
    width: 16%
  }

  .payment-history__row > .payment-history__th {
    padding-left: 0;
  }

  .payment-history__row > .payment-history__td {
    padding-right: 15px;
  }
}

@media (min-width: 1600px) {
  .payment-history {
    margin-bottom: 125px;
  }

  .payment-history__td {
    font-size: 16px;
  }
}

.reservation-person {
  margin-bottom: 50px
}

.reservation-person__image {
  margin-bottom: 30px;
  -webkit-box-shadow: 12px 15px 50px rgba(57, 60, 67, .2);
  box-shadow: 12px 15px 50px rgba(57, 60, 67, .2)
}

.reservation-person__image img {
  width: 100%
}

.reservation-person__info {
  background: #f5f6fb;
  padding: 30px 18px 40px;
  color: #252733
}

.reservation-person__subtitle {
  font-size: 18px;
  line-height: 130%;
  letter-spacing: .02em;
  margin-bottom: 5px
}

.reservation-person__title {
  font-weight: 600;
  font-size: 21px;
  line-height: 130%;
  margin-bottom: 22px
}

.reservation-person__rating {
  margin-bottom: 5px
}

.reservation-person__rating .fa {
  color: #fde022;
  margin-right: 7px
}

.reservation-person__reviews {
  font-size: 14px;
  line-height: 130%;
  letter-spacing: .02em
}

.reservation-person__reviews a {
  color: rgba(12, 12, 12, .7);
  text-decoration: underline
}

.reservation-person__reviews a:hover {
  text-decoration: none
}

@media (min-width: 481px) and (max-width: 991px) {
  .reservation-person {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }

  .reservation-person__image {
    margin-bottom: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 30%;
    position: relative;
    overflow: hidden
  }

  .reservation-person__image img {
    position: absolute;
    -o-object-fit: cover;
    object-fit: cover;
    min-height: 100%;
    min-width: 100%
  }

  .reservation-person__info {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
  }
}

@media (max-width: 480px) {
  .reservation-person {
    max-width: 270px;
    margin-left: auto;
    margin-right: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse
  }

  .reservation-person__info {
    padding-top: 10px;
    padding-bottom: 20px
  }

  .reservation-person__subtitle {
    font-size: 14px
  }

  .reservation-person__title {
    font-size: 18px;
    margin-bottom: 10px
  }

  .reservation-person__stat {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }
}

.history-messages h4 {
  margin-bottom: 42px
}

.history-messages__content {
  background: #f5f6fb;
  padding: 36px 35px 44px 20px
}

.history-messages__item {
  position: relative;
  border-top: 1px solid rgba(12, 12, 12, .2);
  margin-top: 32px;
  padding-top: 32px
}

.history-messages__item.first {
  border-top: none;
  margin-top: 0;
  padding-top: 0
}

.history-messages__date {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: rgba(37, 39, 51, .8)
}

.history-messages__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  margin-top: 2px;
  margin-bottom: 14px
}

.history-messages__body {
  font-size: 14px;
  line-height: 140%;
  color: rgba(37, 39, 51, .8)
}

.history-messages__body strong {
  font-weight: 800
}

.history-messages .collapse-more {
  color: #385C8B;
  margin-top: 48px;
  display: inline-block
}

@media (max-width: 991px) {
  .history-messages h4 {
    margin-bottom: 30px
  }

  .history-messages__content {
    margin-left: -15px;
    margin-right: -15px;
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px
  }

  .history-messages__date {
    font-size: 12px
  }

  .history-messages__title {
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px
  }
}

.question-form {
  margin-top: 30px;
  margin-bottom: 32px;
  padding-bottom: 36px;
  border-bottom: 1px solid rgba(12, 12, 12, .2)
}

.question-form .control-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: .02em;
  font-weight: 400;
  padding: 0;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0
}

.question-form .control-label .reset {
  padding: 0;
  border: none;
  background: 0 0;
  text-decoration: none;
  margin-left: 10px;
  display: block;
  color: #b3b3b7;
  cursor: pointer
}

.question-form .control-label .svg-icon {
  width: 10px;
  height: 10px
}

.question-form .textarea-wrap {
  margin: 15px 0 5px
}

@media (max-width: 991px) {
  .question-form .form-group {
    margin-bottom: 30px
  }

  .question-form .form-control {
    font-size: 14px
  }

  .question-form .textarea-wrap {
    margin: 10px 0
  }
}

@media (min-width: 992px) {
  .question-form {
    margin-top: 45px;
    margin-bottom: 72px
  }

  .question-form .control-label {
    font-size: 14px
  }
}

.sort-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 20px;
  padding: 0 2px;
  margin-bottom: 28px
}

.sort-line a {
  margin-right: 30px;
  color: rgba(37, 39, 51, .5)
}

.sort-line a:last-of-type {
  margin-right: 0
}

.sort-line a .arrow {
  display: none;
  overflow: hidden;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  position: relative
}

.sort-line a .arrow .svg-icon {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0;
  left: -5px
}

.sort-line a.desc .svg-icon {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  top: -8px
}

.sort-line a.active {
  color: #252733
}

.sort-line a.active .arrow {
  display: inline-block
}

@import "partials/catalog-item";

.balance {
  margin: 25px 0 55px
}

.balance__value {
  font-weight: 600;
  background: #f5f6fb;
  font-size: 21px;
  line-height: 130%;
  padding: 10px 50px;
  text-align: center
}

@media (max-width: 991px) {
  .balance__btn {
    text-align: center
  }

  .balance__btn .btn-round {
    height: 50px;
    width: 100%;
    max-width: 300px
  }
}

@media (max-width: 479px) {
  .balance__value {
    margin-bottom: 34px
  }
}

@media (min-width: 480px) {
  .balance {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .balance__value {
    margin-right: 24px
  }
}

@media (min-width: 992px) {
  .balance {
    margin-bottom: 75px
  }

  .balance__value {
    margin-right: 45px;
    font-size: 24px;
    padding: 15px 50px;
    min-width: 275px
  }
}

.deals__table {
  width: 100%;
  display: block
}

.deals__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(56, 92, 139, .3);
  position: relative
}

.deals__row:last-of-type {
  border-bottom: none
}

.deals__row > div {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0
}

.deals__row > div.spec {
  width: 100%
}

.deals__row > div.order-type {
  width: 100%
}

.deals__row > div.theme {
  width: 100%
}

.deals__row > div.date {
  width: 100%
}

.deals__row > div.controls {
  width: 100%;
  padding-left: 0;
  padding-right: 0
}

.deals__row > div.controls .btn-round.btn-default {
  width: 100%;
  max-width: 284px
}

.deals__row > div.controls .hint {
  color: #b3b3b7
}

.deals__row > div.controls .hint strong {
  color: #252733
}

.deals__row .collapse-more {
  position: absolute;
  top: 2px;
  right: 15px;
  color: #385C8B;
  font-size: 34px
}

.deals__th {
  color: #5d5d64;
  font-size: 14px;
  line-height: 18px;
  border: none;
  padding: 14px 15px;
  font-weight: 400
}

.deals__th:first-of-type {
  padding-left: 0
}

.deals__td {
  font-size: 14px;
  line-height: 25px;
  padding: 0 0 5px;
  border: none
}

.deals__td:first-of-type {
  padding-left: 0
}

.deals__td.spec a {
  font-weight: 600;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: #385C8B
}

.deals__td a.details {
  font-weight: 600;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: #385C8B
}

.deals__td.theme .title {
  font-weight: 600
}

.deals__label {
  font-size: 12px
}

@media (max-width: 991px) {
  .deals {
    margin-bottom: 118px
  }

  .deals__row {
    padding: 0 0 34px;
    margin-bottom: 30px
  }

  .deals__row > div.spec {
    padding-right: 35%;
    padding-bottom: 20px
  }

  .deals__row > div.order-type {
    padding-bottom: 15px
  }

  .deals__row > div.order-type p {
    margin: 0
  }

  .deals__row > div.theme {
    padding-bottom: 15px
  }

  .deals__row > div.theme p {
    margin-bottom: 5px
  }

  .deals__row > div.date {
    padding-bottom: 15px
  }

  .deals__row > div.date .radio-wraps {
    margin-top: 15px
  }

  .deals__row > div.date .radio-wrap {
    margin-bottom: 0
  }

  .deals__row > div.controls {
    margin-top: -12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end; /*width:calc(100% + 20px)*/
  }

  .deals__row > div.controls .btn {
    margin-top: 32px;
    margin-right: 18px
  }

  .deals__row > div.controls .btn.visible-sm, .deals__row > div.controls .btn.visible-xs {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
  }

  .deals__row > div.controls .btn-round {
    min-width: 190px !important;
    width: auto !important
  }

  .deals__row > div.controls .btn-round.btn-bordered {
    margin-bottom: 0
  }

  .deals__row > div.controls .btn-edit, .deals__row > div.controls .btn-reset {
    margin-top: 10px
  }

  .deals__row > div.controls .hint {
    margin-top: 15px
  }
}

@media (min-width: 992px) {
  .deals {
    margin-top: 25px
  }

  .deals__row > div.spec {
    width: 18.5%
  }

  .deals__row > div.order-type {
    width: 22.5%
  }

  .deals__row > div.theme {
    width: 38%
  }

  .deals__row > div.date {
    width: 21%
  }

  .deals__body .deals__row {
    margin-bottom: 40px
  }

  .deals__td {
    font-size: 16px;
    padding: 17px 15px 22px
  }

  .deals__td.spec {
    padding-left: 0
  }

  .deals__td.spec a {
    font-size: 16px;
    line-height: 25px
  }

  .deals__td a.details {
    font-size: 16px;
    line-height: 25px
  }

  .deals__td.theme .title {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 10px
  }

  .deals__td.controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }

  .deals__td.controls .btn {
    margin-right: 45px;
    margin-bottom: 32px
  }

  .deals__td.controls .btn-round.btn-bordered {
    padding-left: 30px;
    padding-right: 30px
  }

  .deals__td.controls .hint {
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }
}






@media (min-width: 1280px) {
  .deals {
    margin-top: 46px;
    margin-bottom: 50px
  }

  .deals__row > div.spec {
    width: 18.5%
  }

  .deals__row > div.order-type {
    width: 22.5%
  }

  .deals__row > div.theme {
    width: 38%
  }

  .deals__row > div.date {
    width: 21%
  }
}

@media (min-width: 1600px) {
  .deals {
    margin-bottom: 125px
  }
}

.catalog-filter__group {
  border-bottom: 1.5px solid #e4e9ed;
  padding-bottom: 20px;
  margin-bottom: 30px
}

.catalog-filter__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px
}

.catalog-filter__head .group-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: rgba(37, 39, 51, .8);
  position: relative;
  display: inline-block;
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #385C8B 51%, rgba(56, 92, 139, .3) 51%, rgba(56, 92, 139, .3) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(51%, rgba(255, 255, 255, 0)), color-stop(51%, #385C8B), color-stop(51%, rgba(56, 92, 139, .3)), to(rgba(56, 92, 139, .3)));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #385C8B 51%, rgba(56, 92, 139, .3) 51%, rgba(56, 92, 139, .3) 100%)
}

.catalog-filter .blog-search__form input {
  height: 30px
}

.catalog-filter__content .checkbox-wrap, .catalog-filter__content .radio-wrap {
  width: 100%
}

.catalog-filter__content .scroll-pane {
  max-height: 235px
}

.catalog-filter__content .blog-search__group {
  margin-bottom: 30px
}

.catalog-filter__content .collapse-more {
  display: inline-block;
  margin-top: 15px
}

.catalog-filter__content .ui-widget.ui-widget-content {
  border: none;
  background: #b3b3b7;
  height: 2px;
  margin: 32px 0 25px
}

.catalog-filter__content .ui-state-default, .catalog-filter__content .ui-widget-content .ui-state-default {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #385C8B;
  border: none;
  top: -6px
}

.catalog-filter__content .ui-widget-header {
  background: #385C8B;
  height: 2px
}

.catalog-filter__content .ui-slider-labels {
  font-size: 12px;
  line-height: 15px;
  opacity: .8
}

.catalog-filter__content .ui-slider-labels .dash {
  margin: 0 4px
}

.catalog-filter__more {
  padding: 10px 0;
  margin-top: 30px;
  margin-bottom: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #385C8B !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.catalog-filter__more .show-less, .catalog-filter__more .show-more {
  font-size: 36px;
  font-weight: 400;
  line-height: 20px;
  margin-right: 15px;
  margin-left: 10px
}

.catalog-filter .collapse .catalog-filter__group {
  border-bottom: none;
  padding-bottom: 0
}

.catalog-filter .collapse .blog-search__group {
  margin-bottom: 45px
}

.catalog-filter .collapse .catalog-filter__head {
  margin-bottom: 10px
}

.catalog-filter .collapse .radio-wrap label {
  font-size: 14px
}

.page_for-clients::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 150px;
  width: 100%;
  background: #385C8B;
  pointer-events: none
}

.page_for-clients .header::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #385C8B;
  pointer-events: none
}

.page_for-clients .header__cabinet a, .page_for-clients .header__cabinet a .counter, .page_for-clients .header__menu a, .page_for-clients .header__search a {
  color: #fff
}

.page_for-clients .header__burger .icon-bar {
  background: #fff
}

.page_for-clients .page__main {
  padding-bottom: 0
}

@media (max-width: 767px) {
  .page_for-clients::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 49vh;
    width: 100%;
    background: #385C8B;
    pointer-events: none
  }

  .page_for-clients .header__cabinet a {
    color: #fff
  }

  .page_for-clients .header__cabinet a .counter {
    color: #fff
  }

  .page_for-clients .header__burger .icon-bar {
    background: #fff
  }
}

@media (min-width: 1280px) {
  .page_for-clients .page__main > .page__text-bg {
    left: auto;
    right: 4.5%;
    top: 20px;
    z-index: 2
  }
}

.for-clients__top {
  position: relative;
  color: #fff;
  margin-bottom: 130px
}

.for-clients__top p {
  color: #fff
}

.for-clients__top::before {
  content: '';
  display: block;
  position: absolute;
  top: -20px;
  left: 50%;
  bottom: 0;
  margin-left: -100vw;
  width: 200vw;
  background: #385C8B;
  pointer-events: none
}

.for-clients__image {
  -webkit-box-shadow: 35px 35px 50px rgba(37, 39, 51, .2);
  box-shadow: 35px 35px 50px rgba(37, 39, 51, .2);
  position: relative
}

.for-clients__image .owl-carousel_for-clients-top .owl-nav {
  position: absolute;
  bottom: 145px;
  left: 100%;
  margin-left: 95px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.for-clients__image .owl-carousel_for-clients-top .owl-nav > div {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.for-clients__image .owl-carousel_for-clients-top .owl-nav > div .svg-icon {
  width: 118px
}

.for-clients__image .owl-carousel_for-clients-top .owl-nav .owl-prev {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
  margin-right: 40px
}

.for-clients__image-wrap {
  position: relative;
  float: right;
  width: 50vw;
  margin-bottom: -100px
}

.for-clients__image-wrap img {
  width: 100%
}

.for-clients__image-title {
  position: absolute;
  background: #f48f6f;
  bottom: 135px;
  right: 80px;
  padding: 8px 25px;
  min-width: 320px;
  color: #fff
}

.for-clients__image-title span {
  font-weight: 500;
  font-size: 14px;
  line-height: 25px
}

.for-clients__image-title div {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px
}

.for-clients__owl-item {
  position: relative;
  overflow: hidden
}

.for-clients__owl-item::before {
  content: '';
  display: block;
  padding-bottom: 95%
}

.for-clients__owl-item img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0
}

.for-clients__text {
  padding: 100px 60px
}

.for-clients__btn {
  margin-bottom: 100px
}

.for-clients__subtitle {
  text-transform: uppercase;
  font-size: 30px;
  line-height: 130%;
  letter-spacing: .1em;
  margin-top: 3px;
  margin-bottom: 10px;
  margin-left: 10px
}

.for-clients__title {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 72px;
  line-height: 130%;
  letter-spacing: .02em;
  margin-bottom: 45px
}

.for-clients__desc {
  font-size: 30px;
  line-height: 130%;
  max-width: 450px;
  margin-bottom: 70px
}

.for-clients__group {
  position: relative;
  margin-bottom: 100px
}

.for-clients__group .page__text-bg {
  left: -12%;
  top: 0;
  padding-top: 20px
}

.for-clients__group-subtitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: .1em;
  margin-top: 10px;
  margin-bottom: 20px
}

.for-clients__group-title {
  text-transform: uppercase;
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 50px;
  line-height: 130%;
  letter-spacing: .02em;
  color: inherit
}

.for-clients-tasks {
  margin-top: 195px;
  margin-bottom: 140px
}

.for-clients-tasks__desc {
  max-width: 620px
}

.for-clients-tasks__desc p {
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 25px
}

.for-clients-tasks__btn {
  margin-top: 55px
}

.for-clients-tasks .advantages-items {
  margin-bottom: 20px
}

.for-clients-directions .page__text-bg {
  padding-top: 5px
}

.for-clients-directions .for-clients__group-title {
  margin-bottom: 40px
}

.for-clients-directions__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 85px;
  margin-bottom: 135px;
  margin-left: -15px;
  margin-right: -15px
}

.for-clients-directions__item {
  padding: 60px 40px;
  color: #fff;
  max-width: 366px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-right: 15px;
  margin-left: 15px;
  position: relative;
  overflow: hidden
}

.for-clients-directions__item::after {
  content: '';
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  height: 100%;
  background: -o-linear-gradient(right, rgba(255, 255, 255, 0), rgba(255, 255, 255, .8), rgba(255, 255, 255, 0)) no-repeat -25em 0;
  background: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), color-stop(rgba(255, 255, 255, .8)), to(rgba(255, 255, 255, 0))) no-repeat -25em 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, .8), rgba(255, 255, 255, 0)) no-repeat -25em 0;
  background-size: 25em 100%;
  -webkit-transform: skewX(-50deg);
  -ms-transform: skewX(-50deg);
  transform: skewX(-50deg);
  pointer-events: none
}

.for-clients-directions__item:hover::after {
  -webkit-transition: 1.5s linear;
  -o-transition: 1.5s linear;
  transition: 1.5s linear;
  background-position: 530% 0
}

.for-clients-directions__item a {
  color: inherit
}

.for-clients-directions__item a::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

.for-clients-directions__item01 {
  background: #f48f6f
}

.for-clients-directions__item02 {
  background: #385C8B
}

.for-clients-directions__item03 {
  background: #5d8d94
}

.for-clients-directions__icon {
  margin-bottom: 40px
}

.for-clients-directions__icon img {
  height: 82px
}

.for-clients-directions__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 24px;
  line-height: 130%;
  padding: 0 40px 0 5px
}

.for-clients-directions__text .svg-icon {
  width: 40px
}

.for-clients-aims {
  position: relative;
  color: #fff;
  margin-top: 215px;
  margin-bottom: 145px
}

.for-clients-aims::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -100vw;
  width: 200vw;
  height: 100%;
  pointer-events: none;
  background: #385C8B url(../images/blur2.svg) no-repeat 50% 0/contain
}

.for-clients-aims .page__text-bg {
  padding-top: 105px;
  z-index: 1
}

.for-clients-aims .for-clients__group-title {
  margin-top: 35px;
  margin-bottom: 45px;
  color: inherit
}

.for-clients-aims__content {
  position: relative;
  padding-top: 110px;
  padding-bottom: 210px
}

.for-clients-aims .owl-carousel {
  margin-top: 80px
}

.for-clients-aims .owl-carousel-wrap .owl-carousel .owl-dots {
  border-bottom-color: rgba(255, 255, 255, .5)
}

.for-clients-aims .owl-carousel-wrap .owl-carousel .owl-dot.active {
  background-color: #fff
}

.for-clients-aims .owl-carousel-wrap .owl-carousel .owl-item:not(.active) + .owl-item.active .business-aim__content {
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -15px;
  background: #252733;
  -webkit-box-shadow: 10px 10px 30px rgba(57, 60, 67, .2);
  box-shadow: 10px 10px 30px rgba(57, 60, 67, .2)
}

.for-clients-aims .owl-carousel-wrap .owl-carousel .owl-item:not(.active) + .owl-item.active .business-aim__content::after {
  display: none
}

.for-clients-aims .owl-carousel-wrap .owl-carousel .owl-item:not(.active) + .owl-item.active .business-aim__text {
  opacity: .8;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0)
}

.for-clients-aims .owl-carousel-wrap .owl-carousel .owl-item:not(.active) + .owl-item.active .business-aim__title {
  margin-top: 0
}

.for-clients-aims .business-aim {
  margin-bottom: 110px;
  position: relative;
  background-color: #5d8d94
}

.for-clients-aims .business-aim::before {
  content: '';
  display: block;
  padding-bottom: 100%
}

.for-clients-aims .business-aim_odd {
  background-color: #f48f6f
}

.for-clients-aims .business-aim__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 36px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  overflow: hidden
}

.for-clients-aims .business-aim__content::after {
  content: '';
  display: block;
  width: 60px;
  height: 60px;
  opacity: .2;
  position: absolute;
  right: 24px;
  bottom: 24px;
  background-image: url(../images/check.svg);
  background-position: 0 0;
  background-repeat: no-repeat
}

.for-clients-aims .business-aim__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  margin-bottom: 20px;
  margin-top: 20px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  max-width: 260px
}

.for-clients-aims .business-aim__text {
  font-size: 16px;
  line-height: 150%;
  opacity: 0;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px)
}

.for-clients-aims .business-aim.active .business-aim__content {
  top: -15px;
  left: -15px;
  right: -15px;
  bottom: -15px;
  background: #252733;
  -webkit-box-shadow: 10px 10px 30px rgba(57, 60, 67, .2);
  box-shadow: 10px 10px 30px rgba(57, 60, 67, .2)
}

.for-clients-aims .business-aim.active .business-aim__content::after {
  display: none
}

.for-clients-aims .business-aim.active .business-aim__text {
  opacity: .8;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0)
}

.for-clients-aims .business-aim.active .business-aim__title {
  margin-top: 0
}

.for-clients-departments {
  margin-bottom: 165px
}

.for-clients-departments .for-clients__group-title {
  margin-bottom: 40px;
  margin-top: 30px
}

.for-clients-departments .departments-items {
  margin-top: 70px
}

.for-clients-departments .departments-items > div {
  margin-bottom: 45px
}

.for-clients-departments .departments-item {
  background: #f5f6fb;
  padding: 52px 42px;
  font-size: 16px;
  line-height: 130%;
  -webkit-box-shadow: 10px 10px 30px rgba(57, 60, 67, .15);
  box-shadow: 10px 10px 30px rgba(57, 60, 67, .15);
  height: 100%
}

.for-clients-departments .departments-item__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.for-clients-departments .departments-item__title {
  font-weight: 800;
  font-size: 36px;
  line-height: 130%;
  letter-spacing: .02em
}

.for-clients-departments .departments-item__subtitle {
  text-transform: uppercase;
  letter-spacing: .1em;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  margin-bottom: 38px;
  margin-top: 12px;
  margin-left: 5px
}

.for-clients-departments .departments-item__arrow {
  margin-top: 8px;
  margin-right: 12px
}

.for-clients-departments .departments-item__list ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0
}

.for-clients-departments .departments-item__list li {
  margin: 8px 0 12px
}

.for-clients-competitions {
  margin-bottom: 255px
}

.for-clients-competitions__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-top: 70px
}

.for-clients-competitions__item {
  margin-right: 20px;
  border: 1px solid rgba(196, 196, 196, .6);
  border-bottom: 1px solid #385C8B;
  padding: 17px 12px 8px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: rgba(37, 39, 51, .8);
  margin-bottom: 15px;
  position: relative
}

.for-clients-competitions__item::before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: #385C8B;
  -webkit-transition: height .3s;
  -o-transition: height .3s;
  transition: height .3s
}

.for-clients-competitions__item.active {
  color: #fff
}

.for-clients-competitions__item.active::before {
  height: 100%
}

.for-clients-competitions__title {
  display: block;
  max-width: 180px;
  position: relative
}

.for-clients-competitions__more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
  padding: 15px 0 0 15px;
  margin-bottom: 15px
}

.for-clients-competitions__more .svg-icon {
  width: 117px
}

.for-clients-competitions__more:hover .svg-icon {
  fill: #f48f6f
}

.theme-mentor .for-clients-competitions__more:hover .svg-icon {
  fill: #385C8B
}

.theme-psycologist .for-clients-competitions__more:hover .svg-icon {
  fill: #5d8d94
}

.for-clients-competitions__count {
  display: block;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: .02em;
  padding-left: 3px
}

@media (max-width: 991px) {
  .for-clients-competitions {
    padding-top: 30px;
    margin-bottom: 0
  }

  .for-clients-competitions::before {
    content: none
  }

  .for-clients-competitions__list {
    margin-top: 15px
  }
}

@media (min-width: 992px) {
  .for-clients-competitions__item {
    padding: 40px 42px 15px 23px;
    font-size: 16px;
    margin-bottom: 24px
  }

  .for-clients-competitions__more {
    padding-left: 52px
  }
}

.for-clients-order {
  position: relative;
  color: #fff;
  margin-bottom: 0
}

.for-clients-order::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -100vw;
  width: 200vw;
  height: 100%;
  pointer-events: none;
  background: #385C8B url(../images/blur2.svg) no-repeat 50% 0/contain
}

.for-clients-order__content {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px
}

.for-clients-order__content .h3 {
  color: inherit;
  line-height: 1.65;
  margin-bottom: 40px
}

.for-clients-order__content p {
  margin-bottom: 30px
}

.for-clients-order__text {
  max-width: 530px
}

.for-clients-order__btn {
  margin-top: 60px
}

.for-clients-order__image {
  width: 50vw;
  position: absolute;
  bottom: 0
}

.for-clients-order__image img {
  width: 100%;
  margin-left: -15px;
  -webkit-box-shadow: -50px -50px 50px rgba(57, 60, 67, .15);
  box-shadow: -50px -50px 50px rgba(57, 60, 67, .15)
}

@media (max-width: 1700px) {
  .for-clients__group .page__text-bg {
    left: -75px
  }
}

@media (max-width: 1440px) {
  .for-clients__text {
    padding: 50px 30px
  }

  .for-clients__title {
    font-size: 60px
  }

  .for-clients__desc, .for-clients__subtitle {
    font-size: 24px
  }

  .for-clients__image .owl-carousel_for-clients-top .owl-nav {
    bottom: 75px;
    margin-left: 30px
  }

  .for-clients__image-title {
    bottom: 85px;
    right: 40px
  }

  .page_for-clients .page__main > .page__text-bg {
    width: 55px
  }

  .for-clients__top {
    margin-bottom: 50px
  }

  .for-clients-aims {
    margin-top: 100px;
    margin-bottom: 100px
  }

  .for-clients-aims__content {
    padding-top: 100px;
    padding-bottom: 100px
  }

  .for-clients-competitions, .for-clients-departments {
    margin-bottom: 100px
  }

  .for-clients-tasks {
    margin-top: 100px
  }

  .for-clients-aims .business-aim__content {
    padding: 20px;
    font-size: 14px
  }
}

@media (max-width: 1279px) {
  .for-clients-departments .departments-item {
    padding: 30px 20px
  }

  .for-clients-departments .departments-item__title {
    font-size: 30px
  }

  .for-clients__title {
    font-size: 36px;
    margin-bottom: 30px
  }

  .for-clients__desc {
    margin-bottom: 30px
  }

  .for-clients__btn {
    margin-bottom: 30px
  }
}

@media (max-width: 991px) {
  .for-clients__group-subtitle {
    font-size: 21px;
    margin-bottom: 0
  }

  .for-clients__group-title {
    font-size: 24px;
    margin-top: 8px;
    margin-bottom: 20px
  }

  .for-clients__top {
    margin-bottom: 40px
  }

  .for-clients__image-wrap {
    margin-bottom: 0
  }

  .for-clients__image-title {
    position: relative;
    bottom: 0;
    right: 0;
    padding: 4px 28px 25px
  }

  .for-clients__image-title span {
    font-size: 12px
  }

  .for-clients__image-title div {
    font-size: 16px
  }

  .for-clients__text {
    padding: 0
  }

  .for-clients__subtitle {
    font-size: 14px;
    margin-left: 3px;
    margin-top: 14px
  }

  .for-clients__title {
    line-height: 1.2;
    margin-bottom: 25px
  }

  .for-clients__desc {
    font-size: 16px;
    margin-right: 25px;
    margin-bottom: 35px
  }

  .for-clients__btn {
    margin-bottom: 10px
  }

  .for-clients__btn .btn .svg-icon_arrow {
    margin-left: 8px
  }

  .for-clients-tasks {
    margin-top: 0
  }

  .for-clients-tasks__desc p {
    font-size: 14px;
    margin-bottom: 22px
  }

  .for-clients-tasks .advantages-items {
    margin-top: 10px
  }

  .for-clients-tasks .advantages-items .grey-block__content {
    padding-bottom: 32px
  }

  .for-clients-tasks__btn {
    margin-top: 45px
  }

  .for-clients-tasks {
    margin-bottom: 80px
  }

  .for-clients-directions .for-clients__group-title {
    margin-bottom: 20px
  }

  .for-clients-directions__desc p {
    line-height: 1.6
  }

  .for-clients-directions__items {
    margin-top: 25px;
    margin-bottom: 50px
  }

  .for-clients-directions__item {
    padding: 45px 25px 35px;
    margin-bottom: 16px;
    -ms-flex-preferred-size: 33%;
    flex-basis: 33%
  }

  .for-clients-directions__icon {
    margin-bottom: 20px
  }

  .for-clients-directions__icon img {
    height: 60px
  }

  .for-clients-directions__text {
    font-size: 21px;
    padding-right: 10px
  }

  .for-clients__group {
    margin-bottom: 60px
  }

  .for-clients-aims {
    margin-top: 60px;
    margin-bottom: 50px
  }

  .for-clients-aims__content {
    padding-top: 38px;
    padding-bottom: 60px
  }

  .for-clients-aims .for-clients__group-title {
    margin-top: 7px;
    margin-bottom: 35px
  }

  .for-clients-aims .owl-carousel {
    margin-top: 50px
  }

  .for-clients-aims .owl-carousel-wrap .owl-carousel_base .owl-item {
    min-width: 220px
  }

  .for-clients-aims .owl-carousel-wrap .owl-carousel .owl-item:not(.active) + .owl-item.active .business-aim__content {
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px
  }

  .for-clients-aims .business-aim {
    width: 220px;
    margin-bottom: 58px
  }

  .for-clients-aims .business-aim__title {
    font-size: 16px
  }

  .for-clients-aims .business-aim__text {
    font-size: 12px
  }

  .for-clients-departments {
    margin-bottom: 0
  }

  .for-clients-departments .for-clients__group-title {
    margin-top: 10px;
    margin-bottom: 20px
  }

  .for-clients-departments .owl-carousel .owl-stage-outer {
    overflow: visible
  }

  .for-clients-departments .departments-items {
    margin-top: 50px
  }

  .for-clients-departments .departments-items .owl-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 260px;
    margin-left: 10px
  }

  .for-clients-departments .departments-items .owl-nav > div {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
  }

  .for-clients-departments .departments-items .owl-nav > div .svg-icon {
    width: 118px
  }

  .for-clients-departments .departments-items .owl-nav .owl-prev {
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-right: 40px
  }

  .for-clients-departments .departments-item {
    width: 262px
  }

  .for-clients-departments .departments-item__title {
    font-size: 24px
  }

  .for-clients-competitions {
    padding-top: 0
  }

  .for-clients-competitions__list {
    margin-top: 35px
  }

  .for-clients-order {
    margin-bottom: 0
  }

  .for-clients-order__content {
    padding-top: 30px;
    padding-bottom: 0
  }

  .for-clients-order__content .h3 {
    margin-right: 25px;
    line-height: 1.6
  }

  .for-clients-order__content p {
    font-size: 16px;
    line-height: 1.6
  }

  .for-clients-order__btn {
    margin-top: 48px;
    margin-bottom: 78px
  }

  .for-clients-order__btn .btn .svg-icon_arrow {
    margin-left: 10px
  }

  .for-clients-order__image {
    width: auto;
    margin-left: -15px;
    margin-right: -15px;
    position: relative
  }

  .for-clients-order__image img {
    width: 100%;
    margin: 0
  }
}

@media (max-width: 767px) {
  .for-clients__top > .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse
  }

  .for-clients__btn {
    margin-bottom: 20px;
    text-align: center
  }

  .for-clients__desc {
    margin-bottom: 0
  }

  .for-clients__image {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 15px;
    margin-bottom: 15px
  }

  .for-clients__image-wrap {
    width: 100vw;
    max-width: 320px;
    float: none;
    margin: 0 auto
  }

  .for-clients__image-title {
    padding-bottom: 75px;
    padding-left: 18px;
    padding-right: 18px
  }

  .for-clients__owl-item::before {
    padding-bottom: 88%
  }

  .for-clients__image .owl-carousel_for-clients-top .owl-nav {
    left: -10px;
    bottom: 14px
  }
}

@media (max-width: 700px) {
  .for-clients-directions__items {
    display: block
  }

  .for-clients-directions__item {
    max-width: none
  }
}

.about {
  margin-top: 60px;
  margin-bottom: 40px
}

.about__text {
  max-width: 550px
}

.about__text p {
  margin-bottom: 25px
}

.about__text blockquote {
  margin-top: 50px;
  margin-bottom: 75px;
  font-size: 24px;
  padding: 0 0 0 32px;
  line-height: 1.45;
  margin-left: 2px
}

.about .video-preview {
  position: relative;
  margin-bottom: 70px;
  display: inline-block;
  -webkit-box-shadow: 30px 30px 50px rgba(57, 60, 67, .2);
  box-shadow: 30px 30px 50px rgba(57, 60, 67, .2)
}

.about .video-preview img {
  position: relative
}

.about .video-preview::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 78px;
  height: 78px;
  margin: -39px 0 0 -39px;
  border-radius: 50%;
  background: #5d8d94 url(../images/icon-play.svg) no-repeat 50% 50%;
  -webkit-box-shadow: 0 26px 50px rgba(93, 141, 148, .6);
  box-shadow: 0 26px 50px rgba(93, 141, 148, .6)
}

.about .video-preview:hover::after {
  background-color: #537E85
}

.about .video-preview:active::after {
  background-color: #4A7177
}

@media (max-width: 767px) {
  .about {
    margin-top: -5px
  }

  .about__text {
    margin-bottom: 40px
  }

  .about__text p {
    margin-bottom: 20px
  }

  .about__text blockquote {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
    margin-left: 0;
    padding-left: 20px
  }

  .about .video-preview::after {
    width: 36px;
    height: 36px;
    margin: -18px 0 0 -18px;
    background-size: 10px
  }
}

@media (min-width: 1600px) {
  .about {
    margin-bottom: 130px
  }
}

.about-teaser {
  margin: 75px 0
}

.about-teaser__item {
  margin-bottom: 50px;
  -webkit-box-shadow: 30px 30px 50px rgba(57, 60, 67, .2);
  box-shadow: 30px 30px 50px rgba(57, 60, 67, .2);
  color: #fff;
  position: relative;
  max-width: 340px
}

.about-teaser__item:before {
  content: '';
  display: block;
  padding-bottom: 100%
}

.about-teaser__item:hover .about-teaser__letter {
  width: 50%;
  height: 50%
}

.about-teaser__item:hover .about-teaser__media {
  opacity: 1
}

.about-teaser__item:hover .about-teaser__content {
  opacity: 1
}

.about-teaser__item_01 {
  background: #f48f6f
}

.about-teaser__item_01 .about-teaser__media::before {
  background: -o-radial-gradient(50% 50%, 50% 50%, #f0cdb7 0, #f48f6f 100%);
  background: radial-gradient(50% 50% at 50% 50%, #f0cdb7 0, #f48f6f 100%)
}

.about-teaser__item_02 {
  background: #385C8B
}

.about-teaser__item_02 .about-teaser__media::before {
  background: -o-radial-gradient(50% 50%, 50% 50%, rgba(255, 255, 255, .1) 0, #385C8B 100%);
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, .1) 0, #385C8B 100%)
}

.about-teaser__item_03 {
  background: #5d8d94
}

.about-teaser__item_03 .about-teaser__media::before {
  background: -o-radial-gradient(50% 50%, 50% 50%, rgba(255, 255, 255, .1) 0, #5d8d94 100%);
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, .1) 0, #5d8d94 100%)
}

.about-teaser__letter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 50px;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s
}

.about-teaser__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0
}

.about-teaser__media::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .7;
  pointer-events: none
}

.about-teaser__media img {
  position: relative;
  opacity: .9
}

.about-teaser__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s
}

.about-teaser__content h4 {
  color: #fff;
  margin-top: 20px;
  margin-bottom: 30px
}

.about-teaser__content p {
  color: #fff
}

.about-teaser__name {
  position: absolute;
  bottom: 20px;
  right: 30px;
  text-align: right;
}

.about-teaser__name i {
  font-size: .9em;
}

.about-teaser__name strong {
  font-size: 1.2em;
  opacity: 1;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
}

.about-teaser__item:hover .about-teaser__name {
  opacity: 0;
}

@media (max-width: 767px) {
  .about-teaser {
    margin: 15px 0 55px
  }

  .about-teaser__content h4 {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 15px
  }

  .about-teaser__item {
    margin-bottom: 25px
  }
}

@media (min-width: 1600px) {
  .about-teaser__item_01 {
    margin-left: -35px
  }

  .about-teaser__item_02 {
    margin-left: 35px
  }

  .about-teaser__item_03 {
    margin-left: 100px
  }
}

.about-how {
  position: relative;
  margin-bottom: 170px
}

.about-how::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 200vw;
  height: 100%;
  margin-left: -100vw;
  background-color: #385C8B;
  pointer-events: none
}

.about-how__content {
  position: relative;
  padding: 125px 15px
}

.about-how__content::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 200vw;
  height: 100%;
  margin-left: -100vw;
  background: url(../images/blur2.svg) no-repeat 50% 50%;
  pointer-events: none
}

.about-how__content > * {
  color: #fff
}

.about-how__content h3 {
  margin-bottom: 40px
}

.about-how__content p {
  margin-bottom: 30px
}

.about-how__steps {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 55px 0 95px;
  margin-top: 150px;
  margin-bottom: 35px
}

.about-how__steps::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 200vw;
  height: 100%;
  margin-left: -100vw;
  background: url(../images/aboutHowSteps.svg) no-repeat 50% 0;
  background-size: auto 100%
}

.about-how__step {
  width: 50%;
  position: relative
}

.about-how__step:hover {
  z-index: 10
}

.about-how__step:hover .about-how__step-popup {
  opacity: 1
}

.about-how__step:hover .about-how__step-icon {
  -webkit-box-shadow: 0 18px 50px rgba(0, 0, 0, .5);
  box-shadow: 0 18px 50px rgba(0, 0, 0, .5)
}

.about-how__step_02 .about-how__step-icon, .about-how__step_04 .about-how__step-icon {
  background: #fff;
  color: #f48f6f;
  -webkit-box-shadow: 0 18px 50px rgba(255, 255, 255, .6);
  box-shadow: 0 18px 50px rgba(255, 255, 255, .6)
}

.about-how__step_02 .about-how__step-icon::before, .about-how__step_04 .about-how__step-icon::before {
  border-color: #fff
}

.about-how__step_05 .about-how__step-popup {
  left: auto;
  right: 50%;
  padding-left: 20px;
  padding-right: 45px
}

.about-how__step-icon {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #f48f6f;
  color: #fff;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 50px;
  z-index: 1;
  -webkit-box-shadow: 0 18px 50px rgba(243, 112, 32, .6);
  box-shadow: 0 18px 50px rgba(243, 112, 32, .6);
}

.about-how__step-icon::before {
  content: '';
  display: block;
  position: absolute;
  top: -8px;
  bottom: -8px;
  left: -8px;
  right: -8px;
  border-radius: 50%;
  border: 1px solid #f48f6f
}

.about-how__step-num {
  position: absolute;
  top: 60px;
  left: 50%;
  width: 100px;
  margin-left: -50px;
  text-align: center;
  font-size: 64px;
  line-height: 150%;
  opacity: .5;
  letter-spacing: .1em
}

.about-how__step-popup {
  position: absolute;
  opacity: 0;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  left: 50%;
  top: 50%;
  background: #fff;
  padding: 35px 20px 50px 45px;
  color: #252733;
  width: 220px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}

@media (max-width: 767px) {
  .about-how {
    margin-bottom: 70px
  }

  .about-how__content {
    padding-top: 60px;
    padding-right: 0;
    padding-bottom: 5px
  }

  .about-how__content h3 {
    margin-bottom: 32px
  }

  .about-how__steps {
    margin-top: 58px;
    padding-bottom: 15px;
    margin-bottom: 0
  }

  .about-how__steps::before {
    content: none
  }

  .about-how__step {
    margin-bottom: 64px;
    margin-right: calc(50% - 57px);
    width: auto
  }

  .about-how__step_02 .about-how__step-popup, .about-how__step_04 .about-how__step-popup {
    padding-left: 20px;
    padding-right: 45px;
    top: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
  }

  .about-how__step_01 .about-how__step-popup, .about-how__step_03 .about-how__step-popup, .about-how__step_05 .about-how__step-popup {
    left: 36px;
    right: auto;
    padding-left: 45px;
    padding-right: 20px
  }

  .about-how__step-icon {
    width: 45px;
    height: 45px;
    margin-left: 12px
  }

  .about-how__step-num {
    top: 3px;
    margin: 10px;
    font-size: 34px
  }
}


.contacts {
  color: #fff;
  background: #385C8B url(../images/contacts_bg.png) no-repeat 0 0/cover;
  margin-top: 30px;
  margin-bottom: 45px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 55px 35px 10px;
  border-radius: 25px;
}

.contacts p {
  color: inherit
}

.contacts a {
  color: #fff
}

.contacts__item {
  margin-bottom: 42px
}

.contacts__label {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: .1em;
  margin-bottom: 15px
}

.contacts__value {
  font-size: 18px;
  line-height: 130%;
  letter-spacing: .02em;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.contacts__value_phone {
  font-weight: 800
}

.contacts__value_social {
  font-size: 24px
}

.contacts__value_social a {
  margin-right: 80px
}

.contacts__value_download a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px
}

.contacts__value_download .svg-icon {
  width: 27px;
  height: 33px;
  margin-right: 20px
}

@media (min-width: 768px) {
  .contacts {
    padding-left: 40px;
    padding-right: 40px;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 94px
  }

  .contacts__item {
    margin-bottom: 50px
  }

  .contacts__label {
    font-size: 14px
  }

  .contacts__value {
    font-size: 24px
  }

  .modal-body .contacts__value {
    font-size: 18px
  }
}

@media (min-width: 992px) {
  .contacts {
    margin-top: 66px;
    margin-bottom: 114px;
    padding-top: 100px;
    padding-right: 60px;
    padding-left: 60px;
    padding-bottom: 60px
  }
}

.vacancies {
  margin-top: 32px;
  margin-bottom: 14px
}

.vacancies .pagination {
  margin: 0 0 87px
}

@media (min-width: 992px) {
  .vacancies {
    margin-top: 76px;
    margin-bottom: 10px
  }

  .vacancies .pagination {
    margin-bottom: 7px
  }
}

.vacancy {
  margin-bottom: 65px;
  position: relative
}

.vacancy__title {
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  margin-bottom: 18px;
  color: #151528
}

.vacancy__location {
  font-size: 14px;
  line-height: 150%;
  opacity: .8;
  margin-bottom: 22px
}

.vacancy__desc {
  margin-bottom: 25px
}

.vacancy__desc p {
  line-height: 145%
}

@media (min-width: 992px) {
  .vacancy {
    margin-bottom: 110px
  }

  .vacancy__title {
    font-size: 24px;
    line-height: 29px
  }

  .vacancy__location {
    font-size: 16px
  }

  .vacancy__desc {
    padding-right: 50px;
    margin-bottom: 30px
  }

  .vacancy__desc p {
    line-height: 150%
  }
}

.reviews {
  margin-top: 36px
}

.reviews .pagination {
  margin: 0 0 87px
}

@media (min-width: 768px) {
  .reviews {
    margin-top: 82px
  }

  .reviews .col-xs-12:nth-of-type(2n) .review {
    margin-top: 60px
  }

  .reviews .pagination {
    margin-top: 68px;
    margin-left: 15px;
    margin-bottom: 120px
  }
}

.review {
  margin-bottom: 45px;
  padding-right: 5%
}

.review__image {
  position: relative;
  display: inline-block;
  margin-bottom: 28px;
  width: 104px
}

.review__image::before {
  content: '';
  display: block;
  background: rgba(0, 0, 0, .25);
  opacity: .4;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100%;
  height: 100%
}

.review__image-wrap {
  overflow: hidden;
  border-radius: 45px;
}

.review__subtitle {
  font-size: 14px;
  line-height: 200%;
  color: #1e1e1e;
  opacity: .5
}

.review__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  color: #000;
  margin-bottom: 25px
}

.review__title span {
  display: inline-block;
  position: relative
}

.review__title span::before {
  background: rgba(56, 92, 139, .3);
  content: '';
  display: block;
  position: absolute;
  top: 60%;
  left: 0;
  width: 100%;
  height: 45%;
  z-index: -1
}

@media (min-width: 768px) {
  .review {
    padding-right: 10%;
    margin-bottom: 32px
  }

  .review__image {
    width: auto;
    margin-bottom: 33px
  }

  .review__subtitle {
    font-size: 16px
  }

  .review__title {
    font-size: 21px;
    margin-bottom: 28px
  }

  .review__title span::before {
    top: 70%
  }
}

.partners {
  margin-top: 34px
}

.partners .pagination {
  margin: -10px 0 7px
}

@media (min-width: 768px) {
  .partners {
    margin-top: 68px
  }
}

.partner {
  margin-bottom: 68px
}

.partner__title {
  font-weight: 600;
  font-size: 21px;
  line-height: 115%;
  margin-bottom: 10px
}

.partner__subtitle {
  font-size: 12px;
  line-height: 15px;
  color: rgba(21, 21, 40, .5);
  margin-bottom: 18px
}

.partner__image {
  margin-bottom: 32px
}

.partner__image img {
  height: 100px
}

.partner__desc {
  margin-bottom: 32px;
  line-height: 140%
}

.partner__link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.partner__link .svg-icon {
  width: 22px;
  height: 22px;
  fill: #385C8B;
  margin-right: 15px
}

@media (min-width: 768px) {
  .partner {
    padding-right: 9%;
    margin-bottom: 110px
  }

  .partner__title {
    font-size: 24px
  }

  .partner__subtitle {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 25px
  }

  .partner__desc {
    margin-bottom: 22px
  }
}

.rents {
  margin-top: 34px
}

.rents .pagination {
  margin: -10px 0 7px
}

@media (min-width: 768px) {
  .rents {
    margin-top: 68px
  }
}

.rent {
  margin-bottom: 68px
}

.rent__title {
  font-weight: 600;
  font-size: 21px;
  line-height: 115%;
  margin-bottom: 10px
}

.rent__subtitle {
  font-size: 12px;
  line-height: 15px;
  color: rgba(21, 21, 40, .5);
  margin-bottom: 18px
}

.rent__image {
  margin-bottom: 32px
}

.rent__image img {
  border-radius: 25px;
}

.rent__desc {
  margin-bottom: 32px;
  line-height: 140%
}

.rent__link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.rent__link .svg-icon {
  width: 22px;
  height: 22px;
  fill: #385C8B;
  margin-right: 15px
}

@media (min-width: 768px) {
  .rent {
    padding-right: 9%;
    margin-bottom: 110px
  }

  .rent__title {
    font-size: 24px
  }

  .rent__subtitle {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 25px
  }

  .rent__desc {
    margin-bottom: 22px
  }
}

.advantages {
  margin-top: 95px;
  margin-bottom: 20px
}

.advantages__info {
  padding: 20px
}

.advantages__subtitle {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: .1em
}

.advantages__info_accent .advantages__subtitle {
  color: #f48f6f
}

.advantages__info_accent-blue .advantages__subtitle {
  color: #385C8B
}

.advantages__title {
  margin-bottom: 50px
}

.advantages__desc p {
  margin-bottom: 25px
}

.advantages__btn {
  margin-top: 56px
}

@media (max-width: 767px) {
  .advantages {
    margin-top: 15px
  }

  .advantages__row_reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse
  }

  .advantages__info {
    padding-left: 0;
    padding-right: 0
  }

  .advantages__subtitle {
    font-size: 14px
  }

  .advantages__title {
    margin-bottom: 15px
  }

  .advantages__desc {
    padding-left: 5px
  }

  .advantages__desc p {
    margin-bottom: 20px
  }

  .advantages__btn {
    margin-top: 32px;
    margin-bottom: 46px
  }
}

@media (min-width: 768px) {
  .advantages .row .col-sm-6:first-of-type .advantages__info {
    padding-left: 0
  }
}

@media (min-width: 992px) {
  .advantages__info {
    padding: 28px 30px
  }
}

.advantages-items {
  margin-bottom: 10px
}

.advantages-items .grey-block__content {
  padding: 30px 15px 45px
}

@media (max-width: 767px) {
  .advantages-items .grey-block__content {
    margin-left: -15px;
    margin-right: -15px
  }
}

@media (min-width: 768px) {
  .advantages-items {
    margin-bottom: 40px
  }

  .advantages-items .grey-block__content {
    padding: 30px 28px 40px
  }
}

@media (min-width: 992px) {
  .advantages-items.grey-block_left {
    margin-right: 40px;
    margin-left: -5px
  }

  .advantages-items.grey-block_right {
    margin-left: 25px;
    margin-top: 25px
  }
}

@media (min-width: 1600px) {
  .advantages-items {
    margin-bottom: 160px
  }

  .advantages-items .grey-block__content {
    padding-top: 58px;
    padding-bottom: 88px
  }
}

.advantages-item {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0
}

.advantages-item .count {
  margin-bottom: 16px
}

@media (max-width: 767px) {
  .advantages-item .count {
    font-size: 24px;
    margin-bottom: 8px
  }
}

@media (min-width: 768px) {
  .advantages-item {
    margin-top: 30px;
    margin-bottom: 25px;
    padding: 0 15px
  }
}

@media (min-width: 1600px) {
  .advantages-item {
    padding: 0 25px
  }
}

.pretext_search {
  margin-top: 28px;
  margin-bottom: 36px
}

@media (min-width: 768px) {
  .pretext_search {
    margin-top: 43px;
    margin-bottom: 40px
  }
}

.feedback_spec-search textarea.form-control {
  margin-bottom: 50px
}

.feedback_spec-search .select2 {
  margin-top: 20px;
  font-weight: 600
}

.feedback_spec-search .checkboxes {
  margin-top: 30px
}

@media (max-width: 991px) {
  .feedback_spec-search .control-label {
    margin-top: 0
  }

  .feedback_spec-search .checkboxes {
    margin-right: -45px
  }
}

@media (min-width: 992px) {
  .feedback_spec-search .feedback__content {
    padding-bottom: 20px
  }

  .feedback_spec-search .col-sm-6 .form-group .checkboxes {
    margin-right: -15%
  }

  .feedback_spec-search .form-group {
    margin-bottom: 45px
  }
}

.faq {
  color: #fff;
  background: #252733;
  margin-top: 25px;
  margin-bottom: 45px;
  padding: 50px 35px 50px;
  border-radius: 25px;
}

.faq p {
  color: inherit
}

.faq a {
  color: #fff
}

.faq__aside h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  margin-bottom: 20px;
  color: #fff
}

.faq__aside ul {
  list-style: none;
  margin: 0;
  padding-left: 0
}

.faq__aside ul li {
  margin-bottom: 10px;
  opacity: .7
}

.faq__aside ul li.active {
  opacity: 1
}

@media (max-width: 991px) {
  .faq {
    margin-right: -15px;
    margin-left: -15px;
    padding: 32px 20px
  }

  .faq__aside {
    margin-bottom: 32px
  }

  .faq__aside ul {
    font-size: 16px;
    font-weight: 600
  }
}

@media (min-width: 992px) {
  .faq {
    margin-top: 65px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }

  .faq__aside {
    width: 24%;
    padding-right: 30px
  }

  .faq__aside h3 {
    font-size: 24px;
    margin-bottom: 35px
  }

  .faq__content {
    width: 76%
  }
}

.faq-item {
  border-bottom: 1px solid rgba(255, 255, 255, .2);
  padding-top: 15px;
  padding-bottom: 25px;
  margin-bottom: 10px
}

.faq-item:last-of-type {
  border-bottom: none
}

.faq-item__title {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  position: relative;
  padding-left: 40px;
  padding-right: 25%;
  cursor: pointer
}

.faq-item__title .fa {
  position: absolute;
  top: 8px;
  left: 8px;
  height: 10px;
  line-height: 10px;
  font-size: 24px;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s
}

.faq-item__title[aria-expanded=true] .fa {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg)
}

.faq-item__body {
  opacity: .8;
  font-size: 16px;
  line-height: 25px;
  padding-left: 40px;
  padding-right: 18%
}

.faq-item__body::before {
  content: '';
  display: block;
  height: 15px
}

.faq-item__body::after {
  content: '';
  display: block;
  clear: both
}

.faq-item__body p:last-of-type {
  margin-bottom: 0
}

@media (max-width: 991px) {
  .faq-item {
    border-bottom: none;
    padding-bottom: 0
  }

  .faq-item__title {
    padding-left: 30px;
    padding-right: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px
  }

  .faq-item__title .fa {
    top: 5px;
    left: 0;
    font-size: 20px
  }

  .faq-item__body {
    padding-left: 30px;
    padding-right: 0;
    font-size: 14px;
    line-height: 20px
  }
}

.page_event::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #385C8B;
  pointer-events: none
}

.page_event .header::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #385C8B;
  pointer-events: none
}

.page_event .header__cabinet a, .page_event .header__cabinet a .counter, .page_event .header__menu a, .page_event .header__search a {
  color: #fff
}

.page_event .header__burger .icon-bar {
  background: #fff
}

.page_event .page__main {
  padding-bottom: 0
}

@media (max-width: 767px) {
  .page_event::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 49vh;
    width: 100%;
    background: #385C8B;
    pointer-events: none
  }

  .page_event .header__cabinet a {
    color: #fff
  }

  .page_event .header__cabinet a .counter {
    color: #fff
  }

  .page_event .header__burger .icon-bar {
    background: #fff
  }
}

.event .page__text-bg {
  top: 10px;
  left: -75px
}

.event__title {
  text-transform: uppercase;
  letter-spacing: .02em;
  font-size: 30px;
  line-height: 130%;
  margin-bottom: 44px;
  margin-top: 0
}

.event__anons {
  position: relative;
  color: #fff;
  margin-bottom: 130px
}

.event__anons p {
  color: #fff
}

.event__anons::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -100vw;
  width: 200vw;
  height: 100%;
  background: #385C8B;
  pointer-events: none
}

.event__image {
  -webkit-box-shadow: 35px 35px 50px rgba(37, 39, 51, .2);
  box-shadow: 35px 35px 50px rgba(37, 39, 51, .2);
  position: relative
}

.event__image-wrap {
  position: relative;
  right: 0;
  width: 50vw
}

.event__date {
  letter-spacing: .02em;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  margin-top: 20px;
  margin-bottom: 6px
}

.event__competention {
  font-size: 14px;
  line-height: 130%
}

.event__main-title {
  letter-spacing: .02em;
  font-weight: 800;
  font-size: 32px;
  line-height: 130%;
  margin-bottom: 36px
}

.event__main-title::before {
  content: '';
  display: block;
  width: 62px;
  height: 5px;
  background: #fff;
  margin: 34px 0 42px
}

.event__desc {
  padding-right: 8.5%;
  margin-bottom: 60px
}

.event__desc p {
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 48px
}

.event__desc ul {
  padding-left: 30px;
  padding-right: 30px
}

.event__desc ul li {
  list-style: none;
  position: relative;
  margin-bottom: 24px
}

.event__desc ul li::before {
  content: '';
  display: block;
  background: #fff;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 5px;
  left: -30px
}

.event__btn {
  margin-bottom: 100px
}

.event__btn .btn span {
  color: #fff
}

.event__btn .btn .svg-icon {
  fill: #fff
}

.event__about {
  position: relative;
  margin-bottom: 160px
}

.event__about::before {
  content: '';
  display: block;
  position: absolute;
  background: url(../images/dance-line.svg) no-repeat 0 50%;
  background-size: contain;
  top: 0;
  left: 50%;
  margin-left: -100vw;
  width: 200vw;
  height: 100%;
  z-index: -1
}

.event__about p {
  margin-bottom: 34px
}

.event__features {
  background: #f5f6fb
}

.event__feature {
  padding: 15px;
  border-bottom: 1px solid rgba(12, 12, 12, .2)
}

.event__feature .h4 {
  color: #385C8B;
  font-weight: 700;
  margin-top: 0
}

.event__feature p {
  margin-bottom: 0
}

.event__location {
  position: relative;
  min-height: 590px
}

.event__location::before {
  content: '';
  display: block;
  position: absolute;
  background: #385C8B;
  top: 0;
  left: 66%;
  width: 50vw;
  height: 100%;
  z-index: -1
}

.event__location p {
  opacity: .9
}

.event__location .p2 {
  font-weight: 500
}

.event__map {
  width: 100%;
  height: 600px;
  margin-top: 40px
}

@media (max-width: 767px) {
  .event__anons {
    margin-bottom: 68px
  }

  .event__image {
    margin-top: -20px;
    margin-left: auto;
    margin-right: auto;
    width: 230px
  }

  .event__text {
    padding-left: 6px;
    padding-right: 6px;
    margin-top: 27px
  }

  .event__main-title {
    font-size: 24px;
    margin-bottom: 32px
  }

  .event__main-title::before {
    margin-top: 19px;
    margin-bottom: 18px
  }

  .event__desc {
    padding-right: 0;
    margin-bottom: 42px
  }

  .event__desc p {
    font-size: 16px
  }

  .event__desc ul {
    padding-right: 0
  }

  .event__desc ul li {
    margin-bottom: 20px
  }

  .event__btn {
    margin-bottom: 62px
  }

  .event__title {
    font-size: 21px;
    margin-bottom: 30px
  }

  .event__about {
    margin-bottom: 62px
  }

  .event__about::before {
    content: none
  }

  .event__about p {
    margin-bottom: 10px
  }

  .event__features {
    margin-top: 40px
  }

  .event__features .col-xs-12:last-of-type .event__feature {
    border-bottom: none;
    padding-bottom: 48px
  }

  .event__feature {
    padding: 27px
  }

  .event__feature p {
    margin-bottom: 0
  }

  .event__location {
    min-height: 0
  }

  .event__location::before {
    margin-bottom: 22px;
    font-size: 14px
  }

  .event__map-wrap {
    margin-left: -15px;
    margin-right: -15px
  }

  .event__map {
    margin-top: 30px;
    height: 260px
  }
}

@media (min-width: 768px) and (max-width: 1599px) {
  .event__text {
    padding-left: 0
  }

  .event__main-title {
    font-size: 30px;
    margin-bottom: 20px
  }

  .event__main-title::before {
    margin: 15px 0 20px
  }

  .event__desc {
    margin-bottom: 25px
  }

  .event__desc p {
    font-size: 16px;
    margin-bottom: 25px
  }

  .event__desc ul li {
    margin-bottom: 12px
  }

  .event__image {
    margin-bottom: 30px;
    margin-right: 25px
  }

  .event__btn {
    margin-bottom: 30px
  }
}

@media (min-width: 768px) {
  .event__features {
    position: relative;
    margin-left: 0;
    margin-right: 0
  }

  .event__features::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50px;
    right: 50px;
    border-bottom: 1px solid rgba(12, 12, 12, .2)
  }

  .event__features::after {
    content: '';
    display: block;
    position: absolute;
    top: 50px;
    bottom: 50px;
    left: 50%;
    border-right: 1px solid rgba(12, 12, 12, .2)
  }

  .event__feature {
    border-bottom: none;
    padding: 30px 20px 30px 30px
  }
}

@media (min-width: 1280px) {
  .event__feature {
    padding: 45px 30px 45px 45px
  }
}

@media (min-width: 1600px) {
  .event .event__anons::before {
    height: 90%
  }

  .event__text {
    padding-left: 8.5%
  }

  .event__main-title {
    font-size: 42px
  }
}


.owl-carousel_events {
  width: 100vw
}

.owl-carousel_events .owl-stage-outer {
  overflow: visible
}

.owl-carousel_events .owl-nav {
  position: absolute;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.owl-carousel_events .owl-nav .svg-icon {
  width: 118px;
  height: 10px
}

.owl-carousel_events .owl-nav > div {
  position: relative
}

.owl-carousel_events .owl-nav > div::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 30px;
  width: 100%;
  margin-top: -15px
}

.owl-carousel_events .owl-prev {
  margin-right: 43px
}

.owl-carousel_events .owl-prev .svg-icon {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1)
}

/*.owl-carousel_calendar{width:100%}*/
.owl-carousel_calendar .owl-stage-outer {
  overflow: visible
}

.owl-carousel_calendar .owl-nav {
  position: absolute;
  top: -120px;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.owl-carousel_calendar .owl-nav .svg-icon {
  width: 118px;
  height: 10px
}

.owl-carousel_calendar .owl-nav > div {
  position: relative
}

.owl-carousel_calendar .owl-nav > div::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 30px;
  width: 100%;
  margin-top: -15px
}

.owl-carousel_calendar .owl-prev {
  margin-right: 43px
}

.owl-carousel_calendar .owl-prev .svg-icon {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1)
}

.events-popular {
  position: relative;
  width: 100vw;
  overflow: hidden;
  padding-left: 20px;
  margin-left: -20px;
  margin-bottom: 148px
}

.events-popular h3 {
  margin-bottom: 50px
}

.events-popular .owl-dots {
  position: absolute;
  bottom: 10px;
  width: 670px;
  left: 300px;
  border-bottom: 1px solid rgba(37, 39, 51, .5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.events-popular .owl-dots .active {
  background: #385C8B
}

@media (min-width: 1280px) {
  .events-popular .owl-dots {
    width: 870px
  }
}

.events-popular .owl-dot {
  height: 3px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  top: 2px
}

@media (max-width: 1279px) {
  .events-popular .owl-dots {
    display: none
  }
}

@media (max-width: 991px) {
  .events-popular {
    margin-bottom: 60px
  }
}

@media (max-width: 767px) {
  .events-popular {
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 60px
  }

  .events-popular h3 {
    margin-top: 0
  }
}

.events-list .page__content h3 {
  margin-bottom: 65px
}

.events-list .page__aside {
  padding-top: 20px
}

.events-list .catalog-filter__group {
  margin-bottom: 40px
}

.events-list .catalog-filter__group:last-of-type {
  border-bottom: none
}

@media (max-width: 991px) {
  .events-list {
    margin-bottom: 80px
  }

  .events-list .page__aside {
    padding-top: 0
  }

  .events-list .page__content h3 {
    margin-bottom: 35px
  }

  .events-list .pagination {
    margin-top: 0
  }
}

@import "./partials/calendar.scss";


.blog-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

@media (max-width: 767px) {
  .blog-head .page__title {
    margin-top: 0;
    line-height: 1.3
  }
}

.blog-sort {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 20px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-bottom: 50px
}

.blog-sort__item {
  opacity: .5;
  margin-left: 36px
}

.blog-sort__item.active {
  opacity: 1
}

.blog-menu {
  margin-top: 10px;
  margin-bottom: 75px
}

.blog-menu h4 {
  letter-spacing: 0;
  margin-bottom: 36px
}

.blog-menu ul {
  list-style: none;
  margin: 0 0 40px;
  padding: 0
}

.blog-menu ul li {
  margin-bottom: 15px
}

.blog-menu ul a:hover {
  opacity: .5
}

.blog-menu ul a.active {
  font-weight: 600
}

@media (max-width: 991px) {
  .blog-menu {
    margin: -5px 5px 25px
  }
}

@media (max-width: 991px) {
  .page__aside h4 {
    font-size: 14px;
    color: #385C8B;
    margin: 0 0 25px
  }

  .page__aside .checkbox-wrap {
    margin-right: 0;
    display: block
  }

  .page__aside .blog-search__form {
    max-width: 100%
  }
}

@media (min-width: 992px) {
  .page__aside #blogCompetention, .page__aside #blogFilter {
    display: block !important;
    height: auto;
    visibility: visible
  }
}

.blog-search {
  margin-top: 10px;
  margin-bottom: 75px
}

.blog-search__group {
  margin-bottom: 65px
}

.blog-search__group .group-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: rgba(37, 39, 51, .8);
  display: inline-block;
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #385C8B 51%, rgba(56, 92, 139, .3) 51%, rgba(56, 92, 139, .3) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(51%, rgba(255, 255, 255, 0)), color-stop(51%, #385C8B), color-stop(51%, rgba(56, 92, 139, .3)), to(rgba(56, 92, 139, .3)));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #385C8B 51%, rgba(56, 92, 139, .3) 51%, rgba(56, 92, 139, .3) 100%);
  margin-bottom: 25px
}

.blog-search__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 235px;
  border-bottom: 1px solid rgba(37, 39, 51, .5)
}

#blogFilter .blog-search__form {
  border-bottom: none;
}

.blog-search__form input {
  font-size: 14px;
  line-height: 17px;
  border: none;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1
}

.blog-search__form button {
  background: 0 0 !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding: 0 5px;
  color: #b3b3b7
}

.blog-search__form .svg-icon {
  width: 13px;
  height: 13px
}

.blog-search .collapse-more {
  margin-top: 10px;
  display: inline-block
}

.bloggers-top {
  max-width: 260px
}

.bloggers-top h5 {
  color: rgba(37, 39, 51, .8);
  letter-spacing: 0;
  margin-bottom: 28px
}

@media (max-width: 991px) {
  .bloggers-top {
    max-width: none;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px
  }

  .bloggers-top h5 {
    font-size: 21px;
    margin-top: -6px
  }
}

.bloggers-list__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 28px;
  position: relative
}

.bloggers-list__photo {
  width: 32%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 15px
}

.bloggers-list__info {
  padding-right: 15px
}

.bloggers-list__name {
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  color: #151528;
  margin-bottom: 10px
}

.bloggers-list__stat {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: .02em;
  color: rgba(37, 39, 51, .8)
}

.bloggers-list__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0
}

@media (max-width: 991px) {
  .bloggers-list__item {
    width: 260px
  }

  .bloggers-list .owl-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 5px;
    margin-bottom: 45px
  }

  .bloggers-list .owl-nav .svg-icon {
    width: 118px;
    height: 10px
  }

  .bloggers-list .owl-nav > div {
    position: relative
  }

  .bloggers-list .owl-nav > div::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    height: 30px;
    width: 100%;
    margin-top: -15px
  }

  .bloggers-list .owl-prev {
    margin-right: 43px
  }

  .bloggers-list .owl-prev .svg-icon {
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1)
  }
}

.blog {
  margin-top: 15px
}

.blog .pagination {
  margin-top: 70px
}

@media (max-width: 991px) {
  .blog .pagination {
    margin-bottom: 70px
  }

  .blog__sort-wrap {
    margin-bottom: 38px
  }
}

@import "partials/blog-item.scss";
@import "partials/blog-articles";


.catalog {
  margin-bottom: 80px
}

.catalog__sort {
  margin-bottom: 30px
}

@media (min-width: 992px) {
  .catalog__sort {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
  }
}

.catalog-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

.page_cabinet .page__title {
  font-size: 21px
}

@media (min-width: 992px) {
  .page_cabinet .page__title {
    font-size: 36px;
    margin-top: 25px;
    margin-bottom: 40px;
    letter-spacing: .02em
  }
}


.gallery-item {
  position: relative;
  padding: 0 20px 0 0;
  margin-bottom: 72px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.gallery-item__media {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 12px;
  overflow: hidden;
}

.gallery-item__media img {
  width: 100%
}

.gallery-item__media a {
  display: block
}

.gallery-item__remove {
  position: absolute;
  top: -25px;
  right: 5px
}

.gallery-item.video a::after {
  content: '';
  display: block;
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  height: 85%;
  background: url(../images/icon-video.svg) no-repeat 50% 0
}

@media (max-width: 991px) {
  .gallery-item {
    padding-right: 0;
    margin-bottom: 40px
  }

  .gallery-item.video a::after {
    background-size: 85px auto
  }
}

.gallery-list .col-xs-6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

@media (max-width: 991px) {
  .gallery-list {
    margin-top: -10px
  }
}

.page_client .header::before, .page_client::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #385C8B;
  pointer-events: none
}

.page_client .page__title {
  font-weight: 800;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 10px
}

.page_client .page__title .primary-color {
  color: #385C8B;
  display: block
}

@media (max-width: 767px) {
  .page_client::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 49vh;
    width: 100%;
    background: #385C8B;
    pointer-events: none
  }

  .page_client .header__cabinet a {
    color: #fff
  }

  .page_client .header__cabinet a .counter {
    color: #fff
  }

  .page_client .header__burger .icon-bar {
    background: #fff
  }
}

@media (min-width: 768px) {
  .page_client .header::before, .page_client::before {
    width: 24%
  }

  .page_client .header__logo {
    width: 24%
  }
}

@media (min-width: 992px) {
  .page_client .page__title {
    font-size: 42px;
    line-height: 130%;
    margin-bottom: 27px
  }
}

@media (min-width: 1600px) {
  .page_client .header::before, .page_client::before {
    width: 30.85%
  }

  .page_client .header {
    padding-top: 28px
  }

  .page_client .header__logo {
    padding-top: 12px;
    width: 30.85%;
    margin-right: 42px
  }

  .page_client .header-menu {
    margin-bottom: 12px
  }
}

.client {
  margin-bottom: 115px
}

.client__aside {
  margin: -15px 30px 34px
}

.client__photo {
  background: #fff;
  -webkit-box-shadow: 0 30px 50px rgba(22, 24, 26, .2);
  box-shadow: 0 30px 50px rgba(22, 24, 26, .2)
}

.client__rating {
  margin-bottom: 4px
}

.client__rating .fa {
  margin-right: 6px;
  color: #fde022
}

.client__reviews {
  font-size: 12px;
  line-height: 130%;
  opacity: .7;
  letter-spacing: .02em
}

.client__reviews a {
  text-decoration: underline
}

.client__reviews a:hover {
  text-decoration: none
}

.client__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  line-height: 130%;
  margin-bottom: 25px
}

.client__social {
  margin-left: 38px
}

.client__social a {
  width: 25px;
  height: 25px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  background: rgba(56, 92, 139, .5);
  border-radius: 50%;
  margin-right: 3px;
  font-size: 8px;
  -webkit-transition: background .5s;
  -o-transition: background .5s;
  transition: background .5s
}

.client__social a:hover {
  background: #385C8B
}

.client__desc {
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 30px
}

.client__desc p {
  line-height: 1.5
}

.client__desc h5 {
  text-transform: uppercase;
  margin-top: 43px;
  margin-bottom: 26px;
  font-weight: 800
}

.client__edit-link {
  margin-bottom: 15px
}

.client__edit-link a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: #b3b3b7
}

.client__edit-link a .svg-icon {
  margin-right: 10px
}

.client__photo {
  position: relative;
}

.client .spec-card__edit-btn {
  background: #385C8B;
}

@media (max-width: 767px) {
  .client__photo {
    max-width: 230px;
    margin: 0 auto
  }

  .client__stats {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 12px
  }

  .client__rating {
    margin-right: 15px
  }

  .client__reviews {
    margin-bottom: 5px
  }

  .client__edit-link {
    margin-top: -20px
  }
}

@media (min-width: 768px) {
  .client {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 35px;
    margin-bottom: 20px
  }

  .client__aside {
    width: 32%;
    padding-top: 7px;
    margin-bottom: 40px
  }

  .client__content {
    width: 68%;
    position: relative
  }

  .client__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .client__stats {
    padding-top: 22px;
    margin-right: 38px
  }

  .client__stats .fa {
    margin-left: 7px;
    margin-right: 0
  }

  .client__reviews {
    text-align: right;
    font-size: 14px
  }
}

@media (min-width: 992px) {
  .client__content {
    padding-left: 40px
  }
}

@media (min-width: 1280px) {
  .client__content {
    padding-left: 80px;
    padding-right: 45px
  }

  .client__info {
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 40px
  }

  .client__social {
    margin-left: 48px
  }

  .client__desc {
    font-size: 18px
  }

  .client__desc h5 {
    margin-top: 72px;
    margin-bottom: 23px
  }

  .client__edit-link {
    position: absolute;
    top: -55px
  }
}

@media (min-width: 1600px) {
  .client__desc p {
    padding-right: 10%
  }
}

.client-interest {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -20px
}

.client-interest__item {
  margin-right: 20px;
  border: 1px solid rgba(77, 77, 77, .6);
  border-bottom: 1px solid #385C8B;
  padding: 17px 12px 8px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: rgba(37, 39, 51, .8);
  margin-bottom: 15px;
  position: relative
}

.client-interest__item:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: #385C8B;
  -webkit-transition: height .3s;
  -o-transition: height .3s;
  transition: height .3s
}

.client-interest__item.active {
  color: #fff
}

.client-interest__item.active::before {
  height: 100%
}

.client-interest__title {
  display: block;
  max-width: 180px;
  position: relative
}

.client-interest__more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
  padding: 15px 0 0 15px;
  margin-bottom: 15px
}

.client-interest__more .svg-icon {
  width: 117px
}

.client-interest__more:hover .svg-icon {
  fill: #385C8B
}

.client-interest__count {
  display: block;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: .02em;
  padding-left: 3px
}

@media (min-width: 992px) {
  .client-interest {
    margin-right: -42px
  }

  .client-interest__item {
    padding: 40px 42px 15px 23px;
    font-size: 16px;
    margin-bottom: 15px
  }

  .client-interest__more {
    padding-left: 52px
  }
}

.page_main .header {
  background: 0 0
}

.page_main .header__menu .has-submenu:hover a {
  color: #252733
}

.page_main.page_header-fix .header::before {
  content: '';
  display: block;
  //background: rgba(255, 255, 255, .8);
  background: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  width: 200vw;
  margin-left: -100vw;
  height: 100%;
}

.page_main.page_header-fix .header__cabinet a, .page_main.page_header-fix .header__cabinet a .counter, .page_main.page_header-fix .header__menu a, .page_main.page_header-fix .header__search a {
  color: #252733
}

.page_main.page_header-fix .header__burger .icon-bar {
  background: #252733
}

@media (max-width: 767px) {
  .page_main .header__cabinet a {
    color: #252733
  }

  .page_main .header__cabinet a .counter {
    color: #252733
  }

  .page_main.page_header-fix .header__cabinet a {
    color: #252733
  }

  .page_main.page_header-fix .header__cabinet a .counter {
    color: #252733
  }

  .page_main.page_header-fix .header__burger .icon-bar {
    background: #252733
  }
}


.main-advantages {
  padding: 100px 0;
  position: relative;
  margin-bottom: 145px
}

.main-advantages::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 76%;
  width: 50vw;
  bottom: 0;
  background: #385C8B url(../images/blur.svg) no-repeat 100% 50%;
  background-size: 100% auto;
  pointer-events: none
}

.main-advantages .advantages-items.grey-block_right {
  max-width: 650px;
  margin-left: 0;
  margin-bottom: 30px
}

.main-advantages .advantages-items .grey-block__content {
  padding: 38px 20px 65px
}

.main-advantages .advantages__title {
  margin-bottom: 15px
}

.main-advantages .advantages__btn {
  margin-top: 35px;
  margin-left: 30px
}

.main-advantages .advantages-item {
  min-height: 132px;
}

.main-advantages > .row > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main-advantages .advantages-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.main-advantages .advantages-items .grey-block__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.main-advantages .advantages-items .grey-block__content > .row {
  margin-bottom: auto;
}


.page_main .page__text-bg {
  top: auto;
  left: auto !important;
  right: 2.5%;
  z-index: 1;
  bottom: 160px;
}

.page_main .page__text-bg2 {
  position: absolute;
  bottom: 55px;
  right: 2.5%;
  z-index: 1;
  pointer-events: none;
}

.page_main .about {
  position: relative;
  margin-top: 120px;
  margin-bottom: 90px;
}

.page_main .about .video-preview {
  margin-top: 60px;
  left: 10px
}

.page_main .about__text blockquote {
  margin-bottom: 50px
}

.page_main .about-teaser {
  margin-bottom: 65px
}

.page_main .best-spec .page__text-bg {
  top: -150px;
  z-index: 0
}

.page_main .mainpage-about-how .page__text-bg {
  top: 120px
}

.page_main .about-how {
  margin-bottom: 95px
}

.page_main .about-how__content {
  padding: 105px 0;
  color: #fff
}

.page_main .about-how__steps {
  margin-bottom: 135px
}

.page_main .about-formats {
  padding: 0;
  position: relative
}

.page_main .about-formats h3 {
  color: #fff
}

.page_main .about-formats .formats {
  margin-top: 60px;
  margin-bottom: 30px
}


.page_main .mainpage-events .page__text-bg {
  top: 105px;
  opacity: .1
}

.page_main .mainpage-events .scroll-pane .calendar-event__group {
  padding-top: 30px
}

.page_main .mainpage-events .scroll-pane .calendar-event__group:first-of-type {
  padding-top: 150px
}

.page_main .mainpage-events .scroll-pane .calendar-event__group:first-of-type .owl-nav {
  margin-top: -210px
}

.page_main .event-calend {
  margin-top: 160px;
  margin-bottom: 140px
}


.page_main .mainpage-partners {
  margin-bottom: 105px
}

.page_main .mainpage-partners .page__text-bg {
  top: 5px
}

.page_main .mainpage-partners .mainpage-group__title-arrow {
  margin-bottom: 10px
}

.page_main .mainpage-partners .owl-carousel-wrap {
  margin-left: -50px;
  padding-left: 50px
}

.page_main .mainpage-partners .owl-carousel_partners {
  margin-top: 30px;
  margin-bottom: 20px
}

.page_main .mainpage-partners .owl-carousel_partners .partner {
  position: relative;
  margin-bottom: 0
}

.page_main .mainpage-partners .owl-carousel_partners .partner::before {
  content: '';
  display: block;
  padding-bottom: 100%
}

.page_main .mainpage-partners .owl-carousel_partners .partner__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.page_main .mainpage-partners .owl-carousel_partners .partner img {
  width: auto;
  max-width: 100%;
  margin: auto
}

.page_main .mainpage-partners .owl-carousel_partners .owl-stage {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.page_main .mainpage-partners .owl-carousel_partners .owl-item img {
  max-width: 70%;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s
}

.page_main .mainpage-partners .owl-carousel_partners .owl-item img:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05)
}

.page_main .mainpage-partners .owl-carousel_partners .owl-item.active img {
  max-width: 100%
}

.page_main .mainpage-partners .owl-carousel_partners .owl-item:not(.active) + .owl-item.active .partner {
  width: 100%;
  left: -10px
}

.mainpage-contacts {
  position: relative;
  padding: 70px 0
}

.mainpage-contacts::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -100vw;
  width: 200vw;
  height: 100%;
  pointer-events: none;
  background: #385C8B url(../images/contacts_bg.png) no-repeat 50% 0/cover
}

.mainpage-contacts .page__text-bg {
  top: 80px
}

.mainpage-contacts__content {
  position: relative;
  color: #fff
}

.mainpage-contacts .contacts-info {
  margin-top: 85px
}

.mainpage-contacts .contacts-info a {
  color: #fff
}

.mainpage-contacts .contacts-info .contacts__item {
  margin-bottom: 70px
}

.mainpage-contacts .btn {
  color: #fff !important
}

.mainpage-contacts .btn span {
  color: #fff !important
}

.mainpage-faq {
  margin: 85px 0 55px 25px
}

.mainpage-faq h3 {
  color: #fff;
  margin-bottom: 40px;
  letter-spacing: .02em
}

.mainpage-faq .scroll-pane {
  height: 360px;
  margin-bottom: 20px;
  padding-right: 30px
}

.mainpage-faq .faq-item {
  padding-top: 5px;
  border-bottom: none
}

.mainpage-faq .faq-item__title {
  padding-right: 0
}

.mainpage-faq .faq-item__title .fa {
  font-size: 20px
}

.mainpage-faq .btn {
  margin-left: 35px
}

.mainpage-faq .btn .svg-icon {
  margin-left: 15px
}


@include max1670 {
  .page_main .page__text-bg {
    left: -95px;
    width: 60px;
    padding-top: 0;
    top: auto;
    bottom: 170px;
  }

  .mainpage-top .page__text-bg {
    left: -75px
  }

  .mainpage-top {
    min-height: 600px
  }

  .mainpage-top__subtitle {
    margin-top: 35px;
    font-size: 20px
  }

  .mainpage-top__title {
    font-size: 76px;
    margin-bottom: 45px
  }

  .mainpage-top__menu-icon {
    margin-bottom: 15px
  }

  .mainpage-top__menu-icon img {
    max-height: 50px
  }

  .mainpage-top__menu-item {
    padding: 15px 22px;
    overflow: hidden
  }

  .mainpage-top__menu-text {
    font-size: 20px
  }

  .mainpage-top__down {
    bottom: 0;
    left: -105px
  }

  .owl-carousel-wrap .owl-carousel_accent .owl-nav {
    left: 35.5%;
    width: 21.5%
  }

  .page_main .best-spec {
    margin-bottom: 100px
  }

  .page_main .about-how__content {
    padding-bottom: 50px
  }

  .page_main .event-calend {
    margin-top: 100px;
    margin-bottom: 100px
  }

  .page_main .mainpage-partners {
    margin-bottom: 55px
  }

  .page_main .mainpage-events .scroll-pane .calendar-event__group:first-of-type {
    padding-top: 50px
  }

  .page_main .mainpage-events .scroll-pane .calendar-event__group:first-of-type .owl-nav {
    margin-top: -120px
  }

  @-moz-document url-prefix() {
    .page_header-fix .header {
      position: fixed;
    }
  }
}

@include max1300 {
  .page_main {
    padding-top: 0
  }

  .page_main .about {
    margin-top: 60px
  }

  .about-teaser {
    margin-bottom: 40px
  }

  .owl-carousel-wrap .owl-carousel_accent .owl-nav {
    left: 400px;
    width: 300px
  }

  .page_main .best-spec {
    margin-bottom: 60px
  }

  .page_main .about-how__content {
    padding: 80px 0 50px;
    color: #fff
  }

  .main-advantages {
    padding: 60px 0;
    margin-bottom: 60px
  }


  .mainpage-top__controls {
    margin-bottom: 30px
  }

  .mainpage-top__controls .btn-primary {
    margin-bottom: 28px
  }

  .mainpage-top__controls .btn-play {
    font-size: 14px
  }

  .mainpage-top__controls .btn-play::before {
    width: 36px;
    height: 36px;
    background-size: 12px auto;
    margin-right: 8px
  }

  .mainpage-top__down, .mainpage-top__social {
    display: none
  }

  .mainpage-top__menu-item {
    padding: 15px 10px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .mainpage-top__menu-icon {
    margin-bottom: 10px
  }

  .mainpage-top__menu-icon img {
    max-height: 30px;
    height: 30px
  }

  .mainpage-top__menu-text {
    font-size: 11px
  }

  .mainpage-top__menu-text .svg-icon {
    display: none
  }
}

//TODO: разобрать
@media (max-width: 767px) {


  .mainpage-group__subtitle {
    font-size: 18px;
    margin-top: 14px;
    margin-bottom: 5px
  }

  .mainpage-group__title {
    font-size: 24px;
    margin-top: 5px;
    margin-bottom: 20px
  }

  .mainpage-group__title-arrow {
    margin-bottom: 15px
  }

  .page_main .about .video-preview {
    left: 0;
    margin-top: 0;
    margin-bottom: 0
  }

  .page_main .about-teaser {
    margin-bottom: 0
  }

  .main-advantages {
    padding: 30px 0;
    margin-bottom: 0
  }

  .main-advantages::before {
    content: none
  }

  .main-advantages .advantages-items .grey-block__content {
    padding: 28px 15px 25px
  }

  .main-advantages .advantages__btn {
    margin-top: 10px;
    margin-left: 0;
    margin-bottom: 40px
  }

  .main-advantages .advantages-items.grey-block_right {
    margin-bottom: 50px
  }


  .owl-carousel-wrap .owl-carousel_accent .owl-item, .owl-carousel-wrap .owl-carousel_accent .owl-item:not(.active) + .owl-item.active {
    min-width: 165px;
    max-width: 165px
  }

  .owl-carousel-wrap .owl-carousel .owl-nav {
    left: 0;
    bottom: 0
  }

  .owl-carousel-wrap .owl-carousel_accent .owl-item:not(.active) + .owl-item.active .catalog-item__content {
    padding-left: 5px;
    padding-right: 5px
  }

  .page_main .about-how__content {
    padding-top: 35px
  }

  .page_main .about-how__content .mainpage-group__title {
    line-height: 1.3
  }

  .page_main .about-how__steps {
    margin-top: -40px;
    margin-bottom: 0
  }

  .page_main .about-formats .formats {
    margin-top: 15px
  }

  .page_main .about-how {
    margin-bottom: 70px
  }


  .page_main .event-calend {
    margin-top: 85px;
    margin-left: 0;
    margin-right: 0
  }


  .mainpage-contacts {
    padding-top: 38px
  }

  .mainpage-contacts .contacts-info {
    margin-top: 25px
  }

  .mainpage-contacts .contacts-info .contacts__item {
    margin-bottom: 30px
  }

  .mainpage-faq {
    margin-top: 30px;
    margin-left: 0
  }

  .mainpage-faq h3 {
    margin-bottom: 30px
  }

  .mainpage-faq .scroll-pane {
    height: 240px
  }

  .mainpage-faq .faq-item {
    margin-bottom: 15px
  }

  .mainpage-faq .btn {
    margin-left: 0;
    margin-top: 32px
  }
}

@media (min-width: 768px) and (max-width: 1599px) {
  .mainpage-contacts .contacts__value_phone {
    white-space: nowrap;
    font-size: 20px
  }
}

.page_spec .header::before, .page_spec::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #f48f6f;
  pointer-events: none
}

.page_spec::before {
  border-bottom-right-radius: 70px;
}

.page_spec::before {
  height: 90vh;
  min-height: 680px;
}

.page_spec .header::before {
  height: 100%
}

.page_spec .page__title {
  font-weight: 800;
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 10px;
  letter-spacing: .02em
}

.page_spec .page__text-bg {
  z-index: 1;
  padding-top: 110px;
  top: 0;
  height: 90vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 10px
}

.page_spec.theme-mentor .header::before, .page_spec.theme-mentor::before {
  background: #385C8B
}

.page_spec.theme-psycologist .header::before, .page_spec.theme-psycologist::before {
  background: #5d8d94
}

@media (max-width: 991px) {
  .page_spec::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 49vh;
    width: 100%;
    background: #f48f6f;
    pointer-events: none;
    min-height: 0;
    max-height: 250px
  }

  .page_spec .page__text-bg {
    display: none
  }

  .page_spec.theme-mentor::before {
    background: #385C8B
  }

  .page_spec.theme-psycologist::before {
    background: #5d8d94
  }

  .page_spec .header__cabinet a {
    color: #fff
  }

  .page_spec .header__cabinet a .counter {
    color: #fff
  }

  .page_spec .header__burger .icon-bar {
    background: #fff
  }

  .page_spec.page_header-fix .header__cabinet a,
  .page_spec.page_header-fix .header__cabinet a .counter {
    color: #252733 !important;
  }

  .page_spec.page_header-fix .header__burger .icon-bar {
    background: #252733 !important;
  }

  .page_spec.page_header-fix.menu-open .header__burger .icon-bar {
    background: #fff !important;
  }
}

@media (min-width: 992px) {
  .page_spec .header::before, .page_spec::before {
    width: 24%
  }

  .page_spec .header__logo {
    width: 24%
  }

  .page_spec .page__title {
    font-size: 28px;
    line-height: 130%;
    margin-bottom: 20px
  }
}

@media (min-width: 992px) and (max-width: 1599px) {
  .page_spec .page__text-bg {
    min-height: 0;
    width: 55px
  }
}

@media (min-width: 1280px) {
  .page_spec .page__title {
    font-size: 30px
  }

  .page_spec .header__container {
    padding-top: 15px;
    padding-bottom: 18px
  }

  .page_spec.page_header-fix .header__container {
    padding-top: 10px;
    padding-bottom: 10px
  }

  .page_spec.page_header-fix .header__logo a {
    height: 48px;
  }

  .page_spec.page_header-fix .header__logo img {
    height: 56px;
  }
}

@media (min-width: 1600px) {
  .page_spec .header::before, .page_spec::before {
    width: 30.85%
  }

  .page_spec::before {
    min-height: 900px;
    height: 90vh
  }

  .page_spec .header__logo { /*padding-top:12px;*/
    width: 30.85%;
    margin-right: 42px
  }

  .page_spec .header-menu {
    margin-bottom: 12px
  }

  .page_spec .page__title {
    font-size: 42px
  }
}

.spec__group {
  position: relative;
  margin-bottom: 100px
}

.page_spec .spec__group .page__text-bg {
  left: -13%;
  padding-top: 0;
  height: auto;
  display: block;
  min-height: 0
}

.spec__group-title {
  text-transform: uppercase;
  font-size: 30px;
  letter-spacing: .02em;
  line-height: 130%;
  color: inherit;
}

.spec__group-title .spec__edit-link {
  margin-left: 50px
}

.spec__edit-link {
  margin-bottom: 15px;
  display: inline-block
}

.spec__edit-link a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #b3b3b7;
  text-transform: none;
  font-weight: 500
}

.spec__edit-link a .svg-icon {
  margin-right: 10px;
  width: 23px;
  height: 23px
}

.spec__edit-link.inverse a {
  color: rgba(255, 255, 255, .5);
}

@media (min-width: 992px) and (max-width: 1599px) {
  .page_spec .spec__group .page__text-bg {
    left: -100px;
  }
}

@media (max-width: 991px) {
  .spec .page__text-bg {
    display: none !important
  }

  .spec__group-title {
    font-size: 21px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 15px
  }

  .spec__group-title .spec__edit-link {
    margin-left: 15px
  }

  .spec__group-title .spec__edit-link a .svg-icon {
    margin-right: 0
  }
}



@import "partials/spec-card.scss";
@import "partials/spec-competitions.scss";


.spec-about {
  margin-bottom: 125px
}

.spec-about p {
  margin-bottom: 34px
}

.spec-about__lead {
  position: relative
}

.spec-about__features {
  background: #f5f6fb;
  position: relative;
  border-radius: 40px;
}

.spec-about__features .col-xs-6:first-child::before {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 25px;
  width: calc(200% - 50px);
  border-bottom: 1px solid rgba(12, 12, 12, .2)
}

.spec-about__features::after {
  content: '';
  display: block;
  position: absolute;
  top: 15px;
  bottom: 15px;
  left: 50%;
  border-right: 1px solid rgba(12, 12, 12, .2)
}

.spec-about__features .col-xs-6:nth-child(2n) {
  padding-left: 0
}

.spec-about__feature {
  padding: 15px
}

.spec-about__feature .h4 {
  color: #f48f6f;
  font-weight: 700;
  margin-top: 0;
  font-size: 30px
}

.theme-mentor .spec-about__feature .h4 {
  color: #385C8B
}

.theme-psycologist .spec-about__feature .h4 {
  color: #5d8d94
}

.spec-about__feature p {
  margin-bottom: 0
}

@media (max-width: 991px) {
  .spec-about {
    margin-bottom: 25px
  }

  .spec-about p {
    margin-bottom: 10px
  }

  .spec-about__lead {
    margin-bottom: 55px
  }

  .spec-about__lead::before {
    content: none
  }

  .spec-about__features {
    margin-top: 40px;
    position: relative;
    margin-left: 0;
    margin-right: 0;
    padding: 10px 0 10px
  }

  .spec-about__feature {
    padding: 18px 0 8px 10px
  }

  .spec-about__feature .h4 {
    font-size: 21px;
    margin-bottom: 0
  }

  .spec-about__feature p {
    margin-bottom: 0
  }
}

@media (min-width: 768px) {
  .spec-about__features::before {
    left: 50px;
    right: 50px;
    top: 50%
  }

  .spec-about__features::after {
    top: 50px;
    bottom: 50px
  }

  .spec-about__feature {
    padding: 30px 20px 30px 30px
  }
}

@media (min-width: 1280px) {
  .spec-about__feature {
    padding: 45px 30px 45px 45px
  }
}

.spec-services {
  padding-top: 75px;
  padding-bottom: 75px;
  position: relative;
  margin-bottom: 115px
}

.spec-services .page__text-bg {
  top: 75px
}

.spec-services::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 34%;
  width: 100%;
  height: 100%;
  border-radius: 0 70px 70px 0;
}

.theme-coach .spec-services::before, .theme-consult .spec-services::before {
  background: #f48f6f
}

.theme-mentor .spec-services::before {
  background: #385C8B
}

.theme-psycologist .spec-services::before {
  background: #5d8d94
}

.spec-services__content {
  color: #fff;
  position: relative
}

.spec-services__content h5 {
  color: inherit;
  margin-bottom: 25px
}

.spec-services__content p {
  margin-bottom: 25px
}

.spec-services__order {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 65px;
  margin-bottom: 25px
}

.spec-services__price {
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  margin-right: 50px
}

.spec-services__price span.currency {
  font-size: 14px;
}

.spec-services__price .svg-icon {
  margin-left: 18px
}

.spec-services__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 58px
}

.spec-services__list ul {
  list-style: none;
  padding: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.spec-services__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #e4e9ed;
  padding: 50px 26px 32px;
  height: 162px;
  font-weight: 600;
  font-size: 21px;
  line-height: 130%;
  color: rgba(37, 39, 51, .9);
  margin: 0 0 28px;
  cursor: pointer;
  border-radius: 12px;
}

.active .spec-services__item, .spec-services__item:hover {
  color: #fff;
  background-color: #252733;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  -webkit-box-shadow: -30px 30px 50px rgba(37, 39, 51, .4);
  box-shadow: -30px 30px 50px rgba(37, 39, 51, .4)
}

.active .spec-services__item .spec-services__icon, .spec-services__item:hover .spec-services__icon {
  opacity: .8
}

.spec-services__icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 20px;
  display: inline-block;
  fill: #f48f6f;
  stroke: #f48f6f;
  opacity: 1;
  width: 60px;
  height: 60px
}

.theme-coach .spec-services__icon, .theme-consult .spec-services__icon {
  fill: #f48f6f;
  stroke: #f48f6f
}

.theme-mentor .spec-services__icon {
  fill: #385C8B;
  stroke: #385C8B
}

.theme-psycologist .spec-services__icon {
  fill: #5d8d94;
  stroke: #5d8d94
}

@media (max-width: 1599px) {
  .spec-services__item {
    font-size: 18px
  }

  .spec-services__icon {
    width: 40px;
    height: 40px
  }
}

@media (max-width: 1279px) {
  c-services__list {
    margin-top: 30px;
    margin-bottom: 30px
  }
}

@media (max-width: 991px) {
  .spec-services {
    padding-top: 30px;
    padding-bottom: 30px
  }

  .spec-services::before {
    right: 50%;
    width: 200%;
    margin-right: -100%
  }
}

@media (max-width: 767px) {
  .spec-services {
    margin-bottom: 45px
  }

  .spec-services__content > .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse
  }

  .spec-services__content h5 {
    font-size: 16px;
    margin-bottom: 15px
  }

  .spec-services__list {
    margin-bottom: 10px
  }

  .spec-services__list > .row {
    margin-left: -9px;
    margin-right: -9px
  }

  .spec-services__list > .row li, .spec-services__list > .row > div {
    padding-left: 9px;
    padding-right: 9px
  }

  .spec-services__item {
    font-size: 14px;
    padding: 20px 10px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    height: auto;
    margin-bottom: 12px
  }

  .spec-services__icon {
    margin-left: 0;
    margin-bottom: 18px
  }

  .spec-services__order {
    margin-top: 30px;
    margin-bottom: 20px
  }

  .spec-services__price {
    white-space: nowrap;
    font-size: 21px;
    margin-right: 35px
  }

  .spec-services__btn {
    font-size: 16px
  }

  .spec-services__btn .svg-icon {
    margin-left: 15px
  }
}

.spec-education {
  position: relative;
  margin-bottom: 115px
}


.spec-education::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -100vw;
  width: 200vw;
  height: 100%;
  pointer-events: none;
  background: url(../images/dance-line-orange.svg) no-repeat 50% 120px
}

.theme-mentor .spec-education::before {
  background-image: url(../images/dance-line-blue.svg)
}

.theme-psycologist .spec-education::before {
  background-image: url(../images/dance-line-green.svg)
}

.spec-education__content {
  position: relative;
  overflow: hidden
}

.spec-education .edu-item {
  margin-bottom: 105px;
  position: relative
}

.spec-education .edu-item__years {
  font-weight: 600;
  font-size: 24px;
  line-height: 160%;
  margin-bottom: 30px
}

.spec-education .edu-item__years span {
  display: inline-block;
  position: relative
}

.spec-education .edu-item__years span::before {
  opacity: .3;
  content: '';
  display: block;
  position: absolute;
  top: 55%;
  left: 0;
  width: 100%;
  height: 35%;
  z-index: -1
}

.theme-coach .spec-education .edu-item__years span::before {
  background: #f48f6f
}

.theme-consult .spec-education .edu-item__years span::before {
  background: #f48f6f
}

.theme-mentor .spec-education .edu-item__years span::before {
  background: #385C8B
}

.theme-psycologist .spec-education .edu-item__years span::before {
  background: #5d8d94
}

.spec-education .edu-item__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 160%
}

.spec-education .edu-item__subtitle {
  opacity: .5;
  font-style: italic;
  font-size: 19px
}

@media (max-width: 767px) {
  .spec-education {
    margin-bottom: 55px
  }

  .spec-education::before {
    content: none
  }

  .spec-education__content {
    overflow: visible
  }

  .spec-education__content .owl-carousel_education {
    width: 240px
  }

  .spec-education .edu-item {
    margin-bottom: 70px
  }

  .spec-education .edu-item__years {
    font-size: 21px;
    margin-top: 8px;
    margin-bottom: 20px
  }

  .spec-education .edu-item__title {
    font-size: 16px
  }

  .spec-education .edu-item__subtitle {
    font-size: 17px
  }
}

.spec-career {
  position: relative;
  padding-top: 60px;
  padding-bottom: 10px;
  margin-bottom: 105px
}

.spec-career .page__text-bg {
  top: 65px
}

.spec-career::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 66%;
  width: 100%;
  height: 100%;
  background-color: #e4e9ed;
  border-radius: 0 70px 70px 0;
}

.spec-career__content {
  position: relative
}

.spec-career .owl-carousel_career .owl-nav {
  position: absolute;
  top: -85px;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 275px;
  opacity: 1;


  top: 39%;
  /* bottom: 21px; */
  right: unset;
  left: -7%;
  width: 219%;
  @include max1600 {
    top: 59%;
    /* bottom: 21px; */
    right: unset;
    left: -8%;
    width: 224%;
  }
  @include max1250 {
    top: 38%;
    /* bottom: 21px; */
    right: unset;
    left: -1%;
    width: 182%;
  }
  @include max768 {
    width: 153px;

  }
  @include max576 {
    display: flex !important;
    width: 90px;


  }

   .prev, .next {
    @include max576 {
      width: 40px;
      height: 40px;
    }
  }
}

.spec-career .owl-carousel_career .owl-nav .svg-icon {
  width: 100%;
  height: 10px
}

.spec-career .owl-carousel_career .owl-nav > div {
  position: relative;
  width: calc(50% - 20px)
}

.spec-career .owl-carousel_career .owl-nav > div::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 30px;
  width: 100%;
  margin-top: -15px
}

.spec-career .owl-carousel_career .owl-prev {
  margin-right: 40px
}

.spec-career .owl-carousel_career .owl-prev .svg-icon {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1)
}

.spec-career .career-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.spec-career .career-item__place {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 34%;
  overflow: hidden;
}

.spec-career .career-item__years {
  font-weight: 700;
  font-size: 18px;
  line-height: 160%;
  color: #1e1e1e;
  opacity: .3;
  margin-top: 10px;
  margin-bottom: 10px;
  white-space: nowrap
}

.spec-career .career-item__title {
  color: #1e1e1e;
  font-weight: 600;
  font-size: 24px;
  line-height: 160%
}

.spec-career .career-item__title span {
  position: relative;
  display: inline-block;
  padding-right: 20px;
}

.spec-career .career-item__title span::before {
  content: '';
  display: block;
  position: absolute;
  top: auto;
  left: 0;
  width: 100%;
  height: 0.65em;
  z-index: -1;
  bottom: 0.1em;
}

.theme-coach .spec-career .career-item__title span::before {
  background: #f48f6f
}

.theme-consult .spec-career .career-item__title span::before {
  background: #f48f6f
}

.theme-mentor .spec-career .career-item__title span::before {
  background: #385C8B
}

.theme-psycologist .spec-career .career-item__title span::before {
  background: #5d8d94
}

.spec-career .career-item__text {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-left: 60px;
}

.spec-career .career-item__text p {
  margin-bottom: 30px
}

.spec-career .career-item__text h6, .spec-career .career-item__text ol, .spec-career .career-item__text ul {
  margin-bottom: 20px
}

.spec-career .career-item__text .scroll-pane {
  padding: 10px 120px 10px 0;
  height: 385px
}

@media (max-width: 991px) {
  .spec-career .career-item__title {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .spec-career {
    padding-top: 40px;
    margin-bottom: 50px
  }

  .spec-career::before {
    right: 50%;
    margin-right: -100%;
    width: 200%
  }

  .spec-career .owl-carousel_career { /*width:240px*/
    width: 100%;
  }

  .spec-career .owl-carousel_career .owl-stage-outer {
    overflow: visible
  }

  .spec-career .owl-carousel_career .owl-nav {
    position: relative;
    top: 0
  }

  .spec-career .career-item {
    display: block
  }

  .spec-career .career-item__text {
    padding-left: 0;
  }

  .spec-career .career-item__text .scroll-pane {
    padding-right: 30px;
    padding-top: 30px;
    height: 400px;
    margin-bottom: 45px
  }

  .spec-career .career-item__text .scroll-pane .scroller {
    max-width: 100% !important;
    width: 100% !important;
    min-width: 100% !important;
  }

  .spec-career .career-item__years {
    font-size: 16px;
    margin-bottom: 0
  }

  .spec-career .career-item__place {
    width: 100%
  }

  .spec-career .career-item__title {
    font-size: 21px
  }

  .spec-career__content {
    padding-bottom: 45px
  }
}

.spec-articles {
  position: relative;
  margin-bottom: 155px
}

.spec-articles .carousel-wrap {
  overflow: hidden;
}

.spec-articles .owl-carousel-wrap {
  margin-top: 80px
}

.spec-articles .blog-item {
  margin-bottom: 120px
}

@media (max-width: 767px) {
  .spec-articles {
    margin-bottom: 60px;
    overflow: visible
  }

  .spec-articles .owl-carousel-wrap {
    margin-top: 30px
  }

  .spec-articles .blog-item {
    margin-bottom: 70px
  }
}

/*.spec-sertif .owl-carousel-wrap{margin-left:-65px;padding-left:50px}*/
.spec-sertif .owl-carousel .owl-stage {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 610px;
  padding-top: 20px
}

.spec-sertif .owl-carousel .owl-stage .owl-item {
  min-width: 0;
  margin-bottom: 80px;
}

.spec-sertif .owl-carousel .owl-stage .owl-item .sertif {
  max-width: 180px;
}

.spec-sertif .owl-carousel .owl-stage .owl-item:not(.active) + .owl-item.active {
  min-width: 320px;
  text-align: center;
}

.spec-sertif .owl-carousel .owl-stage .owl-item:not(.active) + .owl-item.active .sertif {
  min-width: 320px;
  max-width: 320px;
}

.spec-sertif .owl-carousel .owl-stage .owl-item.active:first-of-type .sertif {
  max-width: 320px;
  max-height: 350px;
  overflow: hidden;
}

.spec-sertif .owl-carousel .owl-stage .owl-item:not(.active) + .owl-item.active img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.spec-sertif .owl-carousel .owl-stage .owl-item.active:first-of-type .sertif img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.spec-sertif .owl-carousel-wrap .owl-carousel .owl-nav {
  bottom: 120px;
  left: 0;
}

.spec-sertif .owl-carousel-wrap .owl-carousel .owl-dots {
  width: calc(75% - 15px);
  left: calc(25% + 15px);
  bottom: 135px;
}

.spec-sertif .sertif {
  min-height: 100%;
  width: 100%
}

.spec-sertif .sertif__media img, .spec-sertif2 .sertif__media img {
  width: auto;
  max-width: 100%;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  border-radius: 20px;
}

.spec-sertif .sertif__media img:hover, .spec-sertif2 .sertif__media img:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05)
}

@media (max-width: 767px) {
  .spec-sertif, .spec-sertif2 {
    margin-bottom: 65px
  }

  .spec-sertif .owl-carousel .owl-stage, .spec-sertif2 .owl-carousel .owl-stage {
    height: auto
  }

  .spec-sertif .owl-carousel .owl-stage .owl-item {
    min-width: 180px;
    margin-bottom: 30px;
    max-width: calc(100vw - 30px);
  }

  .spec-sertif .owl-carousel .owl-stage .owl-item:not(.active) + .owl-item.active .sertif {
    min-width: calc(100vw - 30px);
    margin-bottom: 0;
    max-width: calc(100vw - 30px);
  }

  .spec-sertif .owl-carousel .owl-stage .owl-item.active:first-of-type .sertif {
    min-width: calc(100vw - 30px);
    margin-bottom: 0;
    max-width: calc(100vw - 30px);
  }

  .spec-sertif .owl-carousel-wrap .owl-carousel .owl-nav,
  .spec-sertif2 .owl-carousel-wrap .owl-carousel .owl-nav {
    position: relative;
    bottom: 0;
    left: 0
  }

  .spec-sertif2 .owl-stage-outer {
    padding-bottom: 30px;
  }

  .spec-sertif2 .sertif {
    max-width: calc(100vw - 30px);
  }
}

.spec-gallery {
  display: none
}

.spec-reviews {
  position: relative
}

.spec-reviews::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -100vw;
  width: 200vw;
  height: 100%;
  pointer-events: none;
  background: url(../images/dance-line-orange.svg) no-repeat 50% 120px
}

.theme-mentor .spec-reviews::before {
  background-image: url(../images/dance-line-blue.svg)
}

.theme-psycologist .spec-reviews::before {
  background-image: url(../images/dance-line-green.svg)
}

@import "partials/review";


#thanksModal .modal-btn-wrap .btn-round.modal-btn {
  max-width: 350px;
  min-width: 0;
  margin: 0 auto;
  width: 100%
}

.mainpage-top {
  position: relative;
  z-index: 1
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  .mainpage-top {
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }

  .mainpage-top__menu {
    margin-top: auto
  }

  .spec-card__order {
    padding-right: 30px;
  }
}

/*
@media only screen and (min-device-width:1024px) and (max-device-width:1366px) and (orientation:landscape) and (-webkit-min-device-pixel-ratio: 1.5){
    .spec-card__aside {width: 55%;}
    .spec-card__content {width: 45%;}
}
*/


@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .spec-card {
    height: auto;
    min-height: 0
  }

  .spec-card__order {
    padding-right: 30px;
  }

  .page_spec::before {
    height: 50vh;
  }
}

.spec-card__order .owl-carousel .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0;
}

.spec-card__order-icon {
  pointer-events: none;
}

.reservation-person__image {
  position: relative;
  max-width: 336px;
  border-radius: 45px;
  overflow: hidden;
}

.reservation-person__controls {
  position: relative;
  max-width: 336px;
  margin: 30px 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.reservation-person__controls a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 12px;
  opacity: 0.6;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.reservation-person__controls .svg-icon {
  fill: #EB5757;
  margin-right: 10px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.reservation-person__controls a:hover {
  opacity: 0.9;
}

.reservation-person__edit {
  margin-left: 20px;
}

.reservation-person__close {
  margin-left: 20px;
}

.reservation-person__edit .svg-icon {
  width: 24px;
  height: 24px;
}

.reservation-person__close .svg-icon {
  width: 12px;
  height: 12px;
}


.select2-results__options::-webkit-scrollbar {
  width: 3px
}

.select2-results__options::-webkit-scrollbar-track {
  background: rgba(37, 39, 51, .5);
  -webkit-box-shadow: inset 0 0 0 1px #fff;
  box-shadow: inset 0 0 0 1px #fff
}

.select2-results__options::-webkit-scrollbar-thumb {
  background: #385C8B
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.modal-body a:hover {
  opacity: 0.8;
}

/* https://github.com/select2/select2-bootstrap-theme/issues/41 */
.modal-open .select2-dropdown {
  z-index: 10060;
}

.modal-open .select2-close-mask {
  z-index: 10055;
}


.catalog-filter__group.city {
  border-bottom: 0px;
}

.blog-search__form .select2-container {
  min-width: 100%;
}

@media only screen and (min-device-width: 768px) {
  .blog-search__group .checkbox-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.spec-card__order .owl-carousel .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.spec-card__order-icon {
  pointer-events: none;
}

@media (max-width: 767px) {
  .header__user-container .header__user img {
    margin-right: 0;
  }

  .modal-body {
    padding-top: 50px;
  }

  .modal-dialog .close {
    top: 20px;
    right: 15px;
  }
}

@media (max-width: 420px) {
  .modal-head .modal__title {
    font-size: 20px;
  }
}


.spec-gallery {
  display: block;
  position: relative;
}

.spec-gallery__pull-right {
  padding-top: 15px;
}

.spec-gallery .tabs-simple {
  padding-top: 15px;
}

.spec-gallery .tabs-simple a {
  margin-right: 30px;
}

.gallery-item .btn-play {
  position: relative;
  max-width: 300px;
}

.gallery-item .btn-play::before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -39px 0 0 -39px;
  content: '';
  display: block;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  background: url(../images/icon-play.svg) no-repeat 50% 50%;
}

.theme-mentor .gallery-item .btn-play::before, .theme-mentor .spec-gallery::before {
  background-color: #385C8B;
}

.theme-coach .gallery-item .btn-play::before, .theme-coach .spec-gallery::before {
  background-color: #f48f6f;
}

.theme-consult .gallery-item .btn-play::before, .theme-consult .spec-gallery::before {
  background-color: #f48f6f;
}

.theme-psycologist .gallery-item .btn-play::before, .theme-psycologist .spec-gallery::before {
  background-color: #5d8d94;
}

.spec-gallery .owl-carousel-wrap .owl-carousel .owl-dots .active {
  -webkit-box-shadow: 0 0 5px rgba(255, 255, 255, .5);
  box-shadow: 0 0 5px rgba(255, 255, 255, .5);
}

.theme-mentor .tabs-simple a.active {
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #385C8B 51%, rgba(56, 92, 139, .3) 51%, rgba(56, 92, 139, .3) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(51%, rgba(255, 255, 255, 0)), color-stop(51%, #385C8B), color-stop(51%, rgba(56, 92, 139, .3)), to(rgba(56, 92, 139, .3)));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #385C8B 51%, rgba(56, 92, 139, .3) 51%, rgba(56, 92, 139, .3) 100%);
}

.theme-consult .tabs-simple a.active,
.theme-coach .tabs-simple a.active {
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #f48f6f 51%, rgba(243, 112, 32, .3) 51%, rgba(243, 112, 32, .3) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(51%, rgba(255, 255, 255, 0)), color-stop(51%, #f48f6f), color-stop(51%, rgba(243, 112, 32, .3)), to(rgba(243, 112, 32, .3)));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #f48f6f 51%, rgba(243, 112, 32, .3) 51%, rgba(243, 112, 32, .3) 100%);
}

.theme-psycologist .tabs-simple a.active {
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #5d8d94 51%, rgba(93, 141, 148, .3) 51%, rgba(93, 141, 148, .3) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(51%, rgba(255, 255, 255, 0)), color-stop(51%, #5d8d94), color-stop(51%, rgba(93, 141, 148, .3)), to(rgba(93, 141, 148, .3)));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #5d8d94 51%, rgba(93, 141, 148, .3) 51%, rgba(93, 141, 148, .3) 100%);
}

@media (min-width: 768px) {
  .spec-gallery {
    padding: 70px 0;
  }

  .spec-gallery::before {
    content: '';
    position: absolute;
    display: block;
    width: 40vw;
    left: 50%;
    height: 100%;
    top: 0;
    margin-left: 10vw;
    border-radius: 70px 0 0 70px;
  }

  .spec-gallery .spec__edit-link a {
    color: rgba(255, 255, 255, .5);
  }
}

@media (max-width: 767px) {
  .spec-gallery__title {
    -webkit-box-ordinal-group: -1;
    -ms-flex-order: -2;
    order: -2;
  }

  .spec-gallery__pull-right {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .gallery-item {
    max-width: calc(100vw - 30px);
  }
}

@media (max-width: 1024px) {
  .responsiv-uploader-fileupload.style-image-multi .upload-button {
    white-space: normal;
    text-align: left;
  }
}

@media (max-width: 480px) {
  .header__user-header {
    border-radius: 50%;
  }
}

.owl-item:not(.active) .scroller.with-scroll + .scroller__bar-wrapper {
  display: none !important;
}

@media (max-width: 1599px) {
  .page_main .best-spec .page__text-bg {
    top: 0;
  }
}

.ui-widget.ui-widget-content {
  z-index: 999 !important;
}

.collapsed {
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.catalogFilter {
  font-size: 14px;
  color: #385C8B !important;
  margin: 0 0 25px;
}

.aside-filter {
  padding-bottom: 65px;
}

.owl-carousel-wrap .owl-carousel .owl-nav > div.disabled {
  opacity: .5;
  cursor: not-allowed;
}

.page__aside .select2-container {
  max-width: 100%;
  min-width: 100%;
}

.filter_level-2 .blog-search__form {
  margin-bottom: 30px;
  margin-right: 20px;
  margin-top: -10px;
}

@media (max-width: 991px) {
  .aside-filter {
    overflow: hidden;
    padding-bottom: 0;
  }

  .aside-filter.in {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .aside-filter__header {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }

  .aside-filter__body {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0 15px;
    margin-left: -15px;
    margin-right: -15px;
    padding-bottom: 65px;
  }
}

#buttonAddPublication {
  margin-top: 10px;
}

.scroll-up {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  background: #fff;
  color: #000;
  width: 50px;
  height: 50px;
  z-index: 999;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  box-shadow: 0 0 10px rgba(0, 0, 0, .2);
  display: none;
}

.scroll-up__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  margin: -15px 0 0 -15px;
}


.moderation .btn {
  background-color: #f48f6f;
}

.moderation .approved {
  background-color: #0EA804;
  color: #fff;
}

@media (min-width: 768px) {
  .owl-carousel .owl-item .gallery-item__media img {
    width: auto;
    height: 170px;
    max-width: 100%;
  }

  .spec-sertif2 .owl-carousel .owl-stage .owl-item .sertif {
    max-width: none;
    width: auto;
    height: 300px;
  }

  .spec-sertif2 .owl-carousel-wrap .owl-carousel .owl-dots {
    bottom: 10px;
  }

  .spec-sertif2 .owl-carousel-wrap .owl-carousel .owl-nav {
    bottom: 0;
  }

  .spec-sertif2 .owl-carousel-wrap {
    margin-bottom: 100px;
  }

  .owl-carousel_sertif {
    padding-bottom: 100px;
  }
}


.catalog-item .catalog-item__verified {
  z-index: 9;
  width: 14px;
  height: 16px;
  position: relative;
  margin-left: 5px;
  border: none;
  padding: 0;
  background: none;
}

.catalog-item .catalog-item__verified .svg-icon {
  fill: #5CBE85;
  width: 14px;
  height: 16px;
}


/*.catalog-item__viewed {display: inline-flex;align-items: center; margin-right: 20px;font-size: 12px; margin-top:-5px;}*/
/*.catalog-item__viewed .svg-icon {flex-shrink: 0; margin-right: 5px;}*/

.owl-carousel_tab-list {
  padding-bottom: 40px;
}

.owl-carousel_tab-list .owl-dots {
  position: relative;
  width: 100%;
  left: 0;
  border-bottom: 1px solid rgba(37, 39, 51, .5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 18px;
}

.owl-carousel_tab-list .owl-dot {
  height: 3px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  top: 2px;
  width: 100%;
  border-radius: unset;
  margin: 0;
  background: rgba(0,0,0,0.04);
}

.owl-carousel_tab-list .owl-dots .active {
  background: #385C8B;
}

.owl-carousel_tab-list .owl-nav {
  position: absolute;
  top: 100%;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: calc(25% - 30px);
}

.owl-carousel_tab-list .owl-nav > div {
  position: relative;
  width: calc(50% - 20px);
  margin-right: 20px;
}

.owl-carousel_tab-list .owl-nav > div::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  height: 30px;
  width: 100%;
  margin-top: -15px;
}

.owl-carousel_tab-list .owl-nav .svg-icon {
  width: 100%;
  height: 10px;
}

.owl-carousel_tab-list .owl-prev .svg-icon {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}

.form-group_notice {
  font-size: 13px;
  opacity: .8;
}

.form-group_notice + .h5 {
  margin-bottom: 20px;
  font-size: 18px;
}

.modal-body form[data-request="onRegister"] .form-group .col-xs-6 .radio-wrap {
  margin-bottom: 0;
}

.modal-body form[data-request="onRegister"] .form-group {
  margin-bottom: 15px;
}

.blog-article__content a, a.link {
  color: #385C8B;
  text-decoration: underline;
}

.blog-article__content a:hover, .blog-article__content a:active, a.link:hover, a.link:active {
  color: #385C8B;
  text-decoration: none;
}

.deals__phone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.deals__phone .svg-icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.deals__email {
  display: block;
  overflow: hidden;
  max-width: 100%;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.deals__email .svg-icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}


.cabinet-cards {
  margin-bottom: 40px;
}

.cabinet-cards__list {
  margin-top: 30px;
  margin-bottom: 60px;
}

.cabinet-cards__list-item {
  margin-bottom: 17px;
  max-width: 378px;
}

.card-item {
  color: #252733;
  font-size: 16px;
  line-height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.card-item__number {
  padding-right: 40px;
}

.card-item__activate {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #385C8B;
}

.card-item__delete {
  color: #B3B3B7;
}

.card-item__delete .svg-icon {
  width: 10px;
  height: 10px;
}

.cabinet-cards__add {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #385C8B;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.cabinet-cards__add .svg-icon {
  margin-right: 20px;
}

.card-active {
  margin-bottom: 20px;
}

.card-active__data {
  background: #f5f6fb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 16px 25px;
}

.card-active__number {
  font-weight: 600;
  color: #252733;
  text-align: center;
  font-size: 18px;
  line-height: 130%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-right: 20px;
}

.card-active__data .svg-icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.card-active__desc {
  font-size: 14px;
  line-height: 150%;
  padding: 5px;
}

@media (min-width: 768px) {
  .cabinet-cards {
    padding-left: 10px;
    padding-right: 10px;
  }

  .cabinet-cards__title {
    margin-bottom: 45px;
  }

  .cabinet-cards__label {
    color: #5D5D64;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 27px;
  }

  .cabinet-cards__list {
    margin-bottom: 50px;
  }

  .cabinet-cards__add {
    margin-top: -12px;
  }
}

@media (min-width: 1600px) {
  .card-active__number {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .cabinet-cards {
    margin-top: -25px;
  }

  .cabinet-cards__list {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .card-active__desc {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
  }

  .card-active__data {
    margin-bottom: 20px;
    padding-left: 45px;
    padding-right: 45px;
  }

  .card-active__number {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
  }

  .card-item {
    font-size: 14px;
  }

  .card-item__number {
    padding-right: 10px;
  }

  .cabinet-cards__add {
    font-size: 14px;
  }

  .card-item__activate {
    font-size: 14px;
  }
}

#buttonPhoneCodeCheck.btn-round.btn-bordered {
  background-color: #0EA804;
  color: #fff;
  border-color: #0EA804;
}

h1.page__title a:hover {
  //text-decoration: underline;

}

.form-group.spec-field-phone, .form-group.spec-field-email {
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  [class^=share-] {
    width: 38px !important;
    height: 38px !important;
  }

  [class^=share-] svg {
    width: 20px !important;
    height: 20px !important;
  }

  .page__share {
    margin-bottom: 30px
  }
}

.page__comments {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0;
}

.page__comments .col-xs-6 {
  float: none;
  width: 100%;
}

.page__comments h3.text-right {
  text-align: left;
  text-transform: none;
  font-weight: 600;
  font-size: 30px;
  line-height: 115%;
  color: #151528;
}

.page__comments h2.comment-reply-title {
  text-align: left;
  text-transform: none;
  font-weight: 600;
  font-size: 22px;
  line-height: 115%;
  color: #151528;
}

.page__comments .row {
  margin-left: 0;
  margin-right: 0;
  display: block;
}


.comments > ul > li {
  background: #f5f6fb;
  padding: 20px;
  margin-bottom: 20px;
}

.comments .comment {
  position: relative;
  padding-left: 50px !important;
  min-height: 50px;
  margin-bottom: 20px;
}

.comments .comment-avatar {
  width: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.comments .comment-avatar img {
  width: 50px;
  height: 50px;
}

.comments .comment-date {
  color: rgba(21, 21, 40, .5);
}

.comments .comment-content {
  margin-left: 15px;
  overflow: hidden;
  float: none;
  font-size: 14px;
}

.comments .comment-header {
  margin-bottom: 10px;
  font-size: 14px;
}

.comments .comment-name {
  font-size: 15px;
}

.comments .comment-footer {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

@media (max-width: 767px) {
  .page__comments h3.text-right {
    font-size: 24px;
  }

  .page__comments .comments {
    margin: 0 -30px;
  }

  .comments .comment-avatar img {
    width: 30px;
    height: 30px;
  }

  .comments .comment-avatar {
    width: 30px;
  }

  .comments .comment {
    padding-left: 30px !important;
  }
}

/* popover */
.popover {
  background: #FFFFFF;
  border-radius: 3px;
  -webkit-box-shadow: 0px 5px 25px rgba(30, 31, 33, 0.12);
  box-shadow: 0px 5px 25px rgba(30, 31, 33, 0.12);
  border: none;
  padding: 0;
  text-shadow: none;
  -webkit-filter: none;
  filter: none;
}

.popover.right {
  margin-left: 5px;
}

.popover .arrow {
  display: none;
}

.popover-title {
  color: #252733;
  opacity: 0.8;
  background: none;
  font-size: 12px;
  line-height: 130%;
  border: none;
  padding: 8px 10px 0;
  margin-bottom: -5px;
}

.popover-content {
  color: #5D5D64;
  font-size: 12px;
  line-height: 130%;
  padding: 10px;
  text-transform: none;
  text-shadow: none;
}


#rocketModal.modal.in .modal-dialog {
  width: 100%;
  max-width: 632px;
}

#rocketModal .modal-content {
  background: url(../images/rocket-modal-bg.svg) no-repeat 100% 0 / auto 100%;
  height: 442px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

#rocketModal .modal-content > div {
  width: 100%;
}

#rocketModal.modal .close {
  top: 25px;
  right: 35px;
}

#rocketModal.modal .close .svg-icon {
  width: 12px;
  height: 12px;
}

#rocketModal.modal .close:hover .svg-icon {
  fill: #fff;
}

#rocketModal .modal-content h5 {
  font-weight: 800;
  font-size: 24px;
  line-height: 130%;
  margin-bottom: 25px;
}

#rocketModal .modal-content p {
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 25px;
}

#rocketModal .modal-content .rocket-content {
  padding: 15px;
  background: rgba(255, 255, 255, .9);
}



.services-list {
  margin-top: 70px;
}

.services-list__item {
  margin-bottom: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
  border-radius: 12px !important;
}

.services-list__item.panel-group .panel + .panel {
  margin-top: 0;
}

.services-list .services-list__item:nth-child(2n+1) {
  background: #F5F6FB;
}

.services-list__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 32px 20px;
  cursor: pointer;
  border-radius: 12px;
}

.services-list__head .fa {
  margin-left: 20px;
  font-size: 24px;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
}

.services-list__head-title {
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.services-list__head-icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 10px;
  width: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.services-list__head[aria-expanded="true"] .fa {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.services-list__head[aria-expanded="true"] .services-list__head-pay {
  display: none;
}

.services-list__head-pay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 30px;
  white-space: nowrap;
}

.services-list__head-pay .btn {
  margin-right: 0;
}

.services-list__head-pay-price {
  margin-right: 30px;
}

.services-list__body {
  padding: 0 20px;
}

.services-list__body-content {
  padding-top: 10px;
  padding-bottom: 25px;
}

.services-list__body-content p {
  margin-bottom: 10px;
}

.services-list__body-text {
  padding-left: 40px;
  padding-bottom: 30px;
}

.services-list__pay {
  margin: 28px 0;
  max-width: 400px;
}

.services-list__pay-item {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.services-list__pay-label {
  margin-right: 20px;
  position: relative;
}

.services-list__pay-label label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.services-list__pay-label small {
  font-size: 14px;
  margin-top: 5px;
  display: block;
}

.services-list__pay-btn {
  margin-left: auto;
}

.services-list__pay-btn .btn {
  margin-right: 0;
}

.services-list__pay-hint {
  font-style: italic;
  margin-bottom: 50px;
  font-size: 14px;
  line-height: 150%;
  opacity: .8;
}

@media (min-width: 1600px) {
  .services-list__head {
    padding: 32px 35px;
  }

  .services-list__body {
    padding: 0 35px;
  }

  .services-list__head .fa {
    margin-left: 55px;
  }
}

@media (max-width: 600px) {
  .services-list__head-pay {
    display: none;
  }

  .services-list__body-text {
    padding-left: 0;
  }

  .services-list__pay-item {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .services-list__pay-btn {
    margin-left: 30px;
    width: 100%;
  }
}

.tarifs-list-wrap {
  margin-top: 70px;
  margin-bottom: 50px;
}

.tarifs-list__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.tarifs-list__body .tarifs-list__row:nth-child(2n + 1) .tarifs-list__cell {
  background: #F5F6FB;
}

.tarifs-list__body .tarifs-list__row:hover .tarifs-list__cell {
  background: rgba(170, 170, 239, .5);
}

.tarifs-list__cell {
  max-width: 233px;
  width: 25%;
  padding: 15px 16px;
  text-align: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
}

.tarifs-list__cell_first {
  max-width: none;
  text-align: left;
  width: auto;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  display: block;
}

.tarifs-list__head .tarifs-list__cell_first {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.tarifs-list__head .tarifs-list__cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
}

.tarifs-list__head .tarifs-list__cell_accent {
  color: #fff;
  font-size: 14px;
  line-height: 130%;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.tarifs-list__head .tarifs-list__cell_accent .tarifs-list__title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
}

.tarifs-list__head .tarifs-list__cell_accent-blue {
  background: #374859;
  border-radius: 10px 0px 0px 0px;
}

.tarifs-list__head .tarifs-list__cell_accent-orange {
  background: #f48f6f;
}

.tarifs-list__head .tarifs-list__cell_accent-green {
  background: #28CDAC;
  border-radius: 0px 10px 0px 0px;
}

.tarifs-list__body .tarifs-list__cell {
  border-left: 1px solid rgba(179, 179, 183, .2);
}

.tarifs-list__body .tarifs-list__cell_first {
  border-left: none;
}

.tarifs-list__row_footer .bold {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.tarifs-list__row_footer .tarifs-list__cell {
  padding-top: 35px;
  padding-bottom: 35px;
  display: block;
}

.tarifs-list__pay-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin-bottom: 15px;
}

.tarifs-list__pay-control {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.tarifs-list__pay-label {
  text-align: left;
  font-size: 14px;
}

.tarifs-list__pay-label label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tarifs-list__pay-label strong {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.tarifs-list__pay-btn {
  text-align: center;
  margin-top: 30px;
}

.tarifs-list__pay-btn .btn {
  margin-right: 0;
}

@media (max-width: 767px) {
  .tarifs-list-wrap {
    overflow-x: auto;
    width: 100%;
  }

  .tarifs-list__cell {
    width: 233px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
}

@media (min-width: 1600px) {
  .tarifs-list__cell {
    padding-left: 20px;
    padding-right: 20px;
  }

  .tarifs-list__cell_first {
    padding-left: 30px;
  }
}

/* блок услуг с календарем  */
.spec-services_calend .ui-datepicker {
  max-width: 320px;
  width: 100%;
  font-family: Montserrat, arial, sans-serif;
}

.spec-services_calend .row {
  margin-right: -12px;
  margin-left: -12px;
}

.spec-services_calend .row > div {
  padding-left: 12px;
  padding-right: 12px;
}

.spec-services_calend .ui-datepicker {
  display: block;
}

.spec-services_calend .spec-services__price {
  font-weight: 400;
  margin-bottom: 20px;
  margin-right: 0;
}

.spec-services__calendar {
  margin-top: 45px;
}

.spec-services__calendar .calendar {
  margin-bottom: 30px;
}

.spec-services_calend .calendar__choose-date {
  border: none;
  background: none;
  margin-bottom: 15px;
  text-transform: lowercase;
}

.spec-services_calend .btn-time {
  border: 1px solid rgba(255, 255, 255, .4);
  color: #fff;
  background: none;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  padding: 7px 10px;
  display: block;
  margin-bottom: 15px;
}

.spec-services_calend .btn-time:hover, .spec-services_calend .btn-time.selected {
  background: #fff;
  color: #252733;
  border-color: #fff;
}

.spec-services_calend .btn-time.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: .5;
}

.spec-services_calend .calendar__choose-date {
}

.spec-services_calend .ui-widget.ui-widget-content {
  border: none;
  border-radius: 0;
  background: none;
  color: #fff;
}

.spec-services_calend .ui-datepicker th {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.005em;
  text-transform: lowercase;
}

.spec-services_calend .ui-widget-header {
  background: none;
  border: none;
  border-bottom: 1px solid #D4D4D7;
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.005em;
  font-weight: 600;
  padding-bottom: 5px;
}

.spec-services_calend .ui-state-default,
.spec-services_calend .ui-widget-content .ui-state-default,
.spec-services_calend .ui-widget-header .ui-state-default,
.spec-services_calend .ui-button,
html .spec-services_calend .ui-button.ui-state-disabled:hover,
html .spec-services_calend .ui-button.ui-state-disabled:active {
  background: none;
  border: none;
  color: #fff;
  text-align: center;
  letter-spacing: 0.005em;
  font-weight: 500;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.spec-services_calend .ui-state-highlight,
.spec-services_calend .ui-widget-content .ui-state-highlight,
.spec-services_calend .ui-widget-header .ui-state-highlight {

}

.spec-services_calend .ui-state-active,
.spec-services_calend .ui-widget-content .ui-state-active,
.spec-services_calend .ui-widget-header .ui-state-active,
.spec-services_calend a.ui-button:active,
.spec-services_calend .ui-button:active,
.spec-services_calend .ui-button.ui-state-active:hover {
  background: #fff;
  color: #000;
}

.spec-services_calend .ui-widget-header .ui-icon {
  background: none;
  height: 32px;
  width: 32px;
  font-size: 16px;
  margin-top: -16px;
  margin-left: -16px;
}

.spec-services_calend .ui-widget-header .ui-icon::before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  position: absolute;
  top: 0;
  left: 0;
  text-indent: 0;
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, .5);
  line-height: 32px;
  height: 100%;
}

.spec-services_calend .ui-widget-header .ui-icon:hover::before {
  color: #fff;
}

.spec-services_calend .ui-datepicker .ui-datepicker-prev,
.spec-services_calend .ui-datepicker .ui-datepicker-next {
  background: none !important;
  border-color: transparent !important;
}

.spec-services_calend .ui-datepicker .ui-datepicker-prev .ui-icon::before {
  content: "\f104"
}

.spec-services_calend .ui-datepicker .ui-datepicker-next .ui-icon::before {
  content: "\f105"
}


.cabinet-form_wide {
  max-width: 100%;
}

.calendar-form {
  margin-bottom: 50px;
}

.calendar-form .ui-datepicker {
  max-width: 320px;
  width: 100%;
}

.calendar-form__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.calendar-form__row .calendar {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  max-width: 320px;
}

.calendar-form .calendar-steps {
  display: none;
}

.calendar-form .cabinet-form__buttons-wrap {
  display: none;
}

.calendar-form .services-list {
  margin-top: 30px;
  margin-bottom: 33px;
}

.calendar-form .services-list__head {
  padding: 20px 25px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.calendar-form .services-list__head.collapsed,
.calendar-form .services-list__head[aria-expanded="true"] {
  pointer-events: none;
  cursor: not-allowed;
}

.calendar-form .panel-title {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.calendar-form .services-list__head .fa {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.calendar-form .services-list__head-subtitle {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5D64;
  margin-bottom: 7px;
}

.calendar-form .services-list__head-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  padding-right: 15px;
  margin-right: -55px;
}

.calendar-form .services-list__body {
  padding: 0 25px;
}

.calendar-form .services-list__body-content {
  padding: 0 0 20px;
}

.calendar-form .services-list__body-content .btn-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  margin-right: 15px;
  color: rgba(42, 45, 54, 0.6);
}

.calendar-form .services-list__body-content .btn-link.primary-color {
  color: #374859;
}

.calendar-form .panel-group .panel + .panel {
  margin-top: 0;
}

.cabinet-form .calendar-form .form-group.radio-wraps {
  margin-bottom: 7px;
}

.cabinet-form .calendar-form .form-group .radio-wrap {
  margin-bottom: 0;
}

.cabinet-form .calendar-form .form-group.disabled {
  opacity: .5;
  pointer-events: none;
}

.cabinet-form .radio-wraps__comment {
  font-weight: 300;
  font-size: 14px;
  line-height: 160%;
  color: #252733;
  margin-bottom: 35px;
}

.cabinet-form .italic {
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  opacity: 0.8;
  margin-top: 15px;
}

.cabinet-form .radio-wraps__comment .italic {
  margin-bottom: 35px;
}

.calendar-form.active .change-calendar,
.calendar-form.active .add-calendar {
  display: none;
}

.calendar-form.active .calendar-steps {
  display: block;
}

.calendar-form.active .cabinet-form__buttons-wrap {
  display: block;
}

.calendar-form .ui-datepicker {
  margin-bottom: 30px;
  font-family: Montserrat, arial, sans-serif;
}

.calendar-form .ui-widget.ui-widget-content {
  border: none;
  border-radius: 10px;
  background: #F5F6FB;
  color: #252733;
  padding: 10px;
}

.calendar-form .ui-datepicker th {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.005em;
  text-transform: lowercase;
}

.calendar-form .ui-widget-header {
  background: none;
  border: none;
  border-bottom: 1px solid #D4D4D7;
  text-transform: uppercase;
  color: #252733;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.005em;
  font-weight: 600;
  padding-bottom: 5px;
}

.calendar-form .ui-state-default,
.calendar-form .ui-widget-content .ui-state-default,
.calendar-form .ui-widget-header .ui-state-default,
.calendar-form .ui-button,
html .calendar-form .ui-button.ui-state-disabled:hover,
html .calendar-form .ui-button.ui-state-disabled:active {
  background: none;
  border: none;
  color: #252733;
  text-align: center;
  letter-spacing: 0.005em;
  font-weight: 500;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.calendar-form .ui-state-highlight,
.calendar-form .ui-widget-content .ui-state-highlight,
.calendar-form .ui-widget-header .ui-state-highlight {

}

.calendar-form .ui-state-active,
.calendar-form .ui-widget-content .ui-state-active,
.calendar-form .ui-widget-header .ui-state-active,
.calendar-form a.ui-button:active,
.calendar-form .ui-button:active,
.calendar-form .ui-button.ui-state-active:hover {
  color: #374859;
  font-weight: 600;
}

.calendar-form .ui-widget-header .ui-icon {
  background: none;
  height: 32px;
  width: 32px;
  font-size: 16px;
  margin-top: -16px;
  margin-left: -16px;
}

.calendar-form .ui-widget-header .ui-icon::before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  position: absolute;
  top: 0;
  left: 0;
  text-indent: 0;
  width: 100%;
  text-align: center;
  color: #252733;
  opacity: .3;
  line-height: 32px;
  height: 100%;
}

.calendar-form .ui-widget-header .ui-icon:hover::before {
  opacity: 1;
}

.calendar-form .ui-datepicker .ui-datepicker-prev,
.calendar-form .ui-datepicker .ui-datepicker-next {
  background: none !important;
  border-color: transparent !important;
}

.calendar-form .ui-datepicker .ui-datepicker-prev .ui-icon::before {
  content: "\f104"
}

.calendar-form .ui-datepicker .ui-datepicker-next .ui-icon::before {
  content: "\f105"
}

.week-calend {
  border-radius: 10px;
  background: #F5F6FB;
  color: #252733;
  padding: 10px;
  max-width: 320px;
  width: 100%;
  margin-bottom: 30px;
  pointer-events: none;
}

.week-calend.editable {
  pointer-events: auto;
}

.week-calend__head {
  border-bottom: 1px solid #D4D4D7;
  text-transform: uppercase;
  color: #252733;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.005em;
  font-weight: 600;
  padding: 5px 0 8px;
  text-align: center;
}

.week-calend__body table {
  width: 100%;
  font-size: .9em;
  border-collapse: collapse;
  margin: 0 0 .4em;
}

.week-calend__body th {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.005em;
  text-transform: lowercase;
  padding: .7em .3em;
  text-align: center;
  border: none;
}

.week-calend__body td {
  border: 0;
  padding: 1px;
}

.week-calend__day {
  background: none;
  border: none;
  color: #252733;
  text-align: center;
  letter-spacing: 0.005em;
  font-weight: 500;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.week-calend__day label {
  font-weight: 500;
  text-align: center;
  display: block;
  margin: 0;
  cursor: pointer;
}

.week-calend__day input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.week-calend__day input:checked + label {
  color: #374859;
  font-weight: 600;
}

.btns-time {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 215px;
  margin-right: 40px;
  width: 100%;
  margin-bottom: 30px;
}

.btns-time .btn-time {
  position: relative;
  width: 95px;
  margin: 0 7px 7px 0;
  overflow: hidden;
}

.btns-time .btn-time input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.btns-time .btn-time label {
  border-radius: 5px;
  margin-bottom: 0;
  font-weight: 400;
  padding: 7px 3px;
  font-size: 14px;
  line-height: 25px;
  background: rgba(245, 246, 251, 0.7);
  color: #252733;
  opacity: .5;
  cursor: pointer;
  display: block;
  text-align: center;
}

.btns-time .btn-time input:checked + label {
  opacity: 1;
}

@media (min-width: 768px) {
  .calendar-form.active .calendar-form__row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .calendar-form .calendar-steps {
    margin-right: 30px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }

  .calendar-form .services-list__head-title {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .spec-services_calend .spec-services__price {
    float: right;
  }
}

@media (min-width: 1280px) {
  .spec-services__calendar { /*margin-right: -100px;*/
    position: relative;
    z-index: 1;
  }

  .spec-services__calendar .col-xs-4 {
    width: 50%;
  }
}

.form-control-wrap_with-edit .select2-container--default .select2-selection--single .select2-selection__arrow {
  display: none;
}

.add-calendar {
  float: left;
}

.calendar-form__posttext {
  padding: 10px 20px;
  border: 5px solid #F5F6FB;
  margin-top: 20px;
}

.calendar-form__posttext .danger {
  padding: 5px 15px;
  border: 2px solid rgb(240, 47, 23);
  background: rgba(240, 47, 23, .3);
  margin: 12px 0;
  border-radius: 10px;
  font-size: 14px;
}

.reserv {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.reserv__id {
  background: #F5F6FB;
  color: #252733;
  padding: 2px 5px;
  display: inline-block;
  width: 40px;
  text-align: center;
  margin-right: 15px;
}

.reserv__date {
  width: 100px;
  margin-right: 10px;
}

@media (max-width: 991px) {
  .spec-card__content,
  .spec-card__prehead,
  .spec-card__prehead .spec__prehead-aside {
    position: static;
  }

  .spec-card__prehead .spec-card__statuses {
    position: absolute;
    top: 10px;
    left: 50%;
    margin-left: 0;
    text-align: right;
    width: 115px;
  }
}

.header__user-menu .dropdown-item.tarifs-menu-item,
.tarifs-menu-item {
  color: #385C8B !important;
}


.header__corp-link {
  font-size: 12px;
  border: 1px solid #252733;
  height: 30px !important;
  padding: 0;
  background: #F48F6F;
}

.header__corp-link .fa {
  font-size: 24px;
}

@media (max-width: 567px) {
  .header__corp-link {
    font-size: 10px;
  }

  .header__user-container {
    margin-left: 5px;
  }
}

@media (max-width: 375px) {
  .header__corp-link {
    padding: 0 2px;
    margin: 0 5px !important;
  }

  .header__logo {
    margin-left: 0;
    margin-right: 8px;
  }

  .header__cabinet a {
    margin-left: 8px;
  }
}

.header__user-container {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.persons > .col-xs-12 {
  display: none !important;
}

@media (min-width: 992px) and (max-width: 1600px) {
  .page_spec .header-menu__item {
    margin-right: 20px;
  }

  .header__search {
    margin-right: 0;
  }
}

.spec-services__btn {
  background: #fff;
  padding: 7px 25px;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
}

.spec-services__btn:hover {
  background: transparent;
  color: #fff;
}

/* Сообщения */
.cabinet-menu ul a .svg-icon-wrap {
  display: inline-block;
  margin: 2px 22px 0 0;
  width: 30px;
  text-align: center;
}

.cabinet-menu ul a .svg-icon-wrap .svg-icon_comment {
  width: 20px;
  height: 20px;
  margin: 0;
}

.messages__search-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 35px;
  border-bottom: 1px solid rgba(15, 15, 20, .5);
}

.messages__search-form input {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
  border: none;
  color: rgba(15, 15, 20, .5);
  font-size: 16px;
  line-height: 20px;
  height: 44px;
}

.messages__search-form button {
  color: #374859;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 10px;
  width: 30px;
  height: 44px;
  padding: 5px 0;
  background: transparent;
  border: none;
  line-height: 44px;
}

.messages__search-form button .svg-icon {
  width: 18px;
  height: 18px;
}

.messages-list {
  border-bottom: 1px solid rgba(179, 179, 183, .5);
}

.messages-item {
  position: relative;
  padding: 15px 24px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid rgba(179, 179, 183, .5);
}

.messages-item_new {
  background: #F5F6FB;
  border-top-color: #F5F6FB;
}

.messages-item__media {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 30px;
  border: 1px solid #F5F6FB;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.messages-item__body {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
}

.messages-item__aside {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-left: 20px;
  text-align: right;
}

.messages-item__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
}

.messages-item__desc {
  font-size: 16px;
  line-height: 150%;
  width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.messages-item__time {
  font-size: 16px;
  line-height: 150%;
  color: rgba(15, 15, 20, .5);
  margin-bottom: 10px;
}

.messages-item__count {
  color: #374859;
  font-weight: 700;
}

.messages-item__link {
  font-size: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.messages-item:hover .messages-item__title {
  color: #374859;
}

.message__head {
  position: relative;
  text-align: center;
  padding: 0 80px;
  border-bottom: 1px solid rgba(179, 179, 183, .5);
  line-height: normal;
}

.message__head * {
  line-height: normal;
}

.message__head-back {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 16px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.message__head-back .fa {
  margin-right: 15px;
  font-size: 24px;
}

.message__head-spec {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.message__head-spec-avatar {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 30px;
  border: 1px solid #F5F6FB;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.message__head-spec-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.message .messages__search-form {
  margin-bottom: 2px;
}

.chat {
}

.chat__head {
  text-align: center;
  background: #F5F6FB;
  color: #374859;
  font-size: 18px;
  padding: 12px 15px;
  margin-bottom: 2px;
}

.chat__messages {
  max-height: 470px;
  overflow: auto;
  margin-bottom: 20px;
}

.chat__messages .form-group {
  margin-bottom: 20px;
}

.chat__messages .form-group + .chat__message {
  margin-top: 45px;
}

.chat__messages .control-label {
  padding-bottom: 5px;
  display: block;
}

.chat__messages::-webkit-scrollbar {
  width: 3px
}

.chat__messages::-webkit-scrollbar-track {
  background: rgba(37, 39, 51, .5);
  -webkit-box-shadow: inset 0 0 0 1px #fff;
  box-shadow: inset 0 0 0 1px #fff
}

.chat__messages::-webkit-scrollbar-thumb {
  background: #385C8B
}

.chat__message {
  position: relative;
  padding: 25px 24px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.chat__message_answer,
.chat__message_new {
  background: #F5F6FB;
}

.chat__media {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 30px;
  border: 1px solid #F5F6FB;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.chat__body {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
}

.chat__title {
  margin-bottom: 10px;
}

.chat__name {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.chat__desc {
  font-size: 16px;
  line-height: 150%;
  width: 100%;
}

.chat__desc img {
  display: block;
  max-width: 180px;
}

.chat__time {
  font-size: 16px;
  line-height: 150%;
  color: rgba(15, 15, 20, .5);
  margin-left: 10px;
}

.chat__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-top: 50px;
}

.chat__form .chat__textarea {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.chat__form .chat__textarea .textarea-wrap {
  min-height: 34px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.chat__form .chat__file {
  position: relative;
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: rgba(15, 15, 20, .5);
  margin-left: 20px;
  margin-bottom: 5px;
  cursor: pointer;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.chat__form .chat__file input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.chat__form .chat__smile {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: rgba(15, 15, 20, .5);
  margin-left: 20px;
  margin-bottom: 5px;
  cursor: pointer;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  pointer-events: none;
  opacity: 0;
}

.chat__form .chat__btn {
  margin-left: 20px;
}

.chat__form .chat__file:hover, .chat__form .chat__smile:hover {
  color: #385C8B;
}

.chat__btns {
  margin: 50px -15px 60px;
}

.chat__btns .btn-round {
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 15px;
  margin-left: 15px;
}

.btn-round.btn-green {
  color: #5CBE85;
  border-color: #5CBE85;
}

.btn-round.btn-green:hover, .btn-round.btn-green:focus, .btn-round.btn-green:focus:active, .btn-round.btn-green:active {
  color: #fff;
  background: #5CBE85;
  border-color: #5CBE85;
}

.timer {
  text-align: center;
  padding: 75px 15px 50px;
}

.timer__title {
  font-size: 18px;
  margin-bottom: 20px;
}

.timer__counter {
}

.timer__items {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.timer__item {
}

.timer__numbers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.timer__numbers span {
  width: 50px;
  height: 50px;
  margin: 0 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #F5F6FB;
  color: #385C8B;
  font-weight: 500;
  font-size: 21px;
}

.timer__separator {
  padding: 0 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
}

@media (max-width: 1279px) {
  .chat {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .messages-item {
    display: block;
    padding: 10px 15px;
    border-top: 1px solid rgba(179, 179, 183, .5);
  }

  .messages-item__media {
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
  }

  .messages-item__aside {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-left: 0;
    margin-top: 10px;
  }

  .messages-item__time {
    margin-bottom: 0;
  }

  .message__head {
    padding: 0;
    text-align: left;
  }

  .message__head-back {
    position: relative;
    margin-bottom: 10px;
  }

  .chat__head {
    font-size: 13px;
  }

  .chat__messages .scroller__content {
    padding-right: 20px;
  }

  .chat__messages .form-group {
    margin-bottom: 12px;
  }

  .chat__messages .control-label {
    padding-bottom: 0;
  }

  .messages-item, .chat__message {
    display: block;
    padding: 10px 5px;
    border-top: 1px solid rgba(179, 179, 183, .5);
  }

  .messages-item__media, .chat__media {
    width: 36px;
    height: 36px;
    position: absolute;
  }

  .messages-item__title, .chat__title {
    margin-left: 45px;
    min-height: 32px;
  }

  .messages-item__title, .chat__name {
    font-size: 15px;
  }

  .messages-item__time, .chat__time {
    font-size: 11px;
    margin-left: 0;
    display: block;
  }

  .messages-item__desc, .chat__desc {
    font-size: 13px;
  }

  .chat__btns {
    margin-left: 0;
    margin-right: 0;
  }

  .chat__btns .btn-round {
    width: 100%;
    margin: 0 0 15px;
  }

  .chat__form {
    display: block;
    margin-bottom: 40px;
  }

  .chat__form .chat__btn {
    margin-left: 0;
    margin-top: 15px;
  }

  .timer__numbers span {
    width: 25px;
  }

  .message__head-back {
    height: auto;
    margin-top: -10px;
  }

  .message__head-spec {
    margin-bottom: 10px;
  }

  .messages-list {
    margin-top: -35px;
  }

}

.emojionearea, .emojionearea.form-control {
  background: 0 0 !important;
  border: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-bottom: 2px solid #dfdfe1;
  font-size: 16px;
  border-radius: 0;
  padding: 0;
}

.emojionearea .emojionearea-editor {
  min-height: 34px;
}

.emojionearea .emojionearea-button {
  top: auto;
  bottom: -5px;
  right: -100px;
  opacity: 1 !important;
}

.emojionearea .emojionearea-button > div {
  width: 30px;
  height: 30px;
  background-position: 0 0;
  background-size: contain;
  background-image: url('../images/icon-smile.svg') !important;
}

.emojionearea .emojionearea-button:hover > div {
  background-image: url('../images/icon-smile-active.svg') !important;
}

.emojionearea .emojionearea-button {
  width: 30px;
  height: 30px;
}

.emojionearea .emojionearea-button > div.emojionearea-button-close {
  opacity: 0 !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

.emojionearea .emojionearea-button > div.emojionearea-button-open {
  background-position: 0 0;
  opacity: 1 !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

.emojionearea .emojionearea-picker.emojionearea-picker-position-bottom {
  right: -114px;
  top: 100%;
}

.emojionearea .emojionearea-picker.emojionearea-picker-position-top {
  right: -114px;
}

.emojionearea .emojionearea-picker .emojionearea-search > input {
  border: 1px solid #dfdfe1;
  border-radius: 2px;
  font-size: 14px;
  text-transform: lowercase;
  height: 30px;
  padding: 2px 10px;
}

.emojionearea .emojionearea-picker .emojionearea-scroll-area::-webkit-scrollbar {
  width: 3px
}

.emojionearea .emojionearea-picker .emojionearea-scroll-area::-webkit-scrollbar-track {
  background: rgba(37, 39, 51, .5);
  -webkit-box-shadow: inset 0 0 0 1px #fff;
  box-shadow: inset 0 0 0 1px #fff
}

.emojionearea .emojionearea-picker .emojionearea-scroll-area::-webkit-scrollbar-thumb {
  background: #385C8B
}

.emojionearea .emojionearea-picker .emojionearea-wrapper {
  background: #F5F6FB;
}

.emojionearea .emojionearea-picker.emojionearea-filters-position-top .emojionearea-filters {
  background: #385C8B;
}

.emojionearea .emojionearea-picker .emojionearea-scroll-area .emojionearea-category-title {
  background: transparent;
  color: #385C8B;
  font-weight: 700;
}

.cabinet-content__tabs .owl-carousel_tab-list .owl-nav {
  width: 100%;
}

.cabinet-content__tabs .owl-carousel_tab-list .owl-nav > div {
  width: 118px;
}

.cabinet-content__tabs .owl-carousel_tab-list .owl-nav .svg-icon {
  fill: #385C8B;
}

.cabinet-content__tabs .owl-carousel_tab-list .owl-nav .svg-icon svg path {
  opacity: 1 !important;
}

.spec-services__btn:hover {
  background: transparent;
  color: #fff;
}

.js-filename {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.block-round {
  -webkit-box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
  border-radius: 45px;
  position: relative;
  padding: 70px 100px;
  margin-left: -105px;
  margin-right: -100px;
}

.block-blue {
  background: #374859;
  color: #fff;
}

.block-orange {
  background: #F48F6F;
}

.block-green {
  background: #374859;
}

body [class^=share-] svg {
  width: 20px;
  height: 20px;
  margin: 13px;
}


.block-round .mainpage-group__title {
  max-width: 500px;
  padding-left: 10px;
  font-size: 50px;
}

.block-round p {
  font-size: 18px;
  margin: 5px 0 22px 15px;
}

.block-round p:last-child {
  margin-bottom: 0;
}

h1.page__title, .page__title_small {
  font-weight: 800;
  font-size: 24px;
  line-height: 130%
}

.catalog-spec-find {
  margin-top: 100px;
  margin-bottom: 100px;
}

.catalog-spec-find__title {
  margin-bottom: 60px;
}

.catalog-spec-find__text {
  margin-bottom: 75px;
}

.catalog-spec-find__text p {
  margin-bottom: 20px;
  line-height: 1.4;
}

.catalog-spec-find__btn .btn span {
  padding-right: 15px;
}

.catalog-blog {
  margin-bottom: 170px;
}

.catalog-blog:last-child {
  margin-bottom: 0;
}

.catalog-blog__title {
  text-transform: none;
  font-weight: bold;
  letter-spacing: 0;
}

.catalog-blog__title .spec-card__name {
  text-transform: uppercase;
}

.catalog-blog__text {
  margin-top: 65px;
  margin-bottom: 35px;
}

.catalog-blog__text p {
  font-size: 18px;
}

.catalog-blog__btn .btn span {
  padding-right: 45px;
}

.catalog-blog_carousel { /*width: calc(100% + 30px);*/
  margin: 85px -15px;
  padding: 0 15px;
}

.catalog-blog .catalog-blog_carousel {
  padding-left: 0;
  padding-right: 0;
}

.catalog-blog .owl-carousel-wrap .owl-stage-outer {
  padding-bottom: 40px;
}

.catalog-blog__carousel {
  padding-left: 0;
  margin-left: 0;
}

.catalog-blog__top {
  margin-bottom: 100px;
}

.theme-coach .pagination__item_current {
  background: #f48f6f;
}

.theme-coach .pagination__next {
  color: #f48f6f;
}

.theme-coach .btn-primary span {
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #f48f6f 51%, rgba(244, 143, 111, .3) 51%, rgba(244, 143, 111, .3) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(51%, rgba(255, 255, 255, 0)), color-stop(51%, #f48f6f), color-stop(51%, rgba(244, 143, 111, .3)), to(rgba(244, 143, 111, .3)));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #f48f6f 51%, rgba(244, 143, 111, .3) 51%, rgba(244, 143, 111, .3) 100%);
}

.theme-coach .btn-primary:hover::before {
  background: #f48f6f;
}

.theme-psycologist .pagination__item_current {
  background: #5d8d94;
}

.theme-psycologist .pagination__next {
  color: #5d8d94;
}

.theme-psycologist .btn-primary span {
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #5d8d94 51%, rgba(93, 141, 148, .3) 51%, rgba(93, 141, 148, .3) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(51%, rgba(255, 255, 255, 0)), color-stop(51%, #5d8d94), color-stop(51%, rgba(93, 141, 148, .3)), to(rgba(93, 141, 148, .3)));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #5d8d94 51%, rgba(93, 141, 148, .3) 51%, rgba(93, 141, 148, .3) 100%);
}

.theme-psycologist .btn-primary:hover::before {
  background: #5d8d94;
}


@import "partials/spec-filters.scss";
.tag {
  opacity: .7;
  font-size: 16px;
  line-height: 130%;
  color: #374859;
  padding: 7px 12px;
  border-radius: 12px;
  border: 1px solid #F48F6F;
  margin-right: 9px;
  margin-bottom: 9px;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  display: block;
  white-space: nowrap;
}

.tag.active {
  background: #F48F6F;
  opacity: 1;
}

/* ховеры показываем только на десктопе */
@media (min-width: 992px) {
  .tag:hover {
    background: #F48F6F;
    opacity: 1;
  }
}

.header__cabinet-tooltip-wrap {
  position: relative;
  border-radius: 5px;
  width: 30px;
  height: 30px !important;
  padding: 0;
}

.header__cabinet-tooltip-wrap .header__cabinet-tooltip {
  position: absolute;
  top: 100%;
  right: 100%;
  padding: 5px;
  border-radius: 5px;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  font-size: 12px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
  -webkit-box-shadow: 4px 5px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 5px 10px rgba(0, 0, 0, 0.15);
  margin: -5px -5px 0 0;

}

.header__cabinet-tooltip-wrap .svg-icon {
  margin: 0 !important;
}

.header__cabinet-tooltip-wrap:hover .header__cabinet-tooltip {
  opacity: 1;
}

.header-menu {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 1000px;
  margin-right: auto;
}

.header-menu__item.level-1 {
  text-transform: lowercase;
  font-size: 16px;
  font-weight: 400;
}

.header-menu__burger {
  position: relative;
}

.header-menu__burger-btn {
  padding: 0;
  width: 60px;
  background: none;
  border: none;
  padding-top: 6px;
}

.header-menu__burger-btn .burger-line {
  margin-bottom: 6px;
  background: #374859;
  height: 3px;
  border-radius: 10px;
}

.header-menu__burger-btn .burger-line_01 {
  width: 60px;
}

.header-menu__burger-btn .burger-line_02 {
  width: 45px;
}

.header-menu__burger-btn .burger-line_03 {
  width: 30px;
}

.header-menu__burger-list {
  position: absolute;
  top: 100%;
  left: -15px;
  background: rgba(255, 255, 255, .95);
  -webkit-box-shadow: 0 5px 25px rgba(30, 31, 33, 0.12);
  box-shadow: 0 5px 25px rgba(30, 31, 33, 0.12);
  display: none;
}

.header-menu__burger:hover .header-menu__burger-list {
  display: block;
}

.header-menu__burger-list .header-menu__item {
  margin-right: 0;
  margin-left: 0;
}

.header-menu__burger-list .header-menu__link {
  padding: 10px 15px;
  display: block;
}

.header-menu__burger-list .header-menu__submenu {
  background: rgba(0, 0, 0, 0.04);
  position: static;
  display: block;
  margin: 0;
}

.header-menu__burger-list .header-menu__submenu::before {
  content: none;
}

@import "partials/theme-item";

.theme-item.theme-item_with-media:hover .theme-item__media {
  opacity: .8
}


@media (min-width: 425px) and (max-width: 767px) {
  .catalog .row {
    margin-left: -8px;
    margin-right: -8px;
  }

  .catalog .col-xs-12 {
    width: 50%;
    padding-left: 8px;
    padding-right: 8px;
  }

  .catalog-item__content {
    padding-left: 15px;
    padding-right: 15px;
  }
}


.themes-list {
  margin-bottom: 130px;
}

.themes-list .theme-item {
  margin-bottom: 30px;
}


.page-theme__specialists {
  margin-bottom: 80px;
}

.page-theme__top {
  margin-bottom: 90px;
}

.page-theme__title-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.page-theme__title-wrap .accent-color {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: .02em;
  margin-left: 5px;
}

.page-theme__specialists .catalog-blog__title {
  margin-top: 18px;
}

.page-theme__specialists .catalog-blog__text {
  max-width: 600px;
  margin-top: 25px;
}

.theme-coach .accent-color {
  color: #F48F6F;
}

.theme-psycologist .accent-color {
  color: #5D8D94;
}

.theme-mentor .accent-color {
  color: #385C8B;
}


.theme-group__order {
  margin-bottom: 180px;
}


.page_themes-list .page__main {
  padding-bottom: 160px;
}

.page_themes-list .page__title_small {
  margin: 52px 0 60px;
}

.page_themes-list .page-theme__top {
  margin-bottom: 75px;
}

.page_themes-list .catalog-item {
  margin-bottom: 75px;
}

.page_themes-list .page-theme__specialists {
  margin-bottom: 70px;
}

.page_themes-list .spec-filters_themes {
  margin-top: 70px;
  margin-bottom: 160px;
}

.page_themes-list .spec-filters_themes .h5 {
  margin-bottom: 45px;
}

.page_themes-list .catalog-blog__top {
  margin-bottom: 85px;
}

.page_themes-list .catalog-blog .owl-carousel-wrap .container {
  padding-left: 0;
  padding-right: 0;
}


.page_theme-page .page__title_small {
  margin: 32px 0 60px;
}

.page_theme-page .header::before, .page_theme-page::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #f48f6f;
  pointer-events: none
}

.page_theme-page::before {
  border-bottom-right-radius: 70px;
}

.page_theme-page::before {
  height: 90vh
}

.page_theme-page .header::before {
  height: 100%;
  -webkit-transition: opacity .5s;
  -o-transition: opacity .5s;
  transition: opacity .5s;
}

.page_theme-page.page_header-fix .header::before {
  opacity: 0;
}

.page_theme-page.theme-mentor .header::before, .page_theme-page.theme-mentor::before {
  background: #385C8B
}

.page_theme-page.theme-psycologist .header::before, .page_theme-page.theme-psycologist::before {
  background: #5d8d94
}

@media (max-width: 991px) {
  .page_theme-page::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 49vh;
    width: 100%;
    background: #f48f6f;
    pointer-events: none;
    min-height: 0;
    max-height: 250px
  }

  .page_theme-page.theme-mentor::before {
    background: #385C8B
  }

  .page_theme-page.theme-psycologist::before {
    background: #5d8d94
  }

  .page_theme-page .header__cabinet a {
    color: #fff
  }

  .page_theme-page .header__cabinet a .counter {
    color: #fff
  }

  .page_theme-page .header__burger .icon-bar {
    background: #fff
  }

  .page_theme-page.page_header-fix .header__cabinet a,
  .page_theme-page.page_header-fix .header__cabinet a .counter {
    color: #252733 !important;
  }

  .page_theme-page.page_header-fix .header__burger .icon-bar {
    background: #252733 !important;
  }

  .page_theme-page.page_header-fix.menu-open .header__burger .icon-bar {
    background: #fff !important;
  }
}

@media (min-width: 992px) {
  .page_theme-page .header::before, .page_theme-page::before {
    width: 24%
  }

  .page_theme-page .header__logo {
    width: 24%
  }
}

@media (min-width: 1280px) {
  .page_theme-page .header__container {
    padding-top: 15px;
    padding-bottom: 18px
  }
}

@media (min-width: 1600px) {
  .page_theme-page .header::before, .page_theme-page::before {
    width: 30.85%
  }

  .page_theme-page::before {
    min-height: 970px;
    height: 90vh
  }

  .page_theme-page .header__logo {
    width: 30.85%;
    margin-right: 42px
  }
}

.page_theme-page .theme-page__media-wrap {
  position: relative;
  margin-bottom: 70px;
}

.page_theme-page .theme-page__media-wrap::after {
  content: '';
  display: block;
  clear: both;
}

.page_theme-page .theme-page__media {
  float: right;
  width: 50vw;
  border-radius: 0 45px 45px 0;
  overflow: hidden;
  text-align: right;
}

.page_theme-page .theme-page__media img {
  height: 600px;
}

.page_theme-page .theme-page {
  margin-bottom: 170px;
}

.page_theme-page .theme-page__prehead {
  font-weight: 600;
  font-size: 30px;
  line-height: 130%;
  text-transform: uppercase;
  color: #252733;
  padding-top: 30px;
}

.page_theme-page .theme-page__prehead::after {
  content: '';
  display: block;
  width: 62px;
  height: 5px;
  margin: 26px 0 50px;
}

.page_theme-page.theme-mentor .theme-page__prehead::after {
  background: #385C8B;
}

.page_theme-page.theme-coach .theme-page__prehead::after {
  background: #f48f6f;
}

.page_theme-page.theme-psycologist .theme-page__prehead::after {
  background: #5D8D94;
}

.page_theme-page .theme-page__content {
  padding-left: 9.5%;
  padding-bottom: 95px;
}

.page_theme-group .theme-page__content {
  padding-left: 9.5%;
}

.page_theme-page .theme-page__text a {
  color: #385C8B;
  text-decoration: underline;
}

.page_theme-page .theme-page__text a:hover {
  color: #385C8B;
  text-decoration: none;
}

.page_theme-page .theme-page__text p {
  font-size: 16px;
  margin-bottom: 24px;
  line-height: 1.4;
}

.page_theme-page .theme-page__text p strong {
  font-size: 18px;
  font-weight: 600;
}

.page_theme-page .theme-page__text {
  margin-bottom: 50px;
}

.page_theme-page .spec-card__total-item strong {
  padding-right: 5px;
}

.page_theme-page .theme-page__btn {
  position: absolute;
  bottom: 95px;
}

.page_theme-page .theme-page__btn .btn-primary span {
  padding-right: 50px;
}

.page_theme-page .page-theme__title-wrap .accent-color {
  margin-left: 23px;
  margin-top: 13px;
}

.page_theme-page .catalog-blog__text {
  margin-top: 40px;
  max-width: 600px;
}

.page_theme-page .catalog-blog__top {
  margin-bottom: 75px;
}

.page_theme-page .owl-carousel-wrap {
  margin-left: -15px;
}

.page_theme-page .mpage-themes__text {
  margin: 14px 0 155px;
  max-width: 600px;
}

.theme-help-order .catalog-blog__title {
  letter-spacing: .02em;
}

.theme-help-order .theme-help-order__text {
  padding: 28px 0 60px 80px;
}

.theme-help-order .theme-help-order__text blockquote {
  padding: 0 30px;
  font-size: 20px;
  line-height: 1.2;
}

/*
.theme-mentor .theme-help-order .theme-help-order__text blockquote {border-left-color: #385C8B;}
.theme-coach .theme-help-order .theme-help-order__text blockquote {border-left-color: #f48f6f;}
.theme-psycologist .theme-help-order .theme-help-order__text blockquote {border-left-color: #5D8D94;}
*/
.theme-help-order .theme-help-order__text blockquote p {
  margin-bottom: 20px;
}

.theme-help-order .theme-help-order__btn {
  padding-top: 28px;
  padding-bottom: 28px;
  text-align: center;
}

.theme-help-order .theme-help-order__btn .btn {
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
}

.theme-help-order__adv {
  margin: -5px 0 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 8%;
}

.theme-help-order__adv-item {
  position: relative;
  width: 28%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.theme-help-order__adv-num {
  font-weight: bold;
  font-size: 50px;
  line-height: 61px;
  color: #F48F6F;
  padding-bottom: 120px;
}

.theme-help-order__adv-num::after {
  content: '';
  display: block;
  position: absolute;
  top: 85px;
  left: 30px;
  bottom: 10px;
  width: 2px;
  background: #F48F6F;
}

.theme-help-order__adv-text {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #374859;
  padding-left: 50px;
}

.theme-help-order__adv-subtext {
  padding-left: 50px;
  padding-top: 25px;
  background: #fff;
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}


.page_theme-group .page__title_small {
  margin-top: 45px;
  margin-bottom: 45px;
}

.page_theme-group .theme-page__media-wrap {
  position: relative;
  margin-bottom: 70px;
}

.page_theme-group .theme-page__media-wrap::after {
  content: '';
  display: block;
  clear: both;
}

.page_theme-group .theme-page__media {
  float: right;
  width: 50vw;
  border-radius: 0 45px 45px 0;
  overflow: hidden;
  text-align: right;
  -webkit-box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
}

.page_theme-group .theme-page__media img {
  height: 600px;
}

.page_theme-group .theme-page__prehead {
  font-weight: 800;
  font-size: 48px;
  line-height: 130%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #252733;
  margin-top: 40px;
  margin-bottom: 65px;
  @include  max576 {
    font-size: 24px;
  }
}

.page_theme-group .theme-page__text p {
  font-size: 30px;
  line-height: 130%;
  @include max576 {
    font-size: 20px;
  }
}

.page_theme-group .theme-page__text {
  margin-bottom: 70px;
}

.page_theme-group .spec-card__total {
  padding-left: 10px;
  margin-bottom: 70px;
}

.page_theme-group .spec-card__total-item strong {
  padding-right: 10px;
}

.page_theme-group .theme-page__group-main {
  color: #252733;
  font-size: 18px;
  padding: 65px 0 60px;
  position: relative;
  margin-top: 72px;
  margin-bottom: 140px;
}

.page_theme-group .theme-page__group-main::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  width: 200vw;
  margin-left: -100vw;
  height: 100%;
}

.page_theme-group .theme-page__group-main > div {
  position: relative;
}

.page_theme-group.theme-mentor .theme-page__group-main::before {
  background: #385C8B;
}

.page_theme-group.theme-coach .theme-page__group-main::before {
  background: #f48f6f;
}

.page_theme-group.theme-psycologist .theme-page__group-main::before {
  background: #5D8D94;
}

.page_theme-group .theme-page__group-main p {
  padding-top: 10px;
  max-width: 600px;
  line-height: 1.4;
}

.page_theme-group .theme-page__group-main .page__title {
  margin-bottom: 30px;
}

.page_theme-group .theme-help-order__adv {
  margin-bottom: 180px;
}

.page_theme-group .catalog-blog__text {
  margin-top: 23px;
  margin-bottom: 35px;
  max-width: 600px;
  line-height: 1.4;
}


/*.page_main #main-top-accounts .owl-nav,
.page_main #main-top-accounts .owl-dots {display: none !important;}*/


#best-spec-accent {
  margin-bottom: 40px;
  border-radius: 25px;
  -webkit-box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
}

#best-spec-accent.owl-carousel .owl-stage {
  padding-top: 0;
}

.best-spec-accent {
  margin-bottom: 20px;
}

.best-spec-accent__card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #F9FAFC;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  min-height: 420px
}

.best-spec-accent__media {
  width: 48%;
  max-height: 420px;
  overflow: hidden;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
}

.best-spec-accent__media::before {
  content: '';
  display: block;
  padding-bottom: 134%;
}

.best-spec-accent__media img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.best-spec-accent__content {
  border-left: 10px solid #ccc;
  padding: 30px 25px 15px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.theme-mentor .best-spec-accent__content {
  border-left-color: #385C8B;
}

.theme-coach .best-spec-accent__content {
  border-left-color: #f48f6f;
}

.theme-psycologist .best-spec-accent__content {
  border-left-color: #5D8D94;
}

.best-spec-accent__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
}

.best-spec-accent__info {
  margin-right: auto;
}

.best-spec-accent__comments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 2px 6px auto 15px;
}

.best-spec-accent__comments .svg-icon {
  width: 24px;
  height: 24px;
  margin-right: 7px;
  margin-top: 1px;
}

.best-spec-accent__comments .svg-icon_eye {
  width: 22px;
  height: 22px;
}

.best-spec-accent__theme {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
}

.best-spec-accent__title {
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  margin-bottom: 8px;
}

.best-spec-accent__subtitle {
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 30px;
}

.best-spec-accent__text {
  line-height: 1.4;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.best-spec-accent__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
}

.best-spec-accent__footer {
  margin-top: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 0 10px 10px 0;
  position: relative;
}

.best-spec-accent__icons .catalog-item__mail {
  margin-left: 30px;
}

.best-spec-accent__card {
  display: block;
}

.best-spec-accent__media {
  width: 100%;
  height: auto;
  position: relative;
}

.best-spec-accent__media::before {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.best-spec-accent__media img {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
}

.best-spec-accent__content {
  padding: 20px 15px;
}

.best-spec-accent__title {
  font-size: 20px;
  line-height: 1.5;
}

.best-spec-accent__footer {
  display: block;
}

.page_main .best-spec-accent__video {
  margin-bottom: 15px;
}


.page_main .best-spec blockquote {
  line-height: 1.4;
  padding-left: 25px;
  width: 100%;
  padding-top: 0;
  font-size: 16px;
  margin-bottom: 25px;
}

.page_main .best-spec blockquote p {
  margin-bottom: 25px;
}

.page_main .best-spec-accent__video {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
}

.page_main .best-spec-accent__video svg {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 20px;
}

.page_main .best-spec-accent__btn {
  margin-left: 30px;
  margin-bottom: 30px;
}

.page_main .best-spec__refresh-wrap {
  text-align: right;
  margin-top: auto;
}

.page_main .best-spec__refresh {
  border: none;
  background: none;
  padding: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
}

.page_main .best-spec__refresh .svg-icon {
  width: 38px;
  height: 28px;
  margin-left: 30px;
}


.page_main .spec-filters_themes .h5 {
  margin-bottom: 60px;
}


.page_main .theme-help-order__adv {
  padding-left: 0;
  margin-bottom: 0;
}

.page_main .theme-help-order__adv-item {
  width: 33.3333%;
}

.page_main .theme-help-order__adv-subtext {
  max-width: 300px;
}

@import "partials/main-themes-desc.scss";

.theme-group__advantages-image {
  -webkit-filter: drop-shadow(4px 10px 25px rgba(0, 0, 0, 0.15));
  filter: drop-shadow(4px 10px 25px rgba(0, 0, 0, 0.15));
  border-radius: 70px;
  overflow: hidden;
  max-width: 560px;
  margin-left: auto;
  margin-top: 180px;
  margin-bottom: 180px;
}

.page_main .theme-group__advantages::before {
  background-position: 50% 100%;
  bottom: -580px;
  top: auto;
  margin-left: -100vw;
}

.page_main .theme-group__advantages-text h3 {
  font-weight: 700;
  margin-bottom: 60px;
}

.page_main .theme-group__advantages-text h5 {
  font-weight: 600;
  padding-right: 100px;
}

.page_main .theme-group__advantages-text .theme-group__advantages-btn {
  text-align: right;
  margin-top: 60px;
}


.cabinet-menu .cabinet-menu__submenu {
  padding: 15px 0 5px 52px;
}

.cabinet-menu .cabinet-menu__submenu li:last-of-type {
  margin-bottom: 0;
}

.cabinet-menu .cabinet-menu__submenu a {
  font-size: 14px;
}

.cabinet-menu .cabinet-menu__submenu a.active {
  color: #385C8B;
}

.deals__body {
  border-top: 1px solid #385C8B;
}

.question-popup {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


.chat-link {
  background: #F5F6FB;
  -webkit-box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
  box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 15px;
  padding: 20px 35px;
  width: 100%;
}

.chat-link .svg-icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 25px;
}

.chat-link strong {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #385C8B;
  margin-right: 20px;
  white-space: nowrap;
}

.chat-link span {
  font-size: 16px;
  line-height: 25px;
}

@media (max-width: 767px) {
  .chat-link {
    flex-wrap: wrap;
    padding: 15px;
    margin-top: 10px;
  }

  .chat-link span {
    width: 100%;
    margin-top: 15px;
  }
}


.date-time-info,
.radio-wrap.date-time-info input:checked + label,
.radio-wrap.date-time-info label {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #385C8B;
}

.date-time-info > div {
  margin-bottom: 10px;
}

.date-time-info label::before {
  display: none;
}

.date-time-info label {
  display: block;
}

.deals__row > div.controls .btn-round.btn-default {
  -webkit-box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15) !important;
  box-shadow: 4px 10px 25px rgba(0, 0, 0, 0.15) !important;
}


/*.page_main #main-top-accounts .catalog-item {margin-bottom: 60px;}*/


.create-spec-category {
  margin-top: 60px;
  margin-bottom: 150px;
}

.create-spec-category__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: bold;
}

.create-spec-category__title span {
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.02em;
}

.create-spec-category__title button {
  margin-left: 40px;
}

.create-spec-category__content {
  margin-top: 20px;
}

.create-spec-category__name-block {
  margin-top: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.create-spec-name {
}

.create-spec-name__label {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #5D5D64;
}

.create-spec-name__input {
  margin-top: 5px;
}

.create-spec-name__input input {
  height: 40px;
}

a.create-spec-category__create-btn {
  margin-left: 50px;
}

.create-spec-category__spec-block {
  margin-top: 50px;
}

.create-spec-add {
}

.create-spec-add__title {
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: #252733;
}

.create-spec-add__count {
  margin-top: 30px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #385C8B;
}

.create-spec-add__all, .create-spec-add__remove-all {
  margin-top: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.create-spec-add__remove-all {
  margin-top: 15px;
}

.create-spec-add__all-label {
  font-size: 16px;
  line-height: 25px;
  color: #252733;
}

.create-spec-add__all button, .create-spec-add__remove-all button {
  margin-left: 40px;
  font-size: 16px;
  line-height: 25px;
  color: #252733;
}

.page_spec .header::before {
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
}

.page_spec.page_header-fix .header::before {
  background: none;
}

@include max1670 {


  .persons .owl-carousel.owl-drag .owl-item {
    min-height: 462px;
  }

  /* меняем масштаб */
  .page {
    zoom: .85;
    -ms-zoom: 0.85;
    -webkit-zoom: 0.85;
  }

  @-moz-document url-prefix() {
    .page > * {
      -moz-transform: scale(.85, 1);
      -moz-transform-origin: center top;
    }

    .page > .header {
      -moz-transform: scale(1, 1);
    }

    .page .header__container {
      -moz-transform: scale(.85, 1);
    }

    html {
      -moz-transform: scale(1, .85);
      -moz-transform-origin: 0 0;
    }



  }


  .how-it-works .mainpage-group__title {
    font-size: 40px;
  }

  .page .persons_new {
    margin-right: 0;
  }

  /*
        .mpage-block {margin-top: 60px;margin-bottom: 60px;}
        .mpage-themes {margin-top: 60px;}
        .mainpage-group__title {font-size: 38px;}
        .mpage-themes__btn {margin-top: 25px;margin-bottom: 55px;}
        .theme-item__content {padding: 24px;}
        .mpage-themes__menu-refresh {margin-bottom: 30px;}
        .mpage-themes__menu-item {margin-bottom: 8px;}
        .block-round .mainpage-group__title{font-size: 40px;}

        .best-spec-accent {margin-bottom: 0;}
        .best-spec-accent__title {line-height: 30px; font-size: 24px;}
        .best-spec-accent__content {padding: 24px 20px 10px 24px;}
        .best-spec-accent__subtitle {margin-bottom: 24px;}
        .best-spec-accent__theme {font-size: 18px; line-height: 20px;margin-top: 8px;margin-bottom: 6px;}

        .catalog-item__content {padding: 16px 16px 12px;}
        .catalog-item__row_bottom {margin-top: 30px;}
        .page_main .best-spec {margin-bottom: 60px;}
        .page_main .spec-filters_themes .h5 {margin-bottom: 42px;}

        .block-round {margin-left: -80px;margin-right: -80px; padding: 50px 80px;}

        .page_main .spec-filters_themes {margin-bottom: 60px;}
    */

}

@media (max-width: 1439px) {


  .how-it-works .mainpage-group__title {
    font-size: 32px;
  }

  .how-it-works blockquote {
    margin-bottom: 10px;
    margin-left: 0;
  }

  .how-it-works__item {
    padding: 0 30px;
  }

  .how-it-works__item-num {
    font-size: 76px;
    line-height: 1.3;
  }

  .how-it-works__item-head {
    margin-bottom: 30px;
  }

  /*
    .how-it-works__item-content {padding: 0 20px 10px;}


    .how-it-works__btn {margin-top: 60px; }
    .how-it-works__aside {padding-top: 60px;padding-bottom: 60px;}
    .how-it-works__slider {margin-top: 30px;}
    */
}

@media (min-width: 1200px) and (max-width: 1366px) {
  .page__text-bg {
    display: block;
  }
}

@media (max-width: 1199px) {
  .page_theme-page .theme-page {
    margin-bottom: 50px;
  }

  .mpage-themes .mainpage-group__title, .page_theme-page .page__title {
    font-size: 30px;
  }

  .page_theme-page .theme-page__content {
    padding-bottom: 0;
  }

  .page_theme-page .theme-page__btn {
    bottom: 0;
    position: relative;
    margin-top: 30px;
  }

  .page_theme-page .catalog-blog {
    margin-bottom: 50px;
  }

  .page_theme-page .mpage-themes {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .page_theme-page .mpage-themes__text {
    margin-bottom: 50px;
  }

  .theme-item__content {
    padding: 15px;
  }

  .theme-help-order .theme-help-order__text {
    padding-left: 0;
  }

  .theme-group__advantages {
    padding-bottom: 40px;
  }

  .theme-group__advantages .theme-group__advantages-text {
    padding-left: 0;
  }

  .theme-group__advantages .theme-group__advantages-btn {
    padding-left: 0;
  }

  .theme-group__advantages .theme-group__advantages-text blockquote {
    margin-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .page-theme__title-wrap {
    display: block;
    margin-bottom: 30px;
  }

  .page_theme-page .page-theme__title-wrap .accent-color {
    margin-left: 0;
  }

  .page_theme-page .theme-page__media img {
    height: auto;
  }

  .page_theme-group .theme-page__media img {
    height: auto;
  }

  .page_theme-page .theme-page__content {
    padding-left: 0;
  }

  .page_theme-group .theme-page__content {
    padding-left: 0;
  }

  .page_theme-page::before {
    background: none !important;
  }

  .page_theme-page .page__title_small {
    margin-bottom: 30px;
  }

  .page_theme-page .catalog-blog__text {
    margin-top: 0;
  }


  .mainpage-top__subtitle {
    margin-bottom: 40px;
  }

  .mainpage-top__logo-wrap {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mainpage-top__logo-container {
    padding-right: 0;
  }

  .mainpage-top__logo {
    margin-bottom: 30px;
  }

  .mainpage-top__logo-text {
    margin: 30px 0;
  }


  .spec-filters .spec-competitions__list {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .spec-filters .spec-competitions__item {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }


  .how-it-works__aside::before {
    left: 50%;
  }

  .how-it-works__slider {
    max-width: 100%;
  }

  .theme-group__advantages .theme-group__advantages-text blockquote {
    margin-left: 0;
    padding-right: 0;
  }

  .page_main .theme-group__advantages-text h5 {
    padding-right: 0;
  }

  .page_main .theme-group__advantages-text .theme-group__advantages-btn {
    text-align: center;
  }

  .theme-group__advantages-image {
    margin: 80px auto;
  }

  .mainpage-pressa {
    margin-bottom: 60px;
  }

  .mainpage-pressa .owl-nav {
    left: 0;
    right: 0;
  }

  .press {
    width: 290px;
  }


}

//TODO: все надо разобрать

@media (max-width: 767px) {
  .page {
    zoom: 1;
    -ms-zoom: 1;
    -webkit-zoom: 1;
  }

  .mainpage-top {
    height: calc(var(--vh, 1vh) * 100);
  }

  .mainpage-top .container {
    padding-bottom: 0;
  }

  .page_main .page__text-bg2 {
    display: none;
  }

  .mainpage-top__letter {
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
  }

  .page_theme-page .theme-page__media-wrap {
    margin-bottom: 10px;
  }

  .page_theme-page .theme-page__media {
    width: 100vw;
  }

  .page_theme-group .theme-page__media {
    width: 100vw;
  }

  .mpage-themes__menu-link::before {
    width: 100vw;
  }

  .theme-help-order__adv {
    padding-left: 0;
  }

  .theme-help-order__adv-item {
    width: 100%;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .theme-help-order__adv-num {
    padding-bottom: 0;
  }

  .theme-help-order__adv-num::after {
    content: none;
  }


  .page_theme-group .page-theme__specialists .catalog-item {
    margin-bottom: 70px;
  }

  .page_theme-group .spec-filters_themes {
    margin-bottom: 60px;
  }

  .page_theme-group .theme-help-order .theme-help-order__text {
    padding-bottom: 0;
  }

  .page_theme-group .theme-help-order .theme-help-order__btn {
    padding-bottom: 60px;
  }

  .page_theme-group .catalog-blog {
    margin-bottom: 60px;
  }

  .page_theme-group .theme-help-order__adv {
    margin-bottom: 80px;
  }

  .theme-group__advantages .page__title {
    font-size: 30px;
  }


  .theme-group__advantages-media {
    padding-top: 50px;
    padding-bottom: 75px;
  }

  .block-round {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .block-round .mainpage-group__title {
    font-size: 30px;
  }


  .page_main .spec-filters_themes {
    margin-bottom: 70px;
  }


  .page_main .theme-help-order__adv {
    display: block;
  }

  .page_main .theme-help-order__adv-item {
    width: 100%;
    display: block;
  }

  .page_main .theme-group__advantages-image {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .mainpage-top__logo-text {
    height: 90px;
  }

  .mainpage-top__logo-text-item {
    display: block;
  }

  .mainpage-top__logo-text-item a {
    display: block;
  }

  .mainpage-top__logo-text-item .accent-color {
    font-size: 28px;
    margin-bottom: 10px;
    display: block;
  }


  .mpage-themes {
    margin-bottom: 60px;
  }

  .owl-item .catalog-item {
    max-width: 270px;
    width: 100vw;
  }

  #main-top-accounts {
    overflow: visible;
  }


  .page_main .about-formats {
    margin-bottom: 60px;
  }


  .page_main .theme-group__advantages-media {
    padding-bottom: 50px;
  }

  .page_main .theme-group__advantages-media::before {
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    border-radius: 0;
  }

  .theme-group__advantages .theme-group__advantages-text {
    padding-bottom: 0;
  }

  .theme-help-order__adv-item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .theme-help-order__adv-text {
    padding-left: 30px;
  }

  .mpage-themes__menu-refresh,
  .mpage-themes__btn {
    margin-bottom: 20px;
  }

  .mpage-themes__menu {
    margin-bottom: 30px;
  }

  .mpage-themes__menu-item {
    margin-bottom: 5px;
  }

  .mpage-themes__menu-link {
    padding-top: 8px;
    padding-bottom: 8px;
  }


  .theme-group__advantages-media .video-preview {
    height: auto;
    padding-bottom: 75%;
  }

  #best-spec-accent,
  .spec-filters .owl-carousel .owl-nav,
  .spec-filters .owl-carousel .owl-dots {
    display: none !important;
  }

  .page_main .best-spec blockquote p:last-of-type,
  .page_main .best-spec-accent__btn,
  .page_main .best-spec-accent__aside {
    margin-bottom: 0;
  }

  .page_main .best-spec-accent__aside {
    padding-bottom: 0;
  }

}



.spec-competencies-filter .blog-search__form {
  margin-bottom: 20px;
}

.text-info a {
  color: #F48F6F;
}


/**
 *  Не хочется лезть в кривую кашу стилей. Пробовал разобрать. Но требуется капитальная разборка
 * Поэтому новые стили отдельно
 */


.spec-competencies-filter .blog-search__form {
  margin-bottom: 20px;
}


.header__cabinet-link.header__cabinet-search .svg-icon {
  width: 35px !important;
  position: relative;
  top: 1px;
}




/**
    Мультиселект
 */
.select2-selection.select2-selection--multiple {
  border: none;
  border-bottom: 2px solid #dfdfe1;
}

.select2-selection--multiple:before {
  content: "\f107";
  font-family: FontAwesome;
  font-size: 18px;
  position: absolute;
  top: 3px;
  right: 2px;

}


.profile-instruction {
  position: relative;
  top: -39px;
}


/**
 * Чат
 */

.chat__messages {
  height: 500px;
  max-height: none;

}

.scroll-pane {
  /*height: 100%;*/
}

.chat__desc a {
  color: #385C8B;
}



.coachinghub-feed {
  .blog-item__media img {
    object-fit: contain;
  }
}






