@import "../media";
.review-carousel-wrap {
  width: 110%;
  position: relative;
  overflow: hidden;
  padding-left: 39px;
  position: relative;
  left: -33px;

  @include max1450 {
    width: 106%;
  }

}


.spec-reviews .review {
  width: 99%;
  //max-width: 860px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 80px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-right: 50px;
  padding: 0;

  @include max800 {
    width: 100%;
    flex-wrap: wrap;
  }
  &__content {
    padding-top: 30px;
    @include max800 {
      width: 100%;
    }
  }
  //@include  max1450 {
  //  width: 51vw;
  //
  //}
}

.spec-reviews .review__image {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 200px;
  margin-right: 60px;
  -webkit-box-shadow: 15px 15px 20px rgba(0, 0, 0, .15);
  box-shadow: 15px 15px 20px rgba(0, 0, 0, .15);
  border-radius: 12px;
  overflow: hidden;
}

.spec-reviews .review__image::before {
  content: none
}


.spec-reviews .review__title span::before {
  opacity: .3
}

.theme-coach .spec-reviews .review__title span::before {
  background: #f48f6f
}

.theme-consult .spec-reviews .review__title span::before {
  background: #f48f6f
}

.theme-mentor .spec-reviews .review__title span::before {
  background: #385C8B
}

.theme-psycologist .spec-reviews .review__title span::before {
  background: #5d8d94
}

@media (max-width: 767px) {
  .spec-reviews::before {
    content: none
  }

  .spec-reviews .spec__group-title {
    margin-bottom: 35px
  }



  .spec-reviews .review__image {
    width: 105px
  }

  .spec-reviews .review__content {
    padding-top: 0;
    margin-bottom: 20px
  }
}

