//TODO:КАША в классах перебрать бы
.event-calend {
  position: relative;
  margin-top: 150px;
  margin-bottom: 150px;

  .calendar-wrap {
    margin-left: -15px;
    position: relative;
    padding-top:120px;
    padding-bottom: 256px;
    z-index: 2;

    @include max800 {
      padding-top: 60px;
      padding-bottom: 116px;
    }
    @include max576 {
      margin: 0;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 50vw;
      height: 100%;
      pointer-events: none;
      background: url("../images/calend.png");
      border-radius: 25px;
      background-repeat: no-repeat;
      background-size: cover;

      @include max1670 {
        width: 70vw;
      }
      @include max800 {
        width: 100%;
      }
      @include max576 {
        width: 160%;
        right: -10%;
      }
    }

    .mainpage-group__title {
      font-weight: 600;
      margin-bottom: 140px;
      color: #fff;
      @include max800 {
        text-align: center;
      }
    }
  }

  .calendar {
    position: relative;
    max-width: 446px;
    color: #fff;

    @include max800 {
      margin: 0 auto;
    }

    table {
      @include max576 {
        width: 90%;
        margin: 0 auto;
      }
    }
  }
}


.calendar-event__latest {
  padding-left: 128px;
}

.event-calend .calendar-wrap .ui-widget {
  font-family: Montserrat, arial, sans-serif
}

.event-calend .calendar-wrap .ui-datepicker {
  display: block;
  padding: 0;
  width: 100%
}

.event-calend .calendar-wrap .ui-corner-all, .event-calend .calendar-wrap .ui-corner-bottom, .event-calend .calendar-wrap .ui-corner-br, .event-calend .calendar-wrap .ui-corner-right {
  border-radius: 0
}

.event-calend .calendar-wrap .ui-widget-header a {
  color: #fff
}

.event-calend .calendar-wrap .ui-widget-header a:hover {
  color: #385C8B
}

.event-calend .calendar-wrap .ui-datepicker .ui-datepicker-next, .event-calend .calendar-wrap .ui-datepicker .ui-datepicker-prev {
  top: 15px;
  background: 0 0 !important;
  border: none !important
}

.event-calend .calendar-wrap .ui-datepicker .ui-datepicker-next span, .event-calend .calendar-wrap .ui-datepicker .ui-datepicker-prev span {
  margin-top: -16px;
  margin-left: -16px
}

.event-calend .calendar-wrap .ui-widget-header .ui-icon {
  background: 0 0;
  height: 32px;
  width: 32px;
  font-size: 24px
}

.event-calend .calendar-wrap .ui-widget-header .ui-icon::before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  position: absolute;
  top: 5px;
  left: 10px;
  text-indent: 0
}

.event-calend .calendar-wrap .ui-datepicker .ui-datepicker-prev .ui-icon::before {
  content: "\f104"
}

.event-calend .calendar-wrap .ui-datepicker .ui-datepicker-next .ui-icon::before {
  content: "\f105"
}

.event-calend .calendar-wrap .ui-widget-content, .event-calend .calendar-wrap .ui-widget-header {
  border: none;
  background: 0 0;
  color: #fff
}

.event-calend .calendar-wrap .ui-widget-header {
  padding: 18px 0 30px;
}

.event-calend .calendar-wrap .ui-button, .event-calend .calendar-wrap .ui-state-default, .event-calend .calendar-wrap .ui-widget-content .ui-state-default, .event-calend .calendar-wrap .ui-widget-header .ui-state-default, .event-calend .calendar-wrap html .ui-button.ui-state-disabled:active, .event-calend .calendar-wrap html .ui-button.ui-state-disabled:hover {
  background: 0 0;
  padding: 5px;
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 8px;
}

.event-calend .calendar-wrap .ui-button, .event-calend .calendar-wrap .ui-state-default, .event-calend .calendar-wrap .ui-widget-content .ui-state-default, .event-calend .calendar-wrap .ui-widget-header .ui-state-default {
  color: #fff
}

.event-calend .calendar-wrap .ui-button:hover, .event-calend .calendar-wrap .ui-state-default:hover, .event-calend .calendar-wrap .ui-widget-content .ui-state-default:hover, .event-calend .calendar-wrap .ui-widget-header .ui-state-default:hover {
  color: #385C8B
}

.event-calend .calendar-wrap html .ui-button.ui-state-disabled:active, .event-calend .calendar-wrap html .ui-button.ui-state-disabled:hover {
  color: #385C8B
}

.event-calend .calendar-wrap .ui-datepicker .ui-datepicker-title {
  text-transform: uppercase;
  font-size: 24px;
  line-height: 23px;
  letter-spacing: .005em
}

.event-calend .calendar-wrap .ui-datepicker th {
  text-transform: lowercase;
  padding: 10px 5px;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: .005em;
  border-bottom: 1px solid #D4D4D7;
}

.event-calend .calendar-wrap .ui-datepicker td {
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  padding: 5px;
}

.event-calend .calendar-wrap .ui-datepicker td a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.event-calend .calendar-wrap .ui-datepicker td.ui-datepicker-current-day a {
  font-weight: 500;
  background: #fff;
  color: #252733
}

.event-calend .calendar-wrap .ui-datepicker td.ui-datepicker-today a {
  color: #56CCF2
}


.event-calend .scroll-pane {
  max-height: none
}





.calendar-event {

  &__wrap {
    max-height: 941px;
    overflow-y: auto;
    padding-right: 20px;
    @include max800 {
      margin-top: 60px;
    }
    .custom-scroll_bar-x {
      display: none;
    }
  }


  &__group-title {

    margin-top: 0;
    font-weight: 800;
    font-size: 30px;
    line-height: 130%;
    letter-spacing: .02em;
    color: #252733;
    margin-bottom: 0;
    padding-left:128px;

  }
  &__nearest {
    padding-left: 129px;
    &.latest {
      .event-item__btn {
        display: none;

      }
    }

    @include max800 {
      padding: 0;
      height: 296px;
    }
    .event-item__date {
      font-weight: bold;
      font-size: 20px;
      line-height: 130%;
      letter-spacing: 0.02em;
      color: #252733;
    }
  }
  &__nearest-title {
    font-weight: 800;
    font-size: 20px;
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #385C8B;

  }
}


@media (max-width: 767px) {
  .calendar-event__group-title {
    margin-bottom: 30px;

    padding: 0;
  }
}



.event-item {
  position: relative;
  margin-bottom: 90px
}

.owl-carousel_events .event-item {
  width: 315px
}

.event-item__media {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px
}

.event-item__media::before {
  content: '';
  display: block;
  padding-bottom: 61.90476%
}

.event-item__media img {
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover
}

.event-item__favorite {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #385C8B;
  color: #fff !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 30px
}

.event-item__favorite .fa-star {
  display: none
}

.event-item__favorite.active .fa-star-o {
  display: none
}

.event-item__favorite.active .fa-star {
  display: inline-block
}

.event-item__date {
  color: #252733;

  font-weight: 800;
  font-size: 30px;
  line-height: 130%;
  margin-bottom: 3px;
  letter-spacing: 0.02em;

}

.event-item__subtitle {
  font-size: 14px;
  line-height: 130%;
  color: rgba(37, 39, 51, .8);
  margin-bottom: 12px
}

.event-item__title {
  line-height: 130%;
  color: #252733;
  margin-top: 10px;
  margin-bottom: 20px;

  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.02em;

  color: #252733;

}

.event-item__anons {
  margin-bottom: 20px
}

.event-item__anons p {
  font-size: 16px;
  line-height: 130%;
  color: #151528;
  margin-bottom: 5px
}

.event-item__location {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #385C8B;
  margin-bottom: 27px
}

.event-item__btn .btn .svg-icon_arrow {
  margin-left: 8px
}

@media (max-width: 767px) {
  .event-item {
    margin-bottom: 60px
  }

  .owl-carousel_calendar .event-item, .owl-carousel_events .event-item {
    width: 260px
  }

  .event-item__media {
    margin-bottom: 18px
  }

  .event-item__date {
    font-size: 14px;
    margin-bottom: 3px
  }

  .event-item__title {
    font-size: 16px;
    line-height: 135%;
    margin-top: 18px;
    margin-bottom: 14px
  }

  .event-item__anons .event-item__subtitle {
    font-size: 12px
  }

  .event-item__anons p {
    font-size: 14px
  }

  .event-item__location {
    font-size: 14px;
    margin-bottom: 38px
  }

  .event-item__btn .btn .svg-icon_arrow {
    margin-left: 28px
  }
}

.event-item_short {
  //max-width: 260px
  padding-left: 132px;
  margin-top: 10px;
}

.owl-carousel_calendar {
  width: 260px;
}

.event-item_short .event-item__date {
  color: #252733;
  font-size: 21px;
  margin-bottom: 3px;
  margin-right: 20px;

}

.event-item_short .event-item__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.event-item_short .event-item__favorite {
  position: relative;
  top: 0;
  right: 0
}

.event-item_short .event-item__title {
  font-size: 21px
}

@media (min-width: 1280px) {


  .owl-carousel_calendar {
    width: 520px;
  }
}

@media (max-width: 767px) {
  .event-item_short .event-item__date {
    font-size: 18px
  }

  .event-item_short .event-item__head {
    margin-bottom: 5px
  }

  .event-item_short .event-item__favorite {
    top: -8px
  }

  .event-item_short .event-item__title {
    font-size: 18px
  }
}