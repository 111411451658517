// Карточка спеца
.spec-card {
  margin-top: -25px;
  margin-bottom: 0;
  position: relative;
  z-index: 2;
}

.spec-card__edit-btns {
  position: absolute;
  bottom: 100px;
  left: 40px;
  max-width: 273px;
  width: 50%;
}

.spec-card__edit-btn {
  margin-bottom: 30px;
  height: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  border-radius: 12px;
}

.spec-card__edit-btn span {
  display: block;
  padding: 5px 30px;
  color: #fff
}

.theme-coach .spec-card__edit-btn {
  background: rgba(243, 112, 32, .9)
}

.theme-coach .spec-card__edit-btn:hover {
  background: #f48f6f
}

.theme-consult .spec-card__edit-btn {
  background: rgba(243, 112, 32, .9)
}

.theme-consult .spec-card__edit-btn:hover {
  background: #f48f6f
}

.theme-mentor .spec-card__edit-btn {
  background: rgba(56, 92, 139, .9)
}

.theme-mentor .spec-card__edit-btn:hover {
  background: #385C8B
}

.theme-psycologist .spec-card__edit-btn {
  background: rgba(93, 141, 148, .9)
}

.theme-psycologist .spec-card__edit-btn:hover {
  background: #5d8d94
}

.spec-card__aside {
  margin: 0;
  position: relative;
  z-index: 2;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.spec-card__video {
  position: absolute;
  bottom: 35px;
  left: 35px;
  display: block;
  font-size: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  background: #f48f6f url(../images/icon-play.svg) no-repeat 50% 50%;
  -webkit-box-shadow: 0 26px 50px rgba(243, 112, 32, .6);
  box-shadow: 0 26px 50px rgba(243, 112, 32, .6)
}

.theme-mentor .spec-card__video {
  background-color: #385C8B;
  -webkit-box-shadow: 0 26px 50px rgba(56, 92, 139, .6);
  box-shadow: 0 26px 50px rgba(56, 92, 139, .6)
}

.theme-psycologist .spec-card__video {
  background-color: #5d8d94;
  -webkit-box-shadow: 0 26px 50px rgba(93, 141, 148, .6);
  box-shadow: 0 26px 50px rgba(93, 141, 148, .6)
}

.spec-card__video:hover {
  background-color: #f68f50
}

.theme-mentor .spec-card__video:hover {
  background-color: #3e5f8a;
}

.theme-psycologist .spec-card__video:hover {
  background-color: #3f888f
}

.spec-card__video:active, .spec-card__video:focus {
  background-color: #ed620d
}

.theme-mentor .spec-card__video:active, .theme-mentor .spec-card__video:focus {
  background-color: #49678d
}

.theme-psycologist .spec-card__video:active, .theme-psycologist .spec-card__video:focus {
  background-color: #4A7177
}

.spec-card__photo {
  background: #fff;
  -webkit-box-shadow: 0 30px 50px rgba(22, 24, 26, .2);
  box-shadow: 0 30px 50px rgba(22, 24, 26, .2);
  border-radius: 45px;
}

.spec-card__name {
  color: #f48f6f
}

.theme-mentor .spec-card__name {
  color: #385C8B
}

.theme-psycologist .spec-card__name {
  color: #5d8d94
}

.spec-card__rating {
  margin-bottom: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.spec-card__rating .fa {
  margin-right: 6px;
  color: #fde022
}

.spec-card__reviews {
  font-size: 12px;
  line-height: 130%;
  opacity: .7;
  letter-spacing: .02em;
  white-space: nowrap
}

.spec-card__reviews a {
  text-decoration: underline
}

.spec-card__reviews a:hover {
  text-decoration: none
}

.spec-card__viewed {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 35px;
}

.spec-card__viewed .svg-icon {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

.spec-card__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  line-height: 130%;
  margin-bottom: 25px
}

.spec-card__prehead {
  position: relative;
  padding-right: 160px
}

.spec-card__prehead::after {
  content: '';
  display: block;
  width: 62px;
  height: 5px;
  background: #f48f6f;
  margin: 35px 0 18px
}

.theme-mentor .spec-card__prehead::after {
  background: #385C8B
}

.theme-psycologist .spec-card__prehead::after {
  background: #5d8d94
}

.spec-card__prehead .spec__prehead-aside {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 5px;
}

.spec-card__prehead .spec-card__statuses {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  z-index: 20;
}

.spec-card__prehead .spec-card__statuses .catalog-item__icon {
  margin-right: 10px;
  -webkit-box-shadow: 0px 5px 15px -3px rgba(30, 31, 33, 0.12);
  box-shadow: 0px 5px 15px -3px rgba(30, 31, 33, 0.12);
  height: 29px;
  min-width: 29px;
  font-size: 12px;
}

.spec-card__prehead .spec-card__statuses .catalog-item__icon img {
  width: 17px;
}

.spec-card__prehead .spec-card__statuses .catalog-item__icon.icon-only img {
  width: 17px;
}

.spec-card__type {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: .02em;
  margin-bottom: 2px
}

.spec-card__competitions {
  font-size: 14px;
  line-height: 130%
}

.spec-card__competitions span {
  white-space: nowrap;
  margin-right: 20px
}

.spec-card__social {
  margin-left: 38px;
  .fa {
    font-size: 12px;
  }
}

.spec-card__social a {
  width: 25px;
  height: 25px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  background: #f48f6f;
  border-radius: 50%;
  margin-right: 3px;
  font-size: 8px;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  opacity: .5
}

.theme-mentor .spec-card__social a {
  background: #385C8B
}

.theme-psycologist .spec-card__social a {
  background: #5d8d94
}

.spec-card__social a:hover {
  opacity: 1
}

.spec-card__desc {
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 45px
}

.spec-card__desc p {
  line-height: 1.5
}

.spec-card__desc h5 {
  text-transform: uppercase;
  margin-top: 43px;
  margin-bottom: 26px;
  font-weight: 800
}

.spec-card__total {
  margin-top: 40px;
  margin-bottom: 30px
}

.spec-card__total-item strong {
  font-size: 30px;
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #f48f6f 51%, rgba(243, 112, 32, .5) 51%, rgba(243, 112, 32, .5) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(51%, rgba(255, 255, 255, 0)), color-stop(51%, #f48f6f), color-stop(51%, rgba(243, 112, 32, .5)), to(rgba(243, 112, 32, .5)));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #f48f6f 51%, rgba(243, 112, 32, .5) 51%, rgba(243, 112, 32, .5) 100%)
}

.theme-mentor .spec-card__total-item strong {
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #385C8B 51%, rgba(56, 92, 139, .5) 51%, rgba(56, 92, 139, .5) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(51%, rgba(255, 255, 255, 0)), color-stop(51%, #385C8B), color-stop(51%, rgba(56, 92, 139, .5)), to(rgba(56, 92, 139, .5)));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #385C8B 51%, rgba(56, 92, 139, .5) 51%, rgba(56, 92, 139, .5) 100%)
}

.theme-psycologist .spec-card__total-item strong {
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #5d8d94 51%, rgba(93, 141, 148, .5) 51%, rgba(93, 141, 148, .5) 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(51%, rgba(255, 255, 255, 0)), color-stop(51%, #5d8d94), color-stop(51%, rgba(93, 141, 148, .5)), to(rgba(93, 141, 148, .5)));
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 51%, #5d8d94 51%, rgba(93, 141, 148, .5) 51%, rgba(93, 141, 148, .5) 100%)
}

.spec-card__content {
  position: relative;
  padding-bottom: 170px;
  padding-top: 30px
}

.spec-card__order {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 0;
  height: 160px;
  padding-left: 40px
}

.spec-card__order::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -50px;
  width: 100vw;
  height: 100%;
  background: #374859;
  opacity: .8
}

.spec-card__order .owl-nav {
  position: absolute;
  left: 100%;
  top: 0;
  color: #fff;
  margin: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse
}

.spec-card__order .owl-next, .spec-card__order .owl-prev {
  overflow: hidden;
  width: 40px;
  position: relative
}

.spec-card__order .owl-next .svg-icon, .spec-card__order .owl-prev .svg-icon {
  float: right;
  width: 150px
}

.spec-card__order .owl-prev {
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1)
}

.spec-card__order-list {
  position: relative;
  width: 100%
}

.spec-card__order-item {
  position: relative;
  padding-left: 20px;
  color: #fff
}

.spec-card__order-item a {
  color: inherit
}

.spec-card__order-item span {
  display: block;
  max-width: 120px;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  margin-bottom: 10px
}

.spec-card__order-item span.currency {
  font-size: 12px;
}

.spec-card__order-item strong {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%
}

.spec-card__order-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.spec-card__order-icon img {
  max-width: 100%;
  max-height: 100%
}

.spec-card__order-icon svg {
  width: 100%;
  height: 100%;
  opacity: .2
}

.spec-card__edit-link {
  margin-bottom: 15px
}

.spec-card__edit-link a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: #b3b3b7
}

.spec-card__edit-link a .svg-icon {
  margin-right: 10px
}

.spec-card__contacts {
  margin-bottom: 15px
}

.spec-card__contacts .a {
  opacity: .6;
  margin-right: 5px
}

.spec-card__contacts .a:hover {
  opacity: 1
}

.spec-card__verified {
  z-index: 10;
  width: 21px;
  height: 25px;
  position: relative;
  margin-left: 5px;
  border: none;
  padding: 0;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.spec-card__verified .svg-icon {
  fill: #5CBE85;
  width: 21px;
  height: 25px;
  vertical-align: top;
}

.spec-card__choice {
  position: absolute;
  top: 35px;
  left: 35px;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  z-index: 20;
  width: 78px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.spec-card__email-btn {
  margin-bottom: 20px;
}

.spec-card__email-btn .btn {
  padding: 10px 30px;
  color: #fff;
  text-transform: none;
  border: 2px solid;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
}

.spec-card__email-btn .btn:hover {
  color: #000;
}

.theme-coach .spec-card__email-btn .btn {
  background: #f48f6f;
  border-color: #f48f6f;
}

.theme-coach .spec-card__email-btn .btn:hover {
  background: #fff;
  color: #f48f6f;
}

.theme-consult .spec-card__email-btn .btn {
  background: #f48f6f;
  border-color: #f48f6f;
}

.theme-consult .spec-card__email-btn .btn:hover {
  background: #fff;
  color: #f48f6f
}

.theme-mentor .spec-card__email-btn .btn {
  background: #385C8B;
  border-color: #385C8B;
}

.theme-mentor .spec-card__email-btn .btn:hover {
  background: #fff;
  border-color: #385C8B;
}

.theme-psycologist .spec-card__email-btn .btn {
  background: #5d8d94;
  border-color: #5d8d94;
}

.theme-psycologist .spec-card__email-btn .btn:hover {
  background: #fff;
  color: #5d8d94;
}


@media (max-width: 991px) {
  .spec-card {
    margin-top: -15px;
    min-height: 0
  }

  .spec-card__aside {
    position: relative
  }

  .spec-card__photo {
    //max-width: 230px;
    margin: 0 auto 30px;
    position: relative
  }

  .spec-card__photo img {
    border-radius: 25px;
  }

  .spec-card__prehead {
    padding-right: 0
  }

  .spec-card__prehead::after {
    margin: 15px 0
  }

  .spec-card__prehead .spec__prehead-aside {
    position: relative;
    right: auto;
    top: auto
  }

  .spec-card__stats {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 17px
  }

  .spec-card__rating {
    margin-right: 15px
  }

  .spec-card__reviews {
    margin-bottom: 5px
  }

  .spec-card__type {
    font-size: 14px;
    margin-bottom: 5px
  }

  .spec-card__competitions {
    font-size: 12px
  }

  .spec-card__competitions span {
    margin-right: 10px
  }

  .spec-card__video {
    width: 35px;
    height: 35px;
    bottom: 12px;
    left: 12px;
    background-size: 8px auto
  }

  .spec-card__choice {
    width: 35px;
    top: 12px;
    left: 12px;
  }

  .spec-card__edit-btns {
    width: auto;
    left: 30px;
    right: 30px;
    bottom: 0
  }

  .spec-card__edit-btn {
    font-size: 12px;
    height: 50px;
    margin-bottom: 15px
  }

  .spec-card__edit-btn span {
    padding: 0
  }

  .spec-card__desc {
    margin-bottom: 15px
  }

  .spec-card__total {
    margin-top: 20px;
    margin-bottom: 45px;
    font-size: 16px
  }

  .spec-card__total-item strong {
    font-size: 24px
  }

  .spec-card__order {
    position: relative;
    width: auto;
    margin-left: -150px;
    margin-right: -150px;
    height: auto;
    padding: 20px 150px 15px
  }

  .spec-card__order::before {
    width: 200%
  }

  .spec-card__order .owl-nav {
    position: relative;
    left: 0;
    top: 0;
    margin: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .spec-card__order .owl-nav .owl-next, .spec-card__order .owl-nav .owl-prev {
    width: 118px
  }

  .spec-card__order-item {
    padding-left: 0
  }

  .spec-card__order-item span {
    font-size: 14px;
    margin-bottom: 15px;
    max-width: 110px;
    height: 36px;
  }

  .spec-card__order-icon {
    display: none
  }

  .spec-card__content {
    padding-bottom: 0;
    padding-top: 0
  }
}

@media (min-width: 992px) {
  .spec-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: calc(90vh - 70px)
  }

  .spec-card__aside {
    width: 48%;
    margin-bottom: 0;
    height: 100%;
    top: 30px
  }

  .spec-card__photo {
    overflow: hidden;
    position: relative
  }

  .spec-card__content {
    width: 52%;
    position: relative;
    padding-left: 20px
  }

  .spec-card__head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
  }

  .spec-card__stats {
    padding-top: 15px;
    margin-right: 5px
  }

  .spec-card__stats .fa {
    margin-left: 7px;
    margin-right: 0
  }

  .spec-card__reviews {
    text-align: right;
    font-size: 14px
  }

  .spec-card__contacts {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0
  }

  .spec-card__contacts a {
    margin-left: 15px
  }
}

@media (min-width: 1280px) {
  .spec-card {
    margin-top: -70px
  }

  .spec-card__aside {
    width: 42%
  }

  .spec-card__content {
    padding-right: 0;
    width: 66%
  }

  .spec-card__info {
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 40px
  }

  .spec-card__social {
    margin-left: 48px;
  }

  .spec-card__desc {
    font-size: 18px
  }

  .spec-card__desc h5 {
    margin-top: 72px;
    margin-bottom: 23px
  }

  .spec-card__edit-link {
    position: absolute;
    top: -55px
  }
}

@media (min-width: 992px) and (max-width: 1599px) {
  .spec-card__aside {
    margin-bottom: 0
  }

  .spec-card__edit-btns {
    bottom: 10px
  }

  .spec-card__prehead::after {
    margin: 15px 0 5px
  }

  .spec-card__type {
    font-size: 18px
  }

  .spec-card__content {
    padding-top: 25px;
    padding-left: 30px;
    padding-bottom: 120px
  }

  .spec-card__video {
    left: 15px;
    bottom: 30px;
    width: 60px;
    height: 60px
  }

  .spec-card__choice {
    left: 15px;
    top: 15px;
    width: 60px;
  }

  .spec-card__info {
    margin-bottom: 20px;
    font-size: 14px
  }

  .spec-card__desc {
    font-size: 14px;
    margin-bottom: 15px
  }

  .spec-card__total {
    margin-top: 15px;
    margin-bottom: 15px
  }

  .spec-card__order {
    height: 100px;
    padding: 15px 0 15px 30px
  }

  .spec-card__order .owl-nav {
    margin: 5px 20px
  }

  .spec-card__order-icon {
    display: none
  }

  .spec-card__order-item {
    padding-left: 0
  }

  .spec-card__order-item span {
    height: 2.6em;
    overflow: hidden;
    font-size: 14px
  }
}

@media (min-width: 1440px) {
  .spec-card__aside {
    width: 44%;
  }
}

@media (min-width: 1600px) {
  .spec-card {
    height: calc(90vh - 70px);
    min-height: 830px;
  }

  .spec-card__aside {
    width: 44%;
    margin-bottom: 0;
    height: 100%
  }

  .spec-card__photo {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .spec-card__content {
    width: 56%;
    padding-top: 35px
  }

  .spec-card__content, .spec-card__order {
    padding-left: 60px
  }
}