
.menu-mobile {
  position: fixed;
  top: 0;
  right: 0;
  background: #272934;
  color: #fff;
  height: 100vh;
  height: 100%;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 99;
  width: 292px;
  -webkit-transition: .5s;
  -o-transition: .5s;
  transition: .5s;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  @include max1050 {
    height: 119vh;
  }
}

.menu-open .menu-mobile {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0)
}

.menu-mobile a {
  color: #fff !important
}

.menu-mobile__top .header__burger .icon-bar {
  background: #fff
}

.menu-mobile__menu {
  margin: 50px 0
}

.menu-mobile__bottom {
  position: relative
}

.menu-mobile__bottom .footer__social {
  padding-top: 0;
  padding-bottom: 50px;
}

.menu-mobile__bottom .footer__social a:last-of-type {
  margin-right: 0
}

.menu-mobile__bottom .footer__contacts {
  width: 100%;
}

@media (min-width: 1280px) {
  .menu-mobile {
    display: none !important
  }
}

.menu-mobile .footer-menu__col {
  padding-top: 0
}